import { Node, Group } from '../models';
import { Action } from './';

type NodesGroupActionObjects = { nodes: Node[]; group: Group };
export class NodesGroupAction extends Action<NodesGroupActionObjects> {
  static actionName = 'NodesGroupAction';

  async redo({ nodes, group }: NodesGroupActionObjects) {
    this.create(group);

    const allNodes = nodes[0].parent.nodes;
    nodes = nodes.slice().sort((a, b) => {
      return allNodes.indexOf(a) - allNodes.indexOf(b);
    });

    let selected = false;
    let unlocked = true;
    let visible = true;
    nodes.forEach((node) => {
      selected = node.selected || selected;
      unlocked = !node.locked && unlocked;
      visible = !node.hidden && visible;
      node.selected = false;
      node.locked = false;
      node.hidden = false;
      group.addNode(node);
    });

    group.selected = selected;
    group.locked = !unlocked;
    group.hidden = !visible;
    group.parent.addNode(group);
  }
}
