import { Node, Matrix } from '../models';
import { Action } from '.';

type NodeTransformActionObjects = { node: Node };
type NodeTransformActionArgs = [scale: Matrix];
export class NodeTransformAction extends Action<NodeTransformActionObjects, NodeTransformActionArgs> {
  static actionName = 'NodeTransformAction';

  async redo({ node }: NodeTransformActionObjects, scale: Matrix) {
    node.transform.lmultiplyO(scale);
  }

  merge([scale]: NodeTransformActionArgs) {
    return [this.args[0].lmultiplyO(scale) as Matrix] as NodeTransformActionArgs;
  }
}
