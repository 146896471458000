import { nextTick } from 'vue';
import { NodesUpdateAction } from '../../actions';
import { Shape } from '../../models';
import { boxToPoints, getPointFromEvent, htmlId } from '../../utils/utils';
import { Point } from '../Point';

export class Text extends Shape {
  static model = 'Text' as const;
  static base = Shape;

  declare type: 'Text';
  text!: string;
  underline!: boolean;
  italic!: boolean;
  bold!: boolean;
  fontFamily!: string;
  fontSize!: number;
  textAlign!: string;
  menuPos!: Point;

  static fields() {
    return {
      ...Shape.fields(),
      type: this.string('Text'),
      text: this.string('   '),
      underline: this.boolean(false),
      filled: this.boolean(true),
      italic: this.boolean(false),
      bold: this.boolean(false),
      fontFamily: this.string('Arial'),
      fontSize: this.number(16),
      textAlign: this.string('left'),
      menuPos: this.computed((text) => {
        const p = text.points[0] ?? new Point();
        return p.transform(text.transform).transform(text.getScreenCtm());
      }),
    };
  }

  onBackspace() {
    //
  }

  onEnter() {
    //
  }

  onMouseDown(ev: MouseEvent): any {
    const p = getPointFromEvent(ev, this.matrix?.value, true, { y: -13 });
    this.addPoint(p, true);
    this.finishShape();
  }

  onMouseMove() {
    //
  }

  onMouseUp() {
    //
  }

  finishShape() {
    this.finishHook().then(
      () => super.finishShape(),
      () => super.cancelDrawing(),
    );
  }

  finishHook(update = false) {
    const {
      underline: oldUnderline,
      italic: oldItalic,
      bold: oldBold,
      fontFamily: oldFontFamily,
      fontSize: oldFontSize,
      textAlign: oldtextAlign,
    } = this;

    if (update) {
      this.finished = false;
      this.selected = false;
      this.points = [this.points[0]!];

      // Trigger the whiteboard indicator to start
      window.reportWhiteboardAction('drawing-start');
    }

    function selectElementContents(el: Element) {
      const range = document.createRange();
      range.selectNodeContents(el);
      const sel = window.getSelection();
      sel?.removeAllRanges();
      sel?.addRange(range);
    }

    return new Promise<HTMLElement>((resolve, reject) => {
      nextTick(() => {
        const el = document.getElementById(htmlId(this._id));

        if (!el) {
          return reject(new Error('Element not found'));
        }

        el.setAttribute('contenteditable', 'true');
        el.textContent = this.text;
        el.focus();
        selectElementContents(el);
        // const skipFirst = 1

        const onClick = (ev: MouseEvent) => {
          ev.stopPropagation();
          if (ev.target === el /* || skipFirst-- */ || (ev.target as Element).closest('.text-menu')) return;

          onFinish();

          if (el.textContent?.trim() === '') {
            reject(new Error('No text'));
          }

          resolve(el);
        };

        const onFinish = () => {
          el.removeAttribute('contenteditable');
          document.removeEventListener('pointerdown', onClick, { capture: true });
          document.removeEventListener('keydown', onKeydown, { capture: true });
        };

        const onInit = (ev: MouseEvent) => {
          ev.stopPropagation();
          ev.preventDefault();
          el.focus();
          selectElementContents(el);
          document.removeEventListener('pointerup', onInit, { capture: true });
        };

        // Extra work on escape. Maybe add a second hook instead
        const onKeydown = (ev: KeyboardEvent) => {
          if (ev.key === 'Escape') {
            onFinish();
          } else if (ev.key === 'Enter' && ev.ctrlKey) {
            onFinish();
            resolve(el);
          }
        };

        document.addEventListener('pointerdown', onClick, { capture: true });
        document.addEventListener('pointerup', onInit, { capture: true });
        document.addEventListener('keydown', onKeydown, { capture: true });
      });
    }).then((el) => {
      // const el = document.getElementById(htmlId(this.id))
      // if (!el) throw new Error('Cant generate corner points for text element with id ' + this.id)
      // this.points = boxToPoints(el.getBoundingClientRect())

      if (update) {
        // Trigger the whiteboard indicator to stop
        window.reportWhiteboardAction('drawing-stop');

        const { underline, italic, bold, fontFamily, fontSize, textAlign } = this;
        Object.assign(this, {
          underline: oldUnderline,
          italic: oldItalic,
          bold: oldBold,
          fontFamily: oldFontFamily,
          fontSize: oldFontSize,
          textAlign: oldtextAlign,
        });

        this.finished = true;
        new NodesUpdateAction().execute(
          { nodes: [this] },
          {
            text: el.innerText,
            underline,
            italic,
            bold,
            fontFamily,
            fontSize,
            textAlign,
          },
        );

        this.points = [this.points[0]!];
      } else {
        this.text = el.innerText;
      }
    });
  }

  generateCornerPoints(): [Point, Point, Point, Point] {
    if (this.points.length === 1) {
      const el = document.getElementById(htmlId(this._id));
      if (!el) throw new Error('Cant generate corner points for text element with id ' + this._id);
      this.points = boxToPoints(el.getBoundingClientRect()).map((p) =>
        p.transformO(this.getScreenCtm().multiply(this.transform).inverse()),
      );
    }

    return this.points.map((p) => p.transform(this.transform)) as [Point, Point, Point, Point];
  }
}

Text.boot();
