import { CoordinateXY, MatrixAlias as BaseMatrixAlias, MatrixLike, MatrixTransformParam } from '../utils/types';
import { Point } from '.';
import { radians } from '../utils/utils';

type MatrixAlias = Exclude<BaseMatrixAlias, string | Element> & { origin?: CoordinateXY };

export class Matrix {
  a!: number;
  b!: number;
  c!: number;
  d!: number;
  e!: number;
  f!: number;

  constructor(source: MatrixAlias | undefined | number = undefined, ...args: number[]) {
    this.init(source, ...args);
  }

  static formatTransforms(o: MatrixTransformParam & { origin?: CoordinateXY }) {
    // Get all of the parameters required to form the matrix
    const flipBoth = o.flip === 'both' || o.flip === true;
    const flipX = o.flip && (flipBoth || o.flip === 'x') ? -1 : 1;
    const flipY = o.flip && (flipBoth || o.flip === 'y') ? -1 : 1;
    const skewX =
      o.skew && Array.isArray(o.skew)
        ? o.skew[0]
        : Number.isFinite(o.skew)
          ? o.skew
          : Number.isFinite(o.skewX)
            ? o.skewX
            : 0;
    const skewY =
      o.skew && Array.isArray(o.skew)
        ? o.skew[1]
        : Number.isFinite(o.skew)
          ? o.skew
          : Number.isFinite(o.skewY)
            ? o.skewY
            : 0;
    const scaleX =
      o.scale && Array.isArray(o.scale)
        ? o.scale[0] * flipX
        : Number.isFinite(o.scale)
          ? o.scale ?? 0 * flipX
          : Number.isFinite(o.scaleX)
            ? o.scaleX ?? 0 * flipX
            : flipX;
    const scaleY =
      o.scale && Array.isArray(o.scale)
        ? o.scale[1] * flipY
        : Number.isFinite(o.scale)
          ? o.scale ?? 0 * flipY
          : Number.isFinite(o.scaleY)
            ? o.scaleY ?? 0 * flipY
            : flipY;
    const shear = o.shear || 0;
    const theta = o.rotate || o.theta || 0;
    const origin = new Point(o.origin || o.around || o.ox || o.originX, o.oy || o.originY);
    const ox = origin.x;
    const oy = origin.y;
    // We need Point to be invalid if nothing was passed because we cannot default to 0 here. Thats why NaN
    const position = new Point(o.position || o.px || o.positionX || NaN, o.py || o.positionY || NaN);
    const px = position.x;
    const py = position.y;
    const translate = new Point(o.translate || o.tx || o.translateX, o.ty || o.translateY);
    const tx = translate.x;
    const ty = translate.y;
    const relative = new Point(o.relative || o.rx || o.relativeX, o.ry || o.relativeY);
    const rx = relative.x;
    const ry = relative.y;

    // Populate all of the values
    return {
      scaleX,
      scaleY,
      skewX,
      skewY,
      shear,
      theta,
      rx,
      ry,
      tx,
      ty,
      ox,
      oy,
      px,
      py,
    };
  }

  static fromArray(a: number[]) {
    return { a: a[0], b: a[1], c: a[2], d: a[3], e: a[4], f: a[5] };
  }

  static isMatrixLike(o: MatrixLike) {
    return o.a != null || o.b != null || o.c != null || o.d != null || o.e != null || o.f != null;
  }

  // left matrix, right matrix, target matrix which is overwritten
  static matrixMultiply(l: Matrix, r: Matrix, o: Matrix) {
    // Work out the product directly
    const a = l.a * r.a + l.c * r.b;
    const b = l.b * r.a + l.d * r.b;
    const c = l.a * r.c + l.c * r.d;
    const d = l.b * r.c + l.d * r.d;
    const e = l.e + l.a * r.e + l.c * r.f;
    const f = l.f + l.b * r.e + l.d * r.f;

    // make sure to use local variables because l/r and o could be the same
    o.a = a;
    o.b = b;
    o.c = c;
    o.d = d;
    o.e = e;
    o.f = f;

    return o;
  }

  clone(): Matrix {
    return new Matrix(this);
  }

  around(cx: number, cy: number, matrix: MatrixAlias) {
    return this.clone().aroundO(cx, cy, matrix);
  }

  // Transform around a center point
  aroundO(cx = 0, cy = 0, matrix: MatrixAlias) {
    const dx = cx;
    const dy = cy;
    return this.translateO(-dx, -dy).lmultiplyO(matrix).translateO(dx, dy);
  }

  // Decomposes this matrix into its affine parameters
  decompose(cx = 0, cy = 0) {
    // Get the parameters from the matrix
    const a = this.a;
    const b = this.b;
    const c = this.c;
    const d = this.d;
    const e = this.e;
    const f = this.f;

    // Figure out if the winding direction is clockwise or counterclockwise
    const determinant = a * d - b * c;
    const ccw = determinant > 0 ? 1 : -1;

    // Since we only shear in x, we can use the x basis to get the x scale
    // and the rotation of the resulting matrix
    const sx = ccw * Math.sqrt(a * a + b * b);
    const thetaRad = Math.atan2(ccw * b, ccw * a);
    const theta = (180 / Math.PI) * thetaRad;
    const ct = Math.cos(thetaRad);
    const st = Math.sin(thetaRad);

    // We can then solve the y basis vector simultaneously to get the other
    // two affine parameters directly from these parameters
    const lam = (a * c + b * d) / determinant;
    const sy = (c * sx) / (lam * a - b) || (d * sx) / (lam * b + a);

    // Use the translations
    const tx = e - cx + cx * ct * sx + cy * (lam * ct * sx - st * sy);
    const ty = f - cy + cx * st * sx + cy * (lam * st * sx + ct * sy);

    // Construct the decomposition and return it
    return {
      // Return the affine parameters
      scaleX: sx,
      scaleY: sy,
      shear: lam,
      rotate: theta,
      translateX: tx,
      translateY: ty,
      originX: cx,
      originY: cy,

      // Return the matrix parameters
      a: this.a,
      b: this.b,
      c: this.c,
      d: this.d,
      e: this.e,
      f: this.f,
    };
  }

  // Flip matrix on x or y, at a given offset
  flip(axis: 'x' | 'y' | 'both' | true, around?: number) {
    return this.clone().flipO(axis, around);
  }

  flipO(axis: 'x' | 'y' | 'both' | true | number, around = 0) {
    return axis === 'x'
      ? this.scaleO(-1, 1, around, 0)
      : axis === 'y'
        ? this.scaleO(1, -1, 0, around)
        : this.scaleO(-1, -1, axis as number, around || (axis as number)); // Define an x, y flip point
  }

  // Initialize
  init(source: MatrixAlias | number | undefined, ...args: number[]) {
    const base = Matrix.fromArray([1, 0, 0, 1, 0, 0]);

    // ensure source as object
    const result = (
      Array.isArray(source)
        ? Matrix.fromArray(source)
        : typeof source === 'object' && Matrix.isMatrixLike(source as MatrixLike)
          ? source
          : typeof source === 'object'
            ? new Matrix().transform(source as MatrixTransformParam & { origin: CoordinateXY })
            : arguments.length === 6
              ? Matrix.fromArray([source as number, ...args])
              : base
    ) as MatrixLike;

    // Merge the source matrix with the base matrix
    this.a = result.a != null ? result.a : base.a;
    this.b = result.b != null ? result.b : base.b;
    this.c = result.c != null ? result.c : base.c;
    this.d = result.d != null ? result.d : base.d;
    this.e = result.e != null ? result.e : base.e;
    this.f = result.f != null ? result.f : base.f;

    return this;
  }

  inverse() {
    return this.clone().inverseO();
  }

  // Inverses matrix
  inverseO() {
    // Get the current parameters out of the matrix
    const a = this.a;
    const b = this.b;
    const c = this.c;
    const d = this.d;
    const e = this.e;
    const f = this.f;

    // Invert the 2x2 matrix in the top left
    const det = a * d - b * c;
    if (!det) throw new Error('Cannot invert ' + this);

    // Calculate the top 2x2 matrix
    const na = d / det;
    const nb = -b / det;
    const nc = -c / det;
    const nd = a / det;

    // Apply the inverted matrix to the top right
    const ne = -(na * e + nc * f);
    const nf = -(nb * e + nd * f);

    // Construct the inverted matrix
    this.a = na;
    this.b = nb;
    this.c = nc;
    this.d = nd;
    this.e = ne;
    this.f = nf;

    return this;
  }

  lmultiply(matrix: MatrixAlias) {
    return this.clone().lmultiplyO(matrix);
  }

  lmultiplyO(matrix: MatrixAlias): this {
    // eslint-disable-next-line
    const r = this;
    const l = matrix instanceof Matrix ? matrix : new Matrix(matrix);

    return Matrix.matrixMultiply(l, r, this) as this;
  }

  // Left multiplies by the given matrix
  multiply(matrix: MatrixAlias) {
    return this.clone().multiplyO(matrix);
  }

  multiplyO(matrix: MatrixAlias): this {
    // Get the matrices
    // eslint-disable-next-line
    const l = this;
    const r = matrix instanceof Matrix ? matrix : new Matrix(matrix);

    return Matrix.matrixMultiply(l, r, this) as this;
  }

  // Rotate matrix
  rotate(r: number, cx: number, cy: number) {
    return this.clone().rotateO(r, cx, cy);
  }

  rotateO(r: number, cx = 0, cy = 0) {
    // Convert degrees to radians
    r = radians(r);

    const cos = Math.cos(r);
    const sin = Math.sin(r);

    const { a, b, c, d, e, f } = this;

    this.a = a * cos - b * sin;
    this.b = b * cos + a * sin;
    this.c = c * cos - d * sin;
    this.d = d * cos + c * sin;
    this.e = e * cos - f * sin + cy * sin - cx * cos + cx;
    this.f = f * cos + e * sin - cx * sin - cy * cos + cy;

    return this;
  }

  // Scale matrix
  scale(scale: number, cx?: number, cy?: number): Matrix;
  scale(scaleX: number, scaleY: number, cx?: number, cy?: number): Matrix;
  scale(scale: number, ...args: number[]) {
    return this.clone().scaleO(scale, ...args);
  }

  scaleO(scale: number, cx?: number, cy?: number): this;
  scaleO(scaleX: number, scaleY: number, cx?: number, cy?: number): this;
  scaleO(x: number, y = x, cx = 0, cy = 0) {
    // Support uniform scaling
    if (arguments.length === 3) {
      cy = cx;
      cx = y;
      y = x;
    }

    const { a, b, c, d, e, f } = this;

    this.a = a * x;
    this.b = b * y;
    this.c = c * x;
    this.d = d * y;
    this.e = e * x - cx * x + cx;
    this.f = f * y - cy * y + cy;

    return this;
  }

  // Shear matrix
  shear(a: number, cx?: number, cy?: number) {
    return this.clone().shearO(a, cx, cy);
  }

  shearO(lx: number, _cx = 0, cy = 0) {
    const { a, b, c, d, e, f } = this;

    this.a = a + b * lx;
    this.c = c + d * lx;
    this.e = e + f * lx - cy * lx;

    return this;
  }

  // Skew Matrix
  skew(x: number, y?: number, cx?: number, cy?: number) {
    return this.clone().skewO(x, y, cx, cy);
  }

  skewO(x = 0, y = x, cx = 0, cy = 0) {
    // support uniformal skew
    if (arguments.length === 3) {
      cy = cx;
      cx = y;
      y = x;
    }

    // Convert degrees to radians
    x = radians(x);
    y = radians(y);

    const lx = Math.tan(x);
    const ly = Math.tan(y);

    const { a, b, c, d, e, f } = this;

    this.a = a + b * lx;
    this.b = b + a * ly;
    this.c = c + d * lx;
    this.d = d + c * ly;
    this.e = e + f * lx - cy * lx;
    this.f = f + e * ly - cx * ly;

    return this;
  }

  // SkewX
  skewX(x: number, cx: number, cy: number) {
    return this.skew(x, 0, cx, cy);
  }

  // SkewY
  skewY(y: number, cx: number, cy: number) {
    return this.skew(0, y, cx, cy);
  }

  toArray() {
    return [this.a, this.b, this.c, this.d, this.e, this.f];
  }

  // Convert matrix to string
  toString() {
    return 'matrix(' + this.a + ',' + this.b + ',' + this.c + ',' + this.d + ',' + this.e + ',' + this.f + ')';
  }

  // Transform a matrix into another matrix by manipulating the space
  transform(o: MatrixAlias) {
    // Check if o is a matrix and then left multiply it directly
    if (Matrix.isMatrixLike(o as MatrixLike)) {
      const matrix = new Matrix(o);
      return matrix.multiplyO(this);
    }

    // Get the proposed transformations and the current transformations
    const t = Matrix.formatTransforms(o as MatrixTransformParam & { origin?: CoordinateXY });
    // eslint-disable-next-line
    const current = this;
    const { x: ox, y: oy } = new Point({ x: t.ox, y: t.oy }).transform(current);

    // Construct the resulting matrix
    const transformer = new Matrix()
      .translateO(t.rx, t.ry)
      .lmultiplyO(current)
      .translateO(-ox, -oy)
      .scaleO(t.scaleX, t.scaleY)
      .skewO(t.skewX, t.skewY)
      .shearO(t.shear)
      .rotateO(t.theta)
      .translateO(ox, oy);

    // If we want the origin at a particular place, we force it there
    if (isFinite(t.px) || isFinite(t.py)) {
      const origin = new Point({ x: ox, y: oy }).transform(transformer);
      // TODO: Replace t.px with isFinite(t.px)
      // Doesnt work because t.px is also 0 if it wasnt passed
      const dx = isFinite(t.px) ? t.px - origin.x : 0;
      const dy = isFinite(t.py) ? t.py - origin.y : 0;
      transformer.translateO(dx, dy);
    }

    // Translate now after positioning
    transformer.translateO(t.tx, t.ty);
    return transformer;
  }

  // Translate matrix
  translate(x: number, y: number) {
    return this.clone().translateO(x, y);
  }

  translateO(x = 0, y = 0) {
    this.e += x;
    this.f += y;
    return this;
  }
}
