import { Node } from '../models';
import { Action } from '.';

export type NodesUpdateActionObjects = { nodes: Node[] };
export type NodesUpdateActionArgs = [args: Record<string, unknown>];
export class NodesUpdateAction extends Action<NodesUpdateActionObjects, NodesUpdateActionArgs> {
  static actionName = 'NodesUpdateAction';
  mergable = true;

  async redo({ nodes }: NodesUpdateActionObjects, args: Record<string, unknown>) {
    nodes.forEach((s) => {
      Object.assign(s, args);
    });
  }

  merge(args: NodesUpdateActionArgs) {
    return [Object.assign({}, this.args[0], args[0])] as NodesUpdateActionArgs;
  }
}
