<template>
  <svg v-if="points.length">
    <line
      :x1="points[0].x"
      :x2="points[1].x"
      :y1="points[0].y"
      :y2="points[1].y"
      sroke-width="1"
      stroke="black"
      stroke-dasharray="5 5"
    />

    <line
      :x1="points[1].x"
      :x2="points[2].x"
      :y1="points[1].y"
      :y2="points[2].y"
      sroke-width="1"
      stroke="black"
      stroke-dasharray="5 5"
    />

    <line
      :x1="points[2].x"
      :x2="points[3].x"
      :y1="points[2].y"
      :y2="points[3].y"
      sroke-width="1"
      stroke="black"
      stroke-dasharray="5 5"
    />

    <line
      :x1="points[3].x"
      :x2="points[0].x"
      :y1="points[3].y"
      :y2="points[0].y"
      sroke-width="1"
      stroke="black"
      stroke-dasharray="5 5"
    />
  </svg>
</template>

<script lang="ts">
import { computed } from 'vue';
import { Node, Slide } from '../../models';

export default {
  name: 'DumbSelection',
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const node = Node.get(props.id) as Node;

    const points = computed(() => {
      const slide = node.parent as Slide;
      return node.generateCornerPoints().map((p) => p.transformO(slide.ctm).transformO(slide.board.transform2));
    });

    return {
      points,
      node,
    };
  },
};
</script>

<style scoped>
svg {
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
}

line {
  animation: 1s infinite linear stroke;
}

@keyframes stroke {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 10;
  }
}
</style>
