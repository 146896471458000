export type Callback = (...data: any[]) => any;

const events = Symbol('Events');

export class EventEmitter {
  [events]: { [s: string]: Set<Callback> } = {};

  /**
   * Loops through listeners and calls them
   * @param event Name of the event to be emitted
   * @param data Data to be passed to the event handler
   */
  emit(event: string, ...data: any[]) {
    // runs all listener and returns false if any of the listener returns false
    return [...(this[events][event] || [])].reduce((acc, fn) => fn(...data) && acc, true);
  }

  /**
   * Binds a listener to an event
   * @param event Name of the event to be listend to
   * @param cb Listener
   */
  on(event: string, cb: Callback) {
    if (!this[events][event]) {
      this[events][event] = new Set();
    }
    this[events][event].add(cb);
  }

  /**
   * Unbinds a listener to an event
   * @param event Name of the event to be unbound
   * @param cb Listener
   */
  off(event: string, cb: Callback) {
    if (!this[events][event]) return;

    this[events][event].delete(cb);
  }
}
