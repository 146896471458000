import { LogLevels, INFO, DEBUG } from './log';

// You can change that
export const MEMORY_SIZE = Infinity;
export const BOARD_TIMEOUT = 120;
export const UNDO_STEPS = 20;
export const CROSS_ORIGINS = ['https://sessions.onlinelearningsessions.com'];
export const LOG_LEVEL: LogLevels = INFO;

// For telling the system to use Beziere curves for freehand drawing or not.
export const ENABLE_BEZIERE_LINE = false;

// For telling the system to allow smoothing of freehand drawing or not
export const ENABLE_BUFFER = false;

// This number defines how many of the last points are averaged to smooth out the line
export const BUFFER_SIZE = 4;

// This number defines how many of the points we use to actualy draw the line
// 2 means every second point, 3 every third and so on
// This is only active if ENABLE_BEZIERE_LINE is true
export const POINT_SAVING_RATE = 2;
