import { Dehydrate, Field } from 'vue-model';
import { Participant, Model, Slide, Node, Matrix } from '../models';
import { v4 as uuid } from 'uuid';
import { Box } from './Slide';
import { useHistory } from '../compositions/useHistory';
import { User } from './User';
import { nextTick } from 'vue';
import type SlideComponent from '../components/Slide.vue';

export class Board extends Model {
  static model = 'Board' as const;
  static primaryKey = '_id';

  version!: number;
  participants!: Participant[];
  slides!: Slide[];
  password!: string;
  toolbar!: string;
  currentSlide!: Slide | null;
  copied: Dehydrate<typeof Node>[] = [];
  transform!: Matrix;
  transform2!: Matrix;
  ctrl!: boolean;
  shift!: boolean;
  position!: Box;
  currentIndex!: number;
  fillColor!: string;
  fillOpacity!: number;
  strokeColor!: string;
  strokeOpacity!: number;
  strokeWidth!: number;
  strokeStyle!: 'solid' | 'dashed' | 'dotted';
  colorSettingsOpen!: null | Record<string, unknown>;
  firstSlide!: Slide;
  lastSlide!: Slide;
  readonly!: boolean;
  navigationEnabled!: boolean;
  localUserId!: string | null;
  localUser!: User;
  copiedSlide!: Dehydrate<typeof Slide> | null;
  pointers!: { [userId: string]: { x: number; y: number } };
  slidesCache!: Slide[];
  seq!: number;
  order!: string[];
  locksDisabled!: boolean;
  showHiddenSlides!: number[];
  hiddenSlides!: Slide[];
  hiddenSlideRefs!: (typeof SlideComponent)[];
  localParticipant!: Participant;
  needsCompat!: boolean;

  static fields(): Record<string, Field> {
    return {
      _id: this.string(uuid),
      version: this.number(1),
      participants: this.hasMany(Participant),
      localParticipant: this.computed((board) => {
        return board.participants.find((p) => p.userId === board.localUserId) || new Participant();
      }),
      localUserId: this.string(null),
      localUser: this.belongsTo(User, 'localUserId'),
      slidesCache: this.hasMany(Slide),
      order: this.array([], Object as any),
      slides: this.computed(
        (board) => {
          return Slide.get(board.order);
        },
        (board, val: Record<string, unknown>[]) => {
          board.removeAll();

          if (!val) return;

          Slide.getOrCreate(val).forEach((slide) => {
            board.addSlide(slide);
          });
        },
      ),
      password: this.string(null),
      toolbar: this.string('select'),
      fillColor: this.string('#000000'),
      fillOpacity: this.number(1),
      strokeColor: this.string('#000000'),
      strokeOpacity: this.number(1),
      strokeWidth: this.number(2),
      strokeStyle: this.string('solid'),
      copiedSlide: this.field<Dehydrate<typeof Slide> | null>(null, Object as any),
      currentIndex: this.number(-1),
      currentSlide: this.computed((board) => {
        return board.slides[board.currentIndex];
      }),
      colorSettingsOpen: this.field(null, Object),
      transform: this.field({}, Matrix),
      transform2: this.field({}, Matrix),
      position: this.field<Box>({ x: 0, y: 0, width: 1, height: 1 }, Object as any),
      ctrl: this.boolean(false),
      shift: this.boolean(false),
      firstSlide: this.computed((board) => {
        return board.slides[0];
      }),
      lastSlide: this.computed((board) => {
        return board.slides[board.slides.length - 1];
      }),
      readonly: this.boolean(false),
      navigationEnabled: this.boolean(true),
      pointers: this.field<{ [userId: string]: { x: number; y: number } }>({}, Object as any),
      seq: this.number(0),
      locksDisabled: this.boolean(false),
      hiddenSlideRefs: this.array<typeof SlideComponent>([], Object as any),
      showHiddenSlides: this.array<number>([], Object as any),
      hiddenSlides: this.hasManyBy(Slide, 'showHiddenSlides'),
      needsCompat: this.boolean(false),
    };
  }

  static cascades() {
    return ['slidesCache'];
  }

  static hidden() {
    return [
      'participants',
      'slides',
      'password',
      'toolbar',
      'currentSlide',
      'copied',
      'transform',
      'transform2',
      'ctrl',
      'shift',
      'position',
      'currentIndex',
      'fillColor',
      'fillOpacity',
      'strokeColor',
      'strokeWidth',
      'colorSettingsOpen',
      'firstSlide',
      'lastSlide',
      'localUserId',
      'copiedSlide',
      'pointers',
      'readonly',
    ];
  }

  dehydrateAsCopy<T extends typeof Model>(this: InstanceType<T>, relations = this.static().cascades()) {
    const obj = super.dehydrateAsCopy(relations);
    const o = obj as Dehydrate<typeof Board>;
    const order = o.order as string[];
    o.slides = (o.slidesCache as Slide[]).sort((a, b) => order.indexOf(a._id) - order.indexOf(b._id));
    delete o.slidesCache;
    return obj;
  }

  setPointer(id: string, pos: { x: number; y: number } | null, slide: number) {
    if (!pos || slide !== this.currentIndex) delete this.pointers[id];
    else this.pointers[id] = pos;
  }

  nextSlide() {
    this.setSlide(this.currentIndex + 1);
  }

  prevSlide() {
    this.setSlide(this.currentIndex - 1);
  }

  setSlide(index: number) {
    this.emit('set-slide', index);
    this.setPointer(useHistory().user._id, null, 0);
  }

  removeSlide(slide: Slide) {
    const index = this.order.indexOf(slide._id);
    if (index > -1) {
      this.order.splice(index, 1);
    }
    slide.boardId = null;
  }

  addSlide(slide: Slide, index?: number) {
    if (index == null) {
      this.order.push(slide._id);
    } else {
      this.order.splice(index, 0, slide._id);
    }

    slide.boardId = this._id;
  }

  removeAll() {
    this.slides.forEach((slide) => this.removeSlide(slide));
  }

  indexOfSlide(slide: Slide) {
    return this.order.indexOf(slide._id);
  }

  getChildren(): Slide[] {
    return this.slides;
  }

  async getSVGNodes(indexes: number[], needsCompat = false) {
    this.hiddenSlideRefs = [];
    this.showHiddenSlides = indexes;
    this.needsCompat = needsCompat;
    await nextTick();

    const slides = this.hiddenSlideRefs.map((slide) => (slide as any).clickTarget as SVGSVGElement);
    this.showHiddenSlides = [];
    return slides;
  }
}

Board.boot();
