import { Shape } from '../models';
import { Action } from './';

type PointLike = { x: number; y: number };
export type NodeMovePointActionObjects = { node: Shape };
export type NodeMovePointActionArgs = [index: number, pos: PointLike];
export class NodeMovePointAction extends Action<NodeMovePointActionObjects, NodeMovePointActionArgs> {
  static actionName = 'NodeMovePointAction';
  mergable = true;

  async redo({ node }: NodeMovePointActionObjects, index: number, pos: PointLike) {
    const point = node.points[index];
    Object.assign(point, pos);
  }

  merge(args: NodeMovePointActionArgs) {
    return args;
  }
}
