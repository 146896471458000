import { computed } from 'vue';
import { getPointFromEvent } from '../../utils/utils';
import { Shape } from '../../models';

export class Rect extends Shape {
  static model = 'Rect' as const;
  static base = Shape;

  declare type: 'Rect';

  static fields() {
    return {
      ...Shape.fields(),
      type: this.string('Rect'),
    };
  }

  getPath() {
    return computed(() => {
      if (this.points.length < 2) {
        return super.getPath.call(this).value;
      }

      const [tl, tr, br, bl] = this.generateUntransformedCornerPoints();

      return `M ${tl.x} ${tl.y} ${tr.x} ${tr.y} ${br.x} ${br.y} ${bl.x} ${bl.y}z`;
    });
  }

  onMouseUp(ev: MouseEvent) {
    const p = getPointFromEvent(ev, this.matrix?.value, true);
    const last = this.getLastPoint();

    if (!this.hasMoved) {
      return this.cancelDrawing();
    }

    if (last.fixed) {
      this.addPoint(p, true);
    } else {
      Object.assign(last, { x: p.x, y: p.y, fixed: true }).round();
    }

    this.finishShape();
  }
}

Rect.boot();
