import { toRaw } from 'vue';
import { Dehydrate, isLocal, Model as BaseModel, PropertyNames, Relation, RelationPropertyNames } from 'vue-model';

export class Model extends BaseModel {
  static primaryKey = '_id';
  _id!: string;

  dehydrateAsCopy<T extends typeof Model>(this: InstanceType<T>, relations = this.static().cascades()) {
    const obj = { ...toRaw(this._cache), __class__: this.static().model } as Dehydrate<T>;

    (this.static().hidden() as PropertyNames<T>[]).forEach((field) => {
      delete obj[field];
    });

    const pKey = this.static().primaryKey as PropertyNames<T> | PropertyNames<T>[];

    if (Array.isArray(pKey)) {
      pKey.forEach((key) => {
        delete obj[key];
      });
    } else {
      delete obj[pKey];
    }

    const fields = this.static().fields() as { [P in RelationPropertyNames<T>]: Relation };

    if (relations) {
      (relations as RelationPropertyNames<T>[]).forEach((field) => {
        const val = this[field] as any;
        const fieldDef = fields[field];

        if (!val) return;
        if (Array.isArray(val)) {
          (obj[field] as any) = val.map((o: Model) => o.dehydrateAsCopy());

          if (isLocal(fieldDef)) {
            const fKey = fieldDef.foreignKey;
            // const localKey = fieldDef.otherKey
            delete (obj as any)[fKey as string]; //= (obj[field] as any).map((o: any) => o[localKey as any])
          } else {
            const fKey = fieldDef.foreignKey;
            // const localKey = fieldDef.otherKey
            (obj as any)[field].forEach((o: any) => {
              delete o[fKey as string];
            });
            // delete (obj as any)[field][fKey as string] //= (obj[field] as any).map((o: any) => o[localKey as any])
          }
        } else {
          obj[field] = val.dehydrateAsCopy();

          if (isLocal(fieldDef)) {
            const fKey = fieldDef.foreignKey;
            // const localKey = fieldDef.otherKey
            delete (obj as any)[fKey as string]; // = (obj as any)[field][localKey as any]
          } else {
            const fKey = fieldDef.foreignKey;
            // const localKey = fieldDef.otherKey
            delete (obj as any)[field][fKey as string]; //= (obj as any)[field][localKey as any]
          }
        }
      });
    }

    return obj;
  }
}
