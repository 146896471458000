<template>
  <div class="flex gap-1.5 row-span-2 shrink-0 [&>*]:rounded [&>*]:border [&>*]:border-black">
    <ColorPicker
      :modelValue="currentColor"
      @update:model-value="setCurrentColor($event)"
      :baseZIndex="110"
      :style="`border-radius: 20px; width: 25px; height:  25px; overflow: hidden;`"
      @hide="
        finishShapePropUpdate();
        colorMenuIsOpen = false;
      "
      @show="colorMenuIsOpen = true"
      title="Color Picker"
    />
    <div class="flex justify-center !w-1 border-none"><div class="w-px bg-gray-300"></div></div>
    <button
      v-for="(color, index) in lastUsedColors"
      :style="`background: #${color}; width: 25px; height: 25px`"
      :class="{ 'ring-1 ring-black': activeColorIndex === index }"
      @click="activeColorIndex = index"
    ></button>
  </div>
</template>

<script lang="ts" setup>
import { Board, Node } from '@/models';
import { createGetter, createSetter, finishShapePropUpdate } from '@/utils/shapeProps';
import ColorPicker from 'primevue/colorpicker';
import { ref, watch } from 'vue';

const props = defineProps<{
  boardId: string;
  prop: 'fillColor' | 'strokeColor';
}>();

const lastUsedColors = ref<string[]>(['000000', 'ff0000', '861fdb', '0aa80f', '0000ff', 'f041cd', 'f58813']);
const currentColor = ref<string>();
const activeColorIndex = ref<number>();
const colorMenuIsOpen = ref(false);

console.log('remounted');

const board = Board.get(props.boardId) as Board;

const setColor = createSetter(board, props.prop);

const getColor = createGetter(board, props.prop) as (nodes?: Node[]) => string | undefined;

const setCurrentColor = (val: string | undefined, immediate = false, nodes = board.currentSlide?.selectedNodes) => {
  currentColor.value = val;

  if (!val) return;
  if (nodes?.length) {
    activeColorIndex.value = undefined;
    setColor('#' + val, immediate, nodes);
  } else if (activeColorIndex.value != null) {
    lastUsedColors.value[activeColorIndex.value] = val;
    setColor('#' + val, immediate, []);
  }
};

watch(activeColorIndex, (val) => {
  if (val == null) return;
  setCurrentColor(lastUsedColors.value[val], true);
});

watch(
  () => board?.currentSlide?.selectedNodes,
  (newVal) => {
    currentColor.value = getColor(newVal)?.slice(1);
  },
  { immediate: true },
);
</script>
