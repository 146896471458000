import { computed } from 'vue';
import { getPointFromEvent } from '../../utils/utils';
import { Shape } from '../../models';

export class Circle extends Shape {
  static model = 'Circle' as const;
  static base = Shape;

  declare type: 'Circle';

  static fields() {
    return {
      ...Shape.fields(),
      type: this.string('Circle'),
    };
  }

  getPath() {
    return computed(() => {
      const points = this.points;

      if (points.length < 2) {
        return super.getPath.call(this).value;
      }

      const p1 = points[0];
      const p2 = points[1];

      const vec = p2.sub(p1);
      const rx = vec.x / 2;
      const ry = vec.y / 2;

      // const c = p1.add(vec.div(2))

      const path = `M${p1.x} ${p1.y + ry} A${rx} ${ry} 0 1 0 ${p2.x} ${p1.y + ry} A${rx} ${ry} 0 0 0 ${p1.x} ${p1.y + ry}z`;
      return path;

      // const cx = point1.x
      // const cy = point1.y
      // const r = point1.sub(point2).length()
      // const path = `M${cx - r} ${cy} a${r} ${r} 0 1 0 ${r * 2} 0 a${r} ${r} 0 1 0 -${r * 2} 0`
      // return path
    });
  }

  onMouseUp(ev: MouseEvent) {
    const p = getPointFromEvent(ev, this.matrix?.value, true);
    const last = this.getLastPoint();

    if (!this.hasMoved) {
      return this.cancelDrawing();
    }

    if (last.fixed) {
      this.addPoint(p, true);
    } else {
      Object.assign(last, { x: p.x, y: p.y, fixed: true });
    }

    this.finishShape();
  }
}

Circle.boot();
