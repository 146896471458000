import { computed, reactive, ref } from 'vue';
import { Baseline, Point } from '..';
import { BUFFER_SIZE, POINT_SAVING_RATE, ENABLE_BEZIERE_LINE, ENABLE_BUFFER } from '../../config';
import { getPointFromEvent } from '../../utils/utils';

const buffer: { x: number; y: number }[] = reactive([]); // Contains the last positions of the mouse cursor

const appendToBuffer = function (pt: { x: number; y: number }) {
  buffer.push(pt);
  while (buffer.length > BUFFER_SIZE) {
    buffer.shift();
  }
};

// Calculate the average point, starting at offset in the buffer
const getAveragePoint = function (offset: number, ignoreEvenCount = false) {
  let len = buffer.length;
  if (len % 2 === 1 || len >= BUFFER_SIZE || ignoreEvenCount) {
    if (len % 2 === 0) len--;
    let totalX = 0;
    let totalY = 0;
    let pt, i;
    let count = 0;
    for (i = offset; i < len; i++) {
      count++;
      pt = buffer[i];
      totalX += pt.x;
      totalY += pt.y;
    }
    return {
      x: totalX / count,
      y: totalY / count,
    };
  }
  return null;
};

const bezierCommand = (point: Point, i: number, a: Point[]) => {
  // start control point
  const [cpsX, cpsY] = controlPoint(a[i - 1], a[i - 2], point); // end control point
  const [cpeX, cpeY] = controlPoint(point, a[i - 1], a[i + 1], true);
  return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point.x},${point.y}`;
};

const controlPoint = (current: Point, previous: Point, next: Point, reverse = false) => {
  // When 'current' is the first or last point of the array
  // 'previous' or 'next' don't exist.
  // Replace with 'current'
  const p = previous || current;
  const n = next || current; // The smoothing ratio
  const smoothing = 0.2; // Properties of the opposed-line
  const o = line(p, n); // If is end-control-point, add PI to the angle to go backward
  const angle = o.angle + (reverse ? Math.PI : 0);
  const length = o.length * smoothing; // The control point position is relative to the current point
  const x = current.x + Math.cos(angle) * length;
  const y = current.y + Math.sin(angle) * length;
  return [x, y];
};

const line = (pointA: Point, pointB: Point) => {
  const lengthX = pointB.x - pointA.x;
  const lengthY = pointB.y - pointA.y;
  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX),
  };
};

const avg = (ps: Point[]) =>
  ps.reduce((total: Point, o: Point) => {
    total.x += o.x / ps.length;
    total.y += o.y / ps.length;
    return total;
  }, new Point());

const tmpPoints: Point[] = reactive([]);
const lastPoint = ref<Point | null>(null);

export class FreeLine extends Baseline {
  static model = 'FreeLine' as const;
  static base = Baseline;

  declare type: 'FreeLine';
  declare selectionComponent: 'NodeSelection';

  static fields() {
    return {
      ...Baseline.fields(),
      type: this.string('FreeLine'),
      selectionComponent: this.string('NodeSelection'),
      closed: this.boolean(false),
    };
  }

  onMouseDown(ev: MouseEvent) {
    const p = getPointFromEvent(ev, this.matrix?.value, true);

    if (ENABLE_BUFFER) {
      buffer.length = 0;
      appendToBuffer(p);
    }

    super.addPoint(new Point(p), true);
    return p;
  }

  onMouseMove(ev: MouseEvent) {
    this.hasMoved = true;

    const p = getPointFromEvent(ev, this.matrix?.value, true);

    if (ENABLE_BUFFER) {
      appendToBuffer(p);

      const pt = getAveragePoint(0);

      if (pt) {
        this.addPoint(new Point(pt), true);
      }

      lastPoint.value = p;
    } else {
      this.addPoint(p, true);
    }

    this.emit('update:points');
  }

  onMouseUp(ev: MouseEvent) {
    if (!this.hasMoved) {
      return this.cancelDrawing();
    }

    const p = getPointFromEvent(ev, this.matrix?.value, true);

    if (ENABLE_BUFFER) {
      appendToBuffer(p);

      const pts = [];
      for (let offset = 2; offset < buffer.length; offset += 2) {
        pts.push(getAveragePoint(offset) as Point);
      }

      pts.map((p) => this.addPoint(new Point(p)), true);

      if (tmpPoints.length) {
        super.addPoint(new Point(avg(tmpPoints)), true);
        tmpPoints.length = 0;
      }
    }

    this.points.pop();
    super.addPoint(p, true);

    this.finishShape();
    buffer.length = 0;
    tmpPoints.length = 0;
    lastPoint.value = null;
  }

  addPoint(p: Point, fix = false) {
    if (ENABLE_BEZIERE_LINE) {
      if (tmpPoints.length < POINT_SAVING_RATE) {
        tmpPoints.push(p);
      }

      if (tmpPoints.length === POINT_SAVING_RATE) {
        super.addPoint(avg(tmpPoints), fix);
        tmpPoints.length = 0;
      }
    } else {
      super.addPoint(p, fix);
    }

    return p;
  }

  getPath() {
    if (!ENABLE_BEZIERE_LINE) {
      return computed(() => {
        if (!this.points.length) return 'M0 0';

        let tmpPath = '';
        for (let offset = 2; offset < buffer.length; offset += 2) {
          const pt = getAveragePoint(offset, true) as Point;
          tmpPath += ' L' + pt.x + ' ' + pt.y;
        }

        if (!this.finished && lastPoint.value) {
          tmpPath += ' L' + lastPoint.value.x + ' ' + lastPoint.value.y;
        }

        return 'M ' + this.points.map((p) => p.x + ' ' + p.y).join(' ') + (!this.finished ? tmpPath : '');
      });
    }

    return computed(() => {
      if (!this.points.length) return 'M0 0';

      let tmpPath = '';
      for (let offset = 2; offset < buffer.length; offset += 2) {
        const pt = getAveragePoint(offset, true) as Point;
        tmpPath += ' L' + pt.x + ' ' + pt.y;
      }

      if (!this.finished && lastPoint.value) {
        tmpPath += ' L' + lastPoint.value.x + ' ' + lastPoint.value.y;
      }

      return (
        this.points.reduce(
          (acc, point, i, a) =>
            i === 0 // if first point
              ? `M ${point.x},${point.y}` // else
              : `${acc} ${bezierCommand(point, i, a)}`,
          '',
        ) + (!this.finished ? tmpPath : '')
      );
    });
  }
}

FreeLine.boot();
