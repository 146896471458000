<template>
  <teleport to="body">
    <transition name="modal">
      <div v-if="modelValue" class="dialog-wrapper" @click="close">
        <div v-bind="$attrs" class="dialog" :class="[type ? 'dialog-' + type : '', customClass]" @click.stop>
          <div class="dialog-header" v-if="title || $slots.title || $slots.close">
            <div class="dialog-title" v-if="title || $slots.title">
              <slot name="title">
                {{ title }}
              </slot>
            </div>
            <div class="dialog-close" @click="close">
              <slot name="close">
                <i class="bx bx-x" />
              </slot>
            </div>
          </div>
          <div class="dialog-content">
            <slot :close="close" :ok="ok" />
          </div>
          <div class="dialog-footer">
            <slot name="footer" :close="close" :ok="ok">
              <div>
                <ButtonYellow v-if="!yesOrNo" @click="close">Cancel</ButtonYellow>
                <ButtonYellow v-if="!yesOrNo" ref="focus" @click="ok(true)">OK</ButtonYellow>
                <ButtonYellow v-if="yesOrNo" ref="focus" @click="ok(true)">Yes</ButtonYellow>
                <ButtonYellow v-if="yesOrNo" @click="ok(false)">No</ButtonYellow>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<style lang="scss" scoped>
@import '../main.scss';

.dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;

  .dialog {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    max-height: 100vh;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    background: #fff;
    overflow: hidden;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
    }

    .dialog-header {
      padding: 10px 20px;
    }

    .dialog-title {
      flex: 1 1 auto;
      font-size: 20px;
    }

    .dialog-close {
      cursor: pointer;
    }

    .dialog-close > i {
      font-size: 25px;
    }

    .dialog-content {
      flex: 1 1 auto;
      font-size: 15px;
      flex-direction: column;
      padding: 10px 20px;
    }

    .dialog-content,
    .dialog-footer {
      box-sizing: border-box;
      background: #fff;
      border-radius: 2px;
      overflow: auto;
      max-height: calc(100vh - 180px);
      max-width: calc(100vw - 180px);
      transition: all 0.3s ease;
    }

    .dialog-footer > div {
      margin-left: auto;
      padding: 10px 20px;
      gap: 4px;
      display: flex;
    }

    .dialog-footer .btn {
      border-radius: 5px;
      background: $icon-highlight;
      border: none;
      font-weight: bold;
      color: white;
      height: 25px;
      margin-left: 5px;
      align-self: flex-end;
    }
  }
}
</style>

<script lang="ts">
import { ref, watch, defineComponent } from 'vue';
import ButtonYellow from './ButtonYellow.vue';
export default defineComponent({
  name: 'Dialog',
  inheritAttrs: false,
  components: { ButtonYellow },
  props: {
    title: {
      type: String,
    },
    type: {
      type: String,
      default: 'info',
    },
    customClass: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    yesOrNo: {
      type: Boolean,
      default: false,
    },
    nofocus: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'ok', 'cancel'],
  setup(props, ctx) {
    const close = () => {
      ctx.emit('update:modelValue', false);
      ctx.emit('cancel');
    };

    const ok = (yesOrNor?: boolean) => {
      ctx.emit('update:modelValue', false);
      ctx.emit('ok', yesOrNor);
    };

    const focus = ref<HTMLButtonElement | null>(null);

    watch(
      () => props.modelValue,
      (value) => {
        if (value && !props.nofocus) {
          focus.value?.focus();
        }
      },
      { flush: 'post' },
    );

    return { close, ok, focus };
  },
});
</script>
