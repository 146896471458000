<template>
  <div ref="graph" class="w-full h-full"></div>
</template>

<script setup lang="ts">
import Desmos from 'desmos';
import { onMounted, ref, shallowRef } from 'vue';

const graph = ref<HTMLDivElement>();
const calculator = shallowRef();

onMounted(() => {
  calculator.value = Desmos.GraphingCalculator(graph.value, {'pasteGraphLink':true});
});

const save = () => {
  return calculator.value.screenshot({
    width: 400,
    height: 400,
    targetPixelRatio: 2,
  });
};

defineExpose({ save });
</script>
