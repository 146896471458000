import { getPointFromEvent, pureBBox } from '../utils/utils';
import {
  Slide,
  Group,
  Point,
  Circle,
  Line,
  Rect,
  Shape,
  Polygon,
  Matrix,
  Image,
  Model,
  FreeLine,
  Pointer,
  Text,
  LineSegment,
  User,
} from '../models';
import { v4 } from 'uuid';
import { NodeTransformAction } from '../actions';
import { Board } from './Board';
import { Dehydrate, Field } from '../../../vue-model/src/Model';
import { Model as BaseModel } from 'vue-model';
import { history } from '../History';
import { MatrixLike } from '../utils/types';

class MatrixWrapper {
  constructor(m: MatrixLike, ...args: any[]) {
    if (!Array.isArray(m)) {
      return new Matrix(m, ...args);
    }
    return m.map((p) => new Matrix(p.x, p.y));
  }
}

export class Node extends Model {
  static model = 'Node';
  boardId!: string;
  board!: Board;
  parent!: Group | Slide;
  parentId!: string | null;
  type!: string;
  locked!: boolean;
  hidden!: boolean;
  transform!: Matrix;
  transformId!: string;
  selectionComponent!: string;
  selected!: boolean;
  finished!: boolean;
  fillColor!: string;
  fillOpacity!: number;
  strokeColor!: string;
  strokeWidth!: number;
  url!: string | null;
  ownerId!: string;
  owner!: User;

  static fields(): Record<string, Field> {
    return {
      _id: this.string(v4),
      boardId: this.string(null),
      board: this.belongsTo(Board, 'boardId'),
      parent: this.computed(
        (node) => {
          return Slide.get(node.parentId as string) || Group.get(node.parentId as string);
        },
        (node, parent) => {
          if (!parent) {
            node.parentId = null;
            return;
          }

          if (parent instanceof Model) {
            parent.save();
          } else {
            if (parent.viewbox) {
              parent = Slide.create(parent);
            } else {
              parent = Group.create(parent);
            }
          }
          node.parentId = parent.id;
        },
      ),
      parentId: this.string(null),
      type: this.string('Node'),
      locked: this.boolean(false),
      hidden: this.boolean(false),
      transform: this.field<Matrix>({}, MatrixWrapper as any),
      selectionComponent: this.string('NodeSelection'),
      selected: this.boolean(false),
      finished: this.boolean(true),
      url: this.string(null),
      ownerId: this.string(() => history().user._id).nullable(),
      owner: this.belongsTo(User, 'ownerId'),
    };
  }

  static types() {
    return {
      Node: Node,
      Shape: Shape,
      Group: Group,
      Circle: Circle,
      Line: Line,
      FreeLine: FreeLine,
      LineSegment: LineSegment,
      Polygon: Polygon,
      Rect: Rect,
      Image: Image,
      Pointer: Pointer,
      Text: Text,
    };
  }

  static cascades() {
    return [] as string[];
  }

  static hidden() {
    return ['selected', 'finished'];
  }

  dehydrateAsCopy<T extends typeof Model>(this: InstanceType<T>, relations = this.static().cascades()) {
    const obj = super.dehydrateAsCopy(relations);
    const o = obj as Dehydrate<typeof Node>;
    delete o.parentId;
    return obj;
  }

  getScreenCtm(): Matrix {
    if (this.parent instanceof Slide) return this.parent.screenCtm;
    return this.parent.getScreenCtm().multiply(this.parent.transform);
  }

  traverse(fn: (node: Node) => void, all = false): void {
    if (this instanceof Shape) {
      return fn(this);
    }

    if (this instanceof Group) {
      this.nodes.forEach((node: Node) => {
        node.traverse(fn);
      });
    }

    if (all) {
      fn(this);
    }
  }

  firstShape(): Shape {
    if (this instanceof Shape) {
      return this;
    }

    if (this instanceof Group) {
      for (const node of this.nodes) {
        if (node instanceof Shape) return node;

        const n = node.firstShape();
        if (n) return n;
      }
    }

    // Never gonna happen
    return new Shape();
  }

  generateCornerPoints(): [Point, Point, Point, Point] {
    throw new Error('This method is abstract');
  }

  onScaleCorner(ev: PointerEvent, pointIndex: number) {
    // Trigger the whiteboard indicator to start
    window.reportWhiteboardAction('drawing-start');

    ev.stopPropagation();

    const slide = this.parent as Slide;
    const matrix = slide.screenCtm;
    const initialTransform = this.transform.clone();

    let ev1 = ev;
    let { ctrlKey, shiftKey } = ev;

    const action = new NodeTransformAction();
    action.start();
    action.execute({ node: this }, new Matrix());

    const keydown = (keyEv: KeyboardEvent) => {
      if (keyEv.key === 'Shift') {
        shiftKey = true;
      } else if (keyEv.key === 'Control') {
        ctrlKey = true;
      }
      Object.assign(this.transform, initialTransform);
      action.reset();
      const ev2 = ev1;
      ev1 = ev;
      pointermove(ev2);
    };

    const keyup = (keyEv: KeyboardEvent) => {
      if (keyEv.key === 'Shift') {
        shiftKey = false;
      } else if (keyEv.key === 'Control') {
        ctrlKey = false;
      }
      Object.assign(this.transform, initialTransform);
      action.reset();
      const ev2 = ev1;
      ev1 = ev;
      pointermove(ev2);
    };

    const pointermove = (ev2: PointerEvent) => {
      Object.assign(this.transform, initialTransform);
      action.reset();
      const { x: x1, y: y1 } = getPointFromEvent(ev, matrix.inverse());
      const { x: x2, y: y2 } = getPointFromEvent(ev2, matrix.inverse());

      const xyMove = {
        x: x2 - x1,
        y: y2 - y1,
      };

      ev1 = ev2;

      const points = this.generateCornerPoints(); // .map((p: Point) => p.transformO(this.transform))
      const bboxBefore = pureBBox(points);

      // const widthHeightIndex = (pointIndex + 1) % 2 + 2
      const [, , beforeWidth, beforeHeight] = bboxBefore;

      const p = points[(pointIndex + 2) % 4];
      let ox = 0;
      let oy = 0;

      if (shiftKey) {
        ox = bboxBefore[0] + beforeWidth / 2;
        oy = bboxBefore[1] + beforeHeight / 2;
      } else {
        ox = p.x;
        oy = p.y;
      }

      const xy = pointIndex % 2 ? 'x' : 'y';
      const xy2 = pointIndex % 2 ? 'y' : 'x';

      const factor = shiftKey ? 2 : 1;

      points[pointIndex].x += xyMove.x * factor;
      points[pointIndex].y += xyMove.y * factor;
      points[(pointIndex + 1) % 4][xy] += xyMove[xy] * factor;
      points[(pointIndex + 3) % 4][xy2] += xyMove[xy2] * factor;

      const bboxAfter = pureBBox(points);

      // const widthHeight = bboxAfter[widthHeightIndex]
      // const scale = widthHeight / bboxBefore[widthHeightIndex]

      const scaleX = bboxAfter[2] / beforeWidth;
      const scaleY = bboxAfter[3] / beforeHeight;
      const scale = Math.max(scaleX, scaleY);

      if (ctrlKey) {
        action.execute({ node: this }, new Matrix({ scale, ox, oy }));
        // this.transform.scaleO(scaleX, scaleY, ox, oy)
      } else {
        action.execute({ node: this }, new Matrix({ scaleX, scaleY, ox, oy }));
        // this.transform.scaleO(scale, ox, oy)
      }
    };

    const pointerup = (_ev: PointerEvent) => {
      // pointermove(ev)
      // Trigger the whiteboard indicator to stop
      window.reportWhiteboardAction('drawing-stop');

      action.stop();
      document.removeEventListener('pointermove', pointermove);
      document.removeEventListener('pointerup', pointerup);
      document.removeEventListener('keydown', keydown);
      document.removeEventListener('keyup', keyup);
    };

    document.addEventListener('pointermove', pointermove);
    document.addEventListener('pointerup', pointerup);
    document.addEventListener('keydown', keydown);
    document.addEventListener('keyup', keyup);
  }

  onScale(ev: PointerEvent, pointIndex: number) {
    // Trigger the whiteboard indicator to start
    window.reportWhiteboardAction('drawing-start');

    ev.stopPropagation();

    const slide = this.parent as Slide;
    const matrix = slide.screenCtm;
    const initialTransform = this.transform.clone();

    let ev1 = ev;
    let { ctrlKey, shiftKey } = ev;

    const action = new NodeTransformAction();
    action.start();
    action.execute({ node: this }, new Matrix());

    const keydown = (keyEv: KeyboardEvent) => {
      if (keyEv.key === 'Shift') {
        shiftKey = true;
      } else if (keyEv.key === 'Control') {
        ctrlKey = true;
      }
      Object.assign(this.transform, initialTransform);
      action.reset();
      const ev2 = ev1;
      ev1 = ev;
      pointermove(ev2);
    };

    const keyup = (keyEv: KeyboardEvent) => {
      if (keyEv.key === 'Shift') {
        shiftKey = false;
      } else if (keyEv.key === 'Control') {
        ctrlKey = false;
      }
      Object.assign(this.transform, initialTransform);
      action.reset();
      const ev2 = ev1;
      ev1 = ev;
      pointermove(ev2);
    };

    const pointermove = (ev2: PointerEvent) => {
      const { x: x1, y: y1 } = getPointFromEvent(ev1, matrix.inverse());
      const { x: x2, y: y2 } = getPointFromEvent(ev2, matrix.inverse());

      const xyMove = {
        x: x2 - x1,
        y: y2 - y1,
      };

      ev1 = ev2;

      const points = this.generateCornerPoints(); // .map((p: Point) => p.transformO(this.transform))// pointsRef.value
      const bboxBefore = pureBBox(points);

      const widthHeightIndex = ((pointIndex + 1) % 2) + 2;
      const [, , beforeWidth, beforeHeight] = bboxBefore;
      const width = widthHeightIndex === 3 ? beforeWidth / 2 : 0;
      const height = widthHeightIndex === 2 ? beforeHeight / 2 : 0;

      const p = points[(pointIndex + 2) % 4];
      let ox = 0;
      let oy = 0;

      if (shiftKey) {
        ox = bboxBefore[0] + beforeWidth / 2;
        oy = bboxBefore[1] + beforeHeight / 2;
      } else {
        if (pointIndex === 0) {
          ox = p.x - width;
          oy = p.y;
        }
        if (pointIndex === 1) {
          ox = p.x;
          oy = p.y - height;
        }
        if (pointIndex === 2) {
          ox = p.x + width;
          oy = p.y;
        }
        if (pointIndex === 3) {
          ox = p.x;
          oy = p.y + height;
        }
      }

      const xy = pointIndex % 2 ? 'x' : 'y';

      const factor = shiftKey ? 2 : 1;

      points[pointIndex][xy] += xyMove[xy] * factor;
      points[(pointIndex + 1) % 4][xy] += xyMove[xy] * factor;

      const bboxAfter = pureBBox(points);

      const widthHeight = bboxAfter[widthHeightIndex];
      const scale = widthHeight / bboxBefore[widthHeightIndex];

      const scaleX = bboxAfter[2] / beforeWidth;
      const scaleY = bboxAfter[3] / beforeHeight;

      if (ctrlKey) {
        action.execute({ node: this }, new Matrix({ scale, ox, oy }));
        // this.transform.scaleO(scaleX, scaleY, ox, oy)
      } else {
        action.execute({ node: this }, new Matrix({ scaleX, scaleY, ox, oy }));
        // this.transform.scaleO(scale, ox, oy)
      }
    };

    const pointerup = (_ev: PointerEvent) => {
      // pointermove(ev)
      // Trigger the whiteboard indicator to start
      window.reportWhiteboardAction('drawing-stop');

      action.stop();

      document.removeEventListener('pointermove', pointermove);
      document.removeEventListener('pointerup', pointerup);
      document.removeEventListener('keydown', keydown);
      document.removeEventListener('keyup', keyup);
    };

    document.addEventListener('pointermove', pointermove);
    document.addEventListener('pointerup', pointerup);
    document.addEventListener('keydown', keydown);
    document.addEventListener('keyup', keyup);
  }

  onRotate(ev1: PointerEvent) {
    window.reportWhiteboardAction('drawing-start');

    ev1.stopPropagation();

    const slide = this.parent as Slide;
    const matrix = slide.screenCtm;

    const action = new NodeTransformAction();
    action.start();
    action.execute({ node: this }, new Matrix());

    const points = this.generateCornerPoints();
    const [x0, y0, width, height] = pureBBox(points);

    const ox = x0 + width / 2;
    const oy = y0 + height / 2;
    const { x: cx, y: cy } = new Point(ox, oy).transform(matrix);

    let startPoint = getPointFromEvent(ev1);

    const pointermove = (ev2: PointerEvent) => {
      const endPoint = getPointFromEvent(ev2);

      const dx1 = startPoint.x - cx;
      const dy1 = startPoint.y - cy;
      const dx2 = endPoint.x - cx;
      const dy2 = endPoint.y - cy;

      const sAngle = Math.atan2(dy1, dx1);
      const pAngle = Math.atan2(dy2, dx2);

      const angle = pAngle - sAngle;

      // snap to 45 degrees when ctrl is pressed
      const angleToRotate = ev2.ctrlKey ? Math.round(angle / (Math.PI / 4)) * (Math.PI / 8) : angle;

      if (angleToRotate === 0) return;

      action.execute({ node: this }, new Matrix({ rotate: (angleToRotate * 180) / Math.PI, ox, oy }));
      startPoint = endPoint;
    };

    const pointerup = (_ev: PointerEvent) => {
      // Trigger the whiteboard indicator to start
      window.reportWhiteboardAction('drawing-stop');

      action.stop();

      document.removeEventListener('pointermove', pointermove);
      document.removeEventListener('pointerup', pointerup);
    };

    document.addEventListener('pointermove', pointermove);
    document.addEventListener('pointerup', pointerup);
  }

  resetRotation() {
    const points = this.generateCornerPoints();
    const [x0, y0, width, height] = pureBBox(points);

    const ox = x0 + width / 2;
    const oy = y0 + height / 2;
    const { rotate } = this.transform.decompose(ox, oy);

    new NodeTransformAction().execute({ node: this }, new Matrix({ rotate: -rotate, ox, oy }));
    return this;
  }

  delete(cascadeDeletion?: boolean, beforeDelete?: ((model: BaseModel) => void) | undefined): this {
    this.remove();
    return super.delete(cascadeDeletion, beforeDelete);
  }

  remove() {
    this.parent?.removeNode(this);
    return this;
  }

  index() {
    return this.parent?.indexOfNode(this) ?? -1;
  }

  prev() {
    return this.parent?.nodes[this.index() - 1];
  }

  next() {
    return this.parent?.nodes[this.index() + 1];
  }
}

Node.boot();
