import { Dehydrate, Field, Model as BaseModel } from 'vue-model';
import { Node, Matrix, Model, Board } from '.';
import { v4 as uuid } from 'uuid';
import { Shape } from './shapes';
import { adaptNodeStructureForHydration } from '../utils/utils';
import { history } from '../History';

export type Box = { x: number; y: number; width: number; height: number };

function ctm(vb: Box, bbox: Box) {
  const vbWidth = vb.width;
  const vbHeight = vb.height;

  const ratioSize = bbox.width / bbox.height;
  const ratioSheet = vbWidth / vbHeight;

  let scale;
  let x = 0;
  let y = 0;
  if (ratioSize > ratioSheet) {
    const scaleY = bbox.height / vbHeight;
    const width = bbox.width / scaleY;
    x = (width - vbWidth) / 2;
    scale = scaleY;
  } else {
    const scaleX = bbox.width / vbWidth;
    const height = bbox.height / scaleX;
    y = (height - vbHeight) / 2;
    scale = scaleX;
  }

  return new Matrix({ translate: [(x - vb.x) * scale, (y - vb.y) * scale], scale: [scale, scale] });
}

export class Slide extends Model {
  static model = 'Slide' as const;
  board!: Board;
  boardId!: string | null;
  nodes!: Node[];
  unlockedNodes!: Node[];
  nodesCache!: Node[];
  selectedNodes!: Node[];
  selectedNodeIds!: string[];
  ctm!: Matrix;
  screenCtm!: Matrix;
  viewbox!: Box;
  shapeInProgress!: Shape | null;
  shapeInProgressId!: string;
  name!: string;
  firstNode!: Node | null;
  lastNode!: Node | null;
  order!: string[];
  selected!: boolean;

  static fields(): Record<string, Field> {
    return {
      _id: this.string(uuid),
      board: this.belongsTo(Board),
      boardId: this.string(null),
      ctm: this.computed((slide) => {
        return ctm(slide.viewbox, slide.board.position);
      }),
      viewbox: this.field<Box>({ x: 0, y: 0, width: 1000, height: 735 }, Object as any),
      selectedNodes: this.computed(
        (slide: Slide) => {
          return (slide.unlockedNodes as Shape[])
            .filter((node) => {
              return node.selected && !node.isTool;
            })
            .sort((a, b) => slide.indexOfNode(a) - slide.indexOfNode(b));
        },
        (slide: Slide, selectedNodes: Node[]) => {
          (slide.nodes as Node[]).forEach((node) => {
            node.selected = selectedNodes.includes(node);
          });
        },
      ),
      selectedNodeIds: this.computed((slide: Slide) => (slide.selectedNodes as Node[]).map((node) => node._id)),
      screenCtm: this.computed((slide) => {
        // add as dependency manually since the multiply call isnt reactive
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { a, e, f } = slide.board.transform;
        return slide.board.transform
          .multiply(slide.ctm)
          .translate(slide.board.position.x, slide.board.position.y) as Matrix;
      }),
      name: this.string('Board 1'),
      nodesCache: this.hasMany(Node, 'parentId'),
      order: this.array<string>([], Object as any),
      firstNode: this.computed((slide) => {
        return slide.nodes[0];
      }),
      nodes: this.computed(
        (slide) => {
          return Node.get(slide.order);
        },
        (slide, val: Record<string, unknown>[]) => {
          slide.removeAll();

          if (!val) return;

          Node.getOrCreate(val).forEach((node) => {
            slide.addNode(node);
          });
        },
      ),
      unlockedNodes: this.computed((slide) => {
        let nodes = slide.nodes;
        if (!history().user.moderator) {
          nodes = nodes.filter((node) => !node.hidden);
        }

        if (slide.board.locksDisabled) return nodes;
        return nodes.filter((node) => !node.locked);
      }),
      lastNode: this.computed((slide) => {
        return slide.nodes[slide.nodes.length - 1];
      }),
      shapeInProgressId: this.string(null),
      shapeInProgress: this.belongsTo(Shape, 'shapeInProgressId'),
      selected: this.boolean(true),
    };
  }

  static cascades() {
    return ['nodesCache'];
  }

  static hidden() {
    return ['shapeInProgressId', 'selected'];
  }

  dehydrateAsCopy<T extends typeof Model>(this: InstanceType<T>, relations = this.static().cascades()) {
    const obj = super.dehydrateAsCopy(relations);
    const o = obj as Dehydrate<typeof Slide>;
    const order = o.order as string[];
    const newOrder = (this as Slide).nodesCache.slice().sort((a, b) => order.indexOf(a._id) - order.indexOf(b._id));

    o.nodes = newOrder.map((node) => (o.nodesCache as Node[])[(this as Slide).nodesCache.indexOf(node)]);

    delete o.nodesCache;
    delete o.order;
    return obj;
  }

  static hydrate<T extends typeof BaseModel>(this: T, values: Dehydrate<T>): InstanceType<T> {
    if (Array.isArray(values)) {
      values.forEach((v) => adaptNodeStructureForHydration(v));
    } else {
      adaptNodeStructureForHydration(values as Dehydrate<any>);
    }
    return super.hydrate(values) as InstanceType<T>;
  }

  removeNode(node: Node) {
    const index = this.order.indexOf(node._id);
    if (index > -1) {
      this.order.splice(index, 1);
    }
    node.parentId = null;
  }

  addNode(node: Node, index?: number) {
    if (node.parent !== this) node.remove();

    if (index == null) {
      this.order.push(node._id);
    } else {
      this.order.splice(index, 0, node._id);
    }

    node.parentId = this._id;
  }

  removeAll() {
    this.nodes.forEach((node) => this.removeNode(node));
  }

  indexOfNode(node: Node) {
    return this.order.indexOf(node._id);
  }

  getChildren(): Node[] {
    return this.nodes;
  }
}

Slide.boot();
