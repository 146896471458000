import { Slide } from '../models';
import { Action } from '.';
import { NodesDeleteAction } from './NodesDeleteAction';

export type SlideDeleteActionObjects = { slide: Slide };
export class SlideDeleteAction extends Action<SlideDeleteActionObjects> {
  static actionName = 'SlideDeleteAction';

  async redo({ slide }: SlideDeleteActionObjects) {
    if (!slide.board.slides.length) {
      return;
    }

    slide.board.removeSlide(slide);

    await new NodesDeleteAction().execute({ nodes: slide.nodes });

    slide.delete(true, (m) => this.delete(m));
  }
}
