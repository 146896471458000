<template>
  <Toolbar
    position="floating"
    noHandle
    contentClass="flex gap-2 items-center"
    class="bottom-1 left-1/2 -translate-x-1/2"
    :class="{ '!opacity-100': colorMenuIsOpen }"
  >
    <ColorSelection :boardId="boardId" prop="strokeColor" />

    <div class="relative">
      <IconButton :icon="lineStyleIcons[strokeStyle]" @click="lineStyleMenuOpen = !lineStyleMenuOpen" />
      <Transition name="fade">
        <ul
          v-show="lineStyleMenuOpen"
          @click="lineStyleMenuOpen = !lineStyleMenuOpen"
          class="absolute flex flex-col bottom-0 -left-1 gap-1 p-1 border-black -mb-1"
        >
          <li>
            <IconButton :icon="lineStyleIcons['dotted']" @click="strokeStyle = 'dotted'" />
          </li>
          <li>
            <IconButton :icon="lineStyleIcons['dashed']" @click="strokeStyle = 'dashed'" />
          </li>
          <li>
            <IconButton :icon="lineStyleIcons['solid']" @click="strokeStyle = 'solid'" />
          </li>
        </ul>
      </Transition>
    </div>

    <SliderInput
      v-model="strokeWidth"
      :icon="RadixIconsBorderWidth"
      :min="0"
      :max="20"
      :step="1"
      class="w-40"
      @change="finishShapePropUpdate"
      title="Click/Drag to Change Stroke Width"
    />
    <SliderInput
      v-model="strokeOpacity"
      :icon="CilOpacity"
      :min="0"
      :max="1"
      :step="0.01"
      class="w-40"
      @change="finishShapePropUpdate"
      title="Click/Drag to Change Stroke Opacity"
    />
  </Toolbar>
</template>

<script lang="ts" setup>
import { createComputed, createSetter, finishShapePropUpdate } from '@/utils/shapeProps';
import { ref, watch } from 'vue';
import CilOpacity from '~icons/cil/opacity';
import LineDashed from '~icons/radix-icons/border-dashed';
import LineDotted from '~icons/radix-icons/border-dotted';
import LineSolid from '~icons/radix-icons/border-solid';
import RadixIconsBorderWidth from '~icons/radix-icons/border-width';
import { Board } from '../models';
import ColorSelection from './ColorSelection.vue';
import IconButton from './IconButton.vue';
import SliderInput from './SliderInput.vue';
import Toolbar from './toolbar/Toolbar.vue';

const props = defineProps<{ boardId: string }>();

const colorMenuIsOpen = ref(false);
const lineStyleMenuOpen = ref(false);

const lineStyleIcons = {
  dotted: LineDotted,
  dashed: LineDashed,
  solid: LineSolid,
} as const;

const board = Board.get(props.boardId) as Board;

const strokeOpacity = createComputed(board, 'fillOpacity');
const strokeWidth = createComputed(board, 'strokeWidth');
const setStrokeStyle = createSetter(board, 'strokeStyle');
const strokeStyle = createComputed(board, 'strokeStyle', (val) => setStrokeStyle(val, true));
</script>
