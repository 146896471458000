<template>
  <div class="flex min-w-0 gap-2 items-center" :class="{ 'flex-col-reverse': orientation === 'vertical' }">
    <ButtonSimple @click="resetZoom" class="btn"><LucideRefreshCcw /></ButtonSimple>
    <ButtonSimple @mousedown="startDec" @mouseup="stop" :disabled="internalValue <= min" class="btn">-</ButtonSimple>
    <input type="range" v-model="internalValue" :min="min" :max="max" :step="step" class="grow" :orient="orientation" />
    <ButtonSimple @mousedown="startInc" @mouseup="stop" :disabled="internalValue >= max" class="btn">+</ButtonSimple>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed, ref, watch } from 'vue';
import ButtonSimple from './ButtonSimple.vue';
import LucideRefreshCcw from '~icons/lucide/refresh-ccw';

const props = defineProps({
  min: { type: Number, required: true, default: 0 },
  max: { type: Number, required: true, default: 100 },
  step: { type: Number, required: true, default: 1 },
  logarithmic: { type: Boolean, required: false, default: false },
  orientation: { type: String as PropType<'vertical' | 'horizontal'>, default: 'horizontal' },
});

const value = defineModel({ type: Number, required: true });

const min = computed(() => (props.logarithmic ? 0 : props.min));
const max = computed(() => (props.logarithmic ? 100 : props.max));
const scale = computed(() => (Math.log(props.max) - Math.log(props.min)) / 100);

const internalValue = computed({
  get: () => (props.logarithmic ? (Math.log(value.value) - Math.log(props.min)) / scale.value : value.value),
  set: (v) => (value.value = props.logarithmic ? Math.exp(Math.log(props.min) + v * scale.value) : v),
});

let timeout: ReturnType<typeof setTimeout>;

const startInc = () => {
  inc(true);
  timeout = setTimeout(inc, 500);
};

const inc = (once = false) => {
  const newValue = Math.min(max.value, internalValue.value + props.step);

  if (newValue === internalValue.value) return;

  internalValue.value = newValue;

  if (!once) {
    timeout = setTimeout(inc, 100);
  }
};

const startDec = () => {
  dec(true);
  timeout = setTimeout(dec, 500);
};

const dec = (once = false) => {
  const newValue = Math.max(min.value, internalValue.value - props.step);

  if (newValue === internalValue.value) return;

  internalValue.value = newValue;

  if (!once) {
    timeout = setTimeout(dec, 100);
  }
};

const resetZoom = () => {
  internalValue.value = 50;
};

const stop = () => {
  clearTimeout(timeout);
};
</script>

<style scoped>
.btn {
  @apply shrink-0 rounded-full;
  @apply flex justify-center items-center w-6 h-6;
}

input[type='range'][orient='vertical'] {
  writing-mode: vertical-lr;
  rotate: 180deg;
}
</style>
