<template>
  <Dialog v-slot="{ ok }" v-model="show" @ok="onOk" @cancel="onCancel" nofocus>
    <p>{{ msg }}</p>
    <input ref="input" v-model="text" type="text" @keydown.enter="ok" />
  </Dialog>
</template>

<script lang="ts">
import Dialog from './Dialog.vue';
import { showPromptDialog, dialogMessage, dialogOk } from '../utils/utils';
import { ref, watch } from 'vue';

export default {
  name: 'PromptDialog',
  components: { Dialog },
  setup() {
    const show = showPromptDialog;
    const msg = dialogMessage;
    const text = ref('');
    const input = ref<HTMLInputElement | null>(null);

    const onOk = () => {
      dialogOk(text.value);
      text.value = '';
    };

    const onCancel = () => {
      text.value = '';
    };

    watch(
      show,
      (val) => {
        if (val) {
          input.value?.focus();
        }
      },
      { flush: 'post' },
    );

    return {
      onOk,
      onCancel,
      show,
      msg,
      text,
      input,
    };
  },
};
</script>
