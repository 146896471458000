<template>
  <image
    :id="htmlId(shape._id)"
    v-bind="$attrs"
    class="shape"
    :href="shape.src"
    :x="shape.points[0].x"
    :y="shape.points[0].y"
    :width="width"
    :height="height"
    preserve-aspect-ratio="none"
  />
</template>

<script>
import { htmlId } from '../utils/utils';
import { Shape } from '../models';

export default {
  name: 'Shape',
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const shape = Shape.get(props.id);

    const { x: width, y: height } = shape.points[1].sub(shape.points[0]);

    return {
      shape,
      htmlId,
      width,
      height,
    };
  },
};
</script>

<style scoped>
.tool {
  animation: 1s infinite linear stroke;
}

@keyframes stroke {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 10;
  }
}
</style>
