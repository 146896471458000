<template>
  <Toolbar
    v-model:active="board.toolbar"
    v-model:x="x"
    v-model:y="y"
    v-model:position="position"
    contentClass="grid grid-cols-2 gap-2"
    @pointerdown="finishDrawing"
    v-bind="$attrs"
  >
    <ToolbarItem icon="bx-undo" :class="toolbarClass('undo')" @click.prevent="onToolbarAction('undo')" label="Undo" />
    <ToolbarItem icon="bx-redo" :class="toolbarClass('redo')" @click.prevent="onToolbarAction('redo')" label="Redo" />

    <div class="empty-tool" />
    <div class="empty-tool" />

    <ToolbarItem icon="bxs-pointer" value="select" label="Select" />
    <ToolbarItem icon="bxs-hand-up" value="pointer" label="Pointer" />

    <ToolbarItem icon="bxs-pencil" value="freeLine" label="Pen" />
    <ToolbarItem icon="bxs-square" label="Shapes" content-class="flex flex-col gap-2" value="filledRect">
      <ToolbarItem icon="bxs-square" value="filledRect" label="Filled Rectangle" />
      <ToolbarItem icon="bxs-circle" value="filledCircle" label="Filled Circle" />
      <ToolbarItem icon="bxs-polygon" value="filledPolygon" label="Filled Polygon" />
      <ToolbarItem icon="bx-square" value="rect" label="Hollow Rectangle" />
      <ToolbarItem icon="bx-circle" value="circle" label="Hollow Circle" />
      <ToolbarItem icon="bx-polygon" value="polygon" label="Hollow Polygon" />
    </ToolbarItem>

    <ToolbarItem icon="bx-text" value="text" label="Text" />
    <ToolbarItem icon="bx-minus" value="lineSegment" label="Straight Line" content-class="flex flex-col gap-2">
      <ToolbarItem icon="bx-minus" value="lineSegment" label="Straight Line" />
      <ToolbarItem :icon="PajamasLongArrow" value="arrowedLineSegment" label="Straight Line Arrow" />
      <ToolbarItem
        :icon="MaterialSymbolsLightArrowRange"
        value="doubleArrowedLineSegment"
        label="Straight Line Arrow"
      />
      <ToolbarItem :icon="markRaw(GisPolyline)" value="line" label="Multiline" />
      <ToolbarItem :icon="markRaw(UilArrowGrowth)" value="arrowedLine" label="Multiline Arrow" />
    </ToolbarItem>

    <ToolbarItem icon="bxs-image">
      <template #item="{ icon }">
        <label>
          <Icon :icon="icon" />
          <input type="file" hidden multiple="true" @change="onFile" />
        </label>
      </template>
    </ToolbarItem>

    <ToolbarItem
      v-if="isMod"
      :value="hidden ? 'Show' : 'Hide'"
      :disabled="!shapeSelected"
      :label="hidden ? 'Show' : 'Hide'"
      @click.prevent="handleShapeTools(hidden ? 'Show' : 'Hide')"
      :icon="hidden ? EyeIcon : EyeSlashIcon"
    />

    <ToolbarItem
      value="group"
      :disabled="!group"
      label="Group"
      @click.prevent="handleShapeTools('Group')"
      :icon="GroupIcon"
    />
    <ToolbarItem
      :disabled="!ungroup"
      value="ungroup"
      label="Ungroup"
      @click.prevent="handleShapeTools('Ungroup')"
      :icon="UngroupIcon"
    />
    <ToolbarItem
      icon="bxs-chevron-up"
      value="Forward"
      :disabled="!shapeSelected"
      label="Move forward"
      @click.prevent="handleShapeTools('Forward')"
    />
    <ToolbarItem
      icon="bxs-chevron-down"
      value="Backward"
      :disabled="!shapeSelected"
      label="Move backward"
      @click.prevent="handleShapeTools('Backward')"
    />

    <ToolbarItem
      icon="bxs-copy"
      value="Copy"
      :disabled="!shapeSelected"
      label="Copy"
      @click.prevent="handleShapeTools('Copy')"
    />
    <ToolbarItem icon="bxs-paste" value="Paste" label="Paste" @click.prevent="handleShapeTools('Paste')" />

    <ToolbarItem
      :icon="lock ? 'bxs-lock-alt' : 'bxs-lock-open-alt'"
      :disabled="!shapeSelected"
      :label="lock ? 'Lock' : 'Unlock'"
      @click="handleShapeTools(lock ? 'Lock' : 'Unlock')"
    />
    <ToolbarItem
      icon="bxs-lock"
      label="Disable Lock"
      @click="board.locksDisabled = !board.locksDisabled"
      :class="{ '!bg-yellow-400': board.locksDisabled }"
    />

    <ToolbarItem :icon="MdiGraphLine" label="Create Graph" @click="showGraphModal = true" />

    <ToolbarItem
      icon="bxs-trash-alt"
      value="Delete"
      :disabled="!shapeSelected"
      label="Delete"
      @click.prevent="handleShapeTools('Delete')"
    />

    <ToolbarItem
      icon="bxs-download"
      value="Save"
      label="Save"
      @click.prevent="openSaveModal"
      :class="isDev || isMod ? 'col-span-2' : ''"
    />
  </Toolbar>

  <Dialog v-model="showSlideListModal" @ok="saveBoard()" title="Save Boards">
    <div class="flex flex-col gap-2">
      <label>
        Filetype:
        <select
          class="px-2 rounded-sm border border-gray-300 focus:ring-1 focus:ring-blue-500"
          v-model="fileType"
          @change="($event.target as HTMLSelectElement).value === 'png' ? (allSelected = false) : undefined"
        >
          <option value="json">Json</option>
          <option value="pdf">PDF</option>
          <option value="png">Image (PNG)</option>
        </select>
      </label>

      <p v-if="fileType === 'json'" style="height: 50px; display: flex; align-items: center">
        Use this if you want to save the board(s) to view/edit later. This can be viewed/edited in session or in the
        Work Room.
      </p>
      <p v-else-if="fileType === 'pdf'" style="height: 50px; display: flex; align-items: center">
        Use this if you want to save the board(s) as pdf that you can view later. PDFs cannot be edited.
      </p>
      <p v-else style="height: 50px; display: flex; align-items: center">
        Use this if you want to save a board as an image. You can only export one board at a time.
      </p>

      <div class="divider" />

      <div class="board-select-message">Select the boards you want to save:</div>
      <label class="flex gap-2" :style="{ display: fileType === 'png' ? 'none' : undefined }">
        <input v-model="allSelected" type="checkbox" />
        <strong>All/None</strong>
      </label>
      <div :style="'column-count: ' + columnCount[0]">
        <label v-for="(slide, index) in board.slides" :key="index" class="flex gap-2">
          <input
            v-model="slide.selected"
            type="checkbox"
            @click="fileType === 'png' ? (allSelected = false) : undefined"
          />
          <span :class="board.currentSlide!._id === slide._id ? 'text-red-500 font-bold' : ''">
            {{ slide.name }}
          </span>
        </label>
      </div>
      <div style="text-align: center; padding: 10px">
        <strong>
          Note:
          <span class="text-red-500">Red</span>
          is the current board.
        </strong>
      </div>
    </div>
  </Dialog>

  <Dialog v-model="showGraphModal" @ok="addGraphToSlide()" title="Create Graph" class="w-4/5 h-4/5">
    <DesmosGraph ref="graph" />
  </Dialog>
</template>

<script lang="ts" setup>
import { Ref, capitalize, computed, markRaw, ref, watch } from 'vue';
import { Dehydrate } from 'vue-model';
import EyeIcon from '~icons/fa-solid/eye';
import EyeSlashIcon from '~icons/fa-solid/eye-slash';
import GroupIcon from '~icons/fa-solid/object-group';
import UngroupIcon from '~icons/fa-solid/object-ungroup';
import { history } from '../History';
import {
  NodeUngroupAction,
  NodesDeleteAction,
  NodesGroupAction,
  NodesHideAction,
  NodesLockAction,
  NodesShowAction,
  NodesToBackAction,
  NodesToFrontAction,
  NodesUnlockAction,
  ShapeSaveAction,
} from '../actions';
import { useFiles } from '../compositions/useFiles';
import { Board, Group, Image, Node, Slide } from '../models';
import { saveAs } from '../utils/export';
import { copyNodes, paste } from '../utils/utils';
import Icon from './Icon.vue';
import Toolbar from './toolbar/Toolbar.vue';
import ToolbarItem from './toolbar/ToolbarItem.vue';
import Dialog from './Dialog.vue';
import GisPolyline from '~icons/gis/polyline';
import MdiGraphLine from '~icons/mdi/graph-line';
import DesmosGraph from './DesmosGraph.vue';
import PajamasLongArrow from '~icons/pajamas/long-arrow';
import UilArrowGrowth from '~icons/uil/arrow-growth';
import MaterialSymbolsLightArrowRange from '~icons/material-symbols-light/arrow-range';

const DELETE = 'Delete';
const LOCK = 'Lock';
const UNLOCK = 'Unlock';
const GROUP = 'Group';
const UNGROUP = 'Ungroup';
const BACKWARD = 'Backward';
const FORWARD = 'Forward';
const COPY = 'Copy';
const PASTE = 'Paste';
const HIDE = 'Hide';
const SHOW = 'Show';
const isDev = import.meta.env.DEV;

const props = defineProps<{ boardId: string; setBoardPosition: () => void }>();

const board = Board.get(props.boardId) as Board;

const x = ref(0);
const y = ref(0);
const position = ref('left');
const showGraphModal = ref(false);
const graph = ref<InstanceType<typeof DesmosGraph>>();

watch(position, props.setBoardPosition, { flush: 'post' });

const { handleImages } = useFiles();

const onToolbarAction = (name: string) => {
  if (!board) return;

  const type = capitalize(name);

  board.currentSlide?.shapeInProgress?.finishShape();

  switch (type) {
    case 'Undo':
      return history().undo();
    case 'Redo':
      return history().redo();
  }
};

const toolbarClass = (type: string) => {
  return board.toolbar === type;
};

const onFile = (ev: Event) => {
  const files = (ev.target as HTMLInputElement).files as FileList;

  if (!files.length) {
    return;
  }

  handleImages(
    Array.from(files),
    new DragEvent('drop', {
      clientX: window.innerWidth / 2,
      clientY: window.innerHeight / 2,
    }),
    board.currentSlide as Slide,
  );
};

const showSlideListModal = ref(false);

const openSaveModal = () => {
  board.slides.forEach((slide) => {
    slide.selected = true;
  });
  showSlideListModal.value = true;

  fileType.value = 'json';
};

const saveBoard = () => {
  const indexes = board.slides.map((slide, index) => (slide.selected ? index : -1)).filter((i) => i !== -1);
  saveAs(board, indexes, fileType.value);
};

const handleShapeTools = (item: string) => {
  const nodes = board.currentSlide?.selectedNodes;

  if (item === PASTE) {
    paste(board.copied, board);
  }

  if (!nodes?.length) return;

  switch (item) {
    case DELETE: {
      new NodesDeleteAction().execute({ nodes: nodes });
      break;
    }
    case LOCK: {
      new NodesLockAction().execute({ nodes: nodes });
      if (board.locksDisabled) {
        nodes.forEach((node) => {
          node.selected = true;
        });
      }
      break;
    }
    case UNLOCK: {
      new NodesUnlockAction().execute({ nodes: nodes });
      break;
    }
    case HIDE: {
      new NodesHideAction().execute({ nodes: nodes });
      break;
    }
    case SHOW: {
      new NodesShowAction().execute({ nodes: nodes });
      break;
    }
    case GROUP: {
      const group = Group.create({
        parentId: nodes[0]!.parentId,
        transform: {},
        boardId: nodes[0]!.boardId,
      });
      new NodesGroupAction().execute({ nodes: nodes, group });
      // group.selected = true
      break;
    }
    case UNGROUP: {
      new NodeUngroupAction().execute({ group: nodes[0] as unknown as Group });
      break;
    }
    case BACKWARD: {
      new NodesToBackAction().execute({ nodes: nodes });
      break;
    }
    case FORWARD: {
      new NodesToFrontAction().execute({ nodes: nodes });
      break;
    }
    case COPY: {
      copyNodes(nodes, board);
      break;
    }
  }
};

const lock = computed(() => {
  const nodes = board.currentSlide?.selectedNodes;
  return nodes?.some((node) => !node.locked) ?? true;
});

const hidden = computed(() => {
  const nodes = board.currentSlide?.selectedNodes;
  return nodes?.some((node) => node.hidden) ?? true;
});

const ungroup = computed(() => {
  const nodes = board.currentSlide?.selectedNodes;
  return nodes?.length === 1 && nodes[0] instanceof Group;
});

const group = computed(() => {
  const nodes = board.currentSlide?.selectedNodes;
  return (nodes?.length ?? 0) > 1;
});

const shapeSelected = computed(() => {
  return !!board.currentSlide?.selectedNodes.length;
});

const finishDrawing = () => {
  board.currentSlide?.shapeInProgress?.finishShape();
};

const isMod = computed(() => board.localUser?.moderator);

const fileType: Ref<'json' | 'pdf' | 'png'> = ref('json');

const allSelected = computed({
  get() {
    return !board.slides.some((slide) => !slide.selected);
  },
  set(val: boolean) {
    board.slides.forEach((slide) => {
      slide.selected = val;
    });
  },
});

const columnCount = computed(() => {
  if (board.slides.length <= 8) {
    return [1, 400];
  } else if (board.slides.length > 8 && board.slides.length <= 16) {
    return [2, 500];
  } else if (board.slides.length > 16 && board.slides.length <= 24) {
    return [3, 500];
  } else if (board.slides.length > 24 && board.slides.length <= 32) {
    return [4, 650];
  } else {
    return [5, 700];
  }
});

const addGraphToSlide = async () => {
  const png = graph.value?.save();
  const slide = board.currentSlide;

  if (!png || !slide) return;

  const image = await Image.createWithOrWithoutPosition(png, slide);

  new ShapeSaveAction().execute({ node: image, slide });
};
</script>
