import { Node } from '../models';
import { Action } from './';

type NodesShowActionObjects = { nodes: Node[] };
export class NodesShowAction extends Action<NodesShowActionObjects> {
  static actionName = 'NodesShowAction';

  async redo({ nodes }: NodesShowActionObjects) {
    nodes.forEach((node) => {
      node.hidden = false;
    });
  }
}
