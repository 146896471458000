import { Baseline } from '..';

export class LineSegment extends Baseline {
  static model = 'LineSegment' as const;
  static base = Baseline;

  declare type: 'LineSegment';
  declare selectionComponent: 'LineSelection';

  static fields() {
    return {
      ...Baseline.fields(),
      type: this.string('LineSegment'),
      selectionComponent: this.string('LineSelection'),
      closed: this.boolean(false),
    };
  }

  onMouseUp(ev: MouseEvent) {
    super.onMouseUp(ev);

    this.finishShape();
  }
}

LineSegment.boot();
