<template>
  <text
    v-if="compat"
    v-bind="{ ...$attrs, style: null }"
    :style="{
      fill: shape.fillColor,
      opacity: shape.fillOpacity, // Let the opacity slider apply opacity to text boxes
      /*'font-family': `'Noto Color Emoji', ${shape.fontFamily}`,*/
      'font-family': shape.fontFamily,
      'font-size': shape.fontSize + 'px',
      'text-decoration': shape.underline ? 'underline' : null,
      'font-style': shape.italic ? 'italic' : null,
      'font-weight': shape.bold ? 'bold' : null,
      'text-align': shape.textAlign,
      ...($attrs.style as any),
    }"
  >
    <tspan
      v-for="(line, index) in lines"
      :key="line + index"
      :y="shape.points[0]?.y + shape.fontSize * 1.3 * (index + 1) - 0.3 * shape.fontSize ?? 0"
      :x="shape.points[0]?.x ?? 0"
      v-text="line"
    />
  </text>
  <foreignObject
    v-else
    v-bind="{ ...$attrs, style: null }"
    :x="shape.points[0]?.x ?? 0"
    :y="shape.points[0]?.y ?? 0"
    :height="shape.points.length > 1 ? shape.points[2].y - shape.points[0].y : 1"
    :width="shape.points.length > 1 ? shape.points[2].x - shape.points[0].x : 1"
    style="overflow: visible"
  >
    <pre
      :id="htmlId(shape._id)"
      class="text"
      :title="
        shape.url && !shape.locked
          ? hasWBControl()
            ? 'To follow the link use shift+click.'
            : 'Click to follow the link.'
          : undefined
      "
      :style="{
        background: shape.finished ? null : '#f5f5f5',
        'user-select': shape.finished ? null : 'auto',
        color: shape.fillColor,
        opacity: shape.fillOpacity, // Let the opacity slider apply opacity to text boxes
        /*'font-family': `'Noto Color Emoji', ${shape.fontFamily}`,*/
        'font-family': shape.fontFamily,
        'font-size': shape.fontSize + 'px',
        'text-decoration': shape.underline ? 'underline' : null,
        'font-style': shape.italic ? 'italic' : null,
        'font-weight': shape.bold ? 'bold' : null,
        'text-align': shape.textAlign,
        cursor: hasWBControl() ? 'default' : 'pointer',
        ...($attrs.style as any),
      }"
      @dblclick="shape.board.readonly ? null : shape.finishHook(true)"
      >{{ shape.text }}</pre
    >
    <Teleport v-if="!shape.finished" to="body">
      <div class="text-menu" :style="{ top: `${shape.menuPos.y}px`, left: `${shape.menuPos.x}px` }">
        <select v-model="shape.fontFamily">
          <option>Open Sans</option>
          <option>Arial</option>
          <option>Arial Black</option>
          <option>Verdana</option>
          <option>Tahoma</option>
          <option>Trebuchet MS</option>
          <option>Impact</option>
          <option>Times New Roman</option>
          <option>Didot</option>
          <option>Georgia</option>
          <option>American Typewriter</option>
          <option>Andalé Mono</option>
          <option>Courier</option>
          <option>Lucida Console</option>
          <option>Monaco</option>
          <option>Bradley Hand</option>
          <option>Brush Script MT</option>
          <option>Luminari</option>
          <option>Comic Sans MS</option>
        </select>
        <label :class="{ active: shape.bold }">
          <i class="bx bx-bold" />
          <input v-model="shape.bold" type="checkbox" hidden />
        </label>
        <label :class="{ active: shape.italic }">
          <i class="bx bx-italic" />
          <input v-model="shape.italic" type="checkbox" hidden />
        </label>
        <label :class="{ active: shape.underline }">
          <i class="bx bx-underline" />
          <input v-model="shape.underline" type="checkbox" hidden />
        </label>
        <input v-model="shape.fontSize" type="number" maxlength="2" class="text-input" />
        <label :class="{ active: shape.textAlign === 'left' ? true : false }">
          <i class="bx bx-align-left" />
          <input v-model="shape.textAlign" type="radio" value="left" name="text-align" hidden />
        </label>
        <label :class="{ active: shape.textAlign === 'center' ? true : false }">
          <i class="bx bx-align-middle" />
          <input v-model="shape.textAlign" type="radio" value="center" name="text-align" hidden />
        </label>
        <label :class="{ active: shape.textAlign === 'right' ? true : false }">
          <i class="bx bx-align-right" />
          <input v-model="shape.textAlign" type="radio" value="right" name="text-align" hidden />
        </label>
      </div>
    </Teleport>
  </foreignObject>
</template>

<style scoped>
.text {
  display: inline-block;
  margin: 0;
  padding: 2px;
  user-select: none;
  line-height: 135%;
}
.text-menu {
  position: absolute;
  transform: translateY(-110%);
  display: flex;
  align-items: stretch;
  height: 30px;
}

.text-menu > label {
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: auto;
  width: 30px;
  background: white;
}

.text-menu > label:hover {
  box-shadow: inset 0 0 3px #ccc;
}

.text-menu label.active {
  box-shadow: inset 0 0 3px black;
}

.text-menu > input {
  width: 30px;
}

.text-input {
  width: 40px !important;
  border: 1px solid black;
  padding-left: 2px;
}
</style>

<script lang="ts">
import { htmlId } from '../utils/utils';
import { Text } from '../models';
import { computed, defineComponent, inject } from 'vue';

export default defineComponent({
  name: 'Text',
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const shape = Text.get(props.id) as Text;

    const compat = inject('compat', false);

    const lines = computed(() => shape.text.split('\n'));

    const hasWBControl = () => {
      return shape.board.localParticipant.canDraw;
    };

    return {
      shape,
      htmlId,
      compat,
      lines,
      hasWBControl,
    };
  },
});
</script>
