<template>
  <Teleport to="body" :disabled="inFullscreen || position !== 'floating' || noTeleport">
    <div
      ref="toolbar"
      class="components_toolbar"
      :class="[{ collapsed: collapsedVal, flyoutLeft }, position]"
      :style="coords ? { top: coords.top, left: coords.left } : undefined"
      v-bind="$attrs"
    >
      <div class="handle" v-if="!noHandle">
        <button
          @click="positionVal = 'left'"
          :disabled="position === 'left'"
          class="disabled:text-gray-400 enabled:hover:bg-gray-200 py-1 rounded"
        >
          <IconLeftPosition />
        </button>
        <button
          @pointerdown="
            positionVal = 'floating';
            startToolbarDrag($event);
          "
          :class="dragging ? 'cursor-grabbing' : 'cursor-grab'"
        >
          <IconFloatingPosition />
        </button>
        <button
          @click="positionVal = 'right'"
          :disabled="position === 'right'"
          class="disabled:text-gray-400 enabled:hover:bg-gray-200 py-1 rounded"
        >
          <IconRightPosition />
        </button>
        <!-- <button @click="collapsedVal = !collapsedVal">
          <IconCollapse v-if="collapsedVal" />
          <IconExpand v-else />
        </button> -->
      </div>
      <div class="title" v-if="title || $slots.title">
        <slot name="title">{{ title }}</slot>
      </div>
      <div class="content" :class="contentClass">
        <slot :flyout-left="flyoutLeft" />
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue';
import { PointLike } from '@/models/Point';
import { computed, defineComponent, nextTick, onBeforeUnmount, provide, ref, watch } from 'vue';
import IconLeftPosition from '~icons/entypo/align-left';
import IconRightPosition from '~icons/entypo/align-right';
import IconFloatingPosition from '~icons/hugeicons/move';
import IconCollapse from '~icons/ph/text-t-bold';
import IconExpand from '~icons/ph/text-t-slash-bold';

type FloatingPoint = { left: string; top: string };

export default defineComponent({
  name: 'Toolbar',
  components: {
    Icon,
    IconLeftPosition,
    IconRightPosition,
    IconFloatingPosition,
    IconCollapse,
    IconExpand,
  },
  props: {
    title: { type: String },
    position: { type: String, default: 'left' },
    collapsed: { type: Boolean, default: undefined },
    x: { type: Number },
    y: { type: Number },
    active: {},
    contentClass: { type: String, default: '' },
    noHandle: { type: Boolean, default: false },
    noTeleport: { type: Boolean, default: false },
  },
  emits: ['update:active', 'update:position', 'update:x', 'update:y', 'update:collapsed'],
  setup(props, context) {
    const toolbar = ref<HTMLElement>();
    const icon = ref<HTMLElement>();
    const showMenu = ref(false);

    const positionVal = computed<string>({
      get() {
        return props.position;
      },
      set(val) {
        context.emit('update:position', val);
      },
    });

    const collapsedVal = computed<boolean>({
      get() {
        return props.collapsed ?? true;
      },
      set(val) {
        context.emit('update:collapsed', val);
      },
    });

    const coords = computed<FloatingPoint | null>(() => {
      return props.position === 'floating' && props.x !== undefined && props.y !== undefined
        ? {
            left: props.x + 'px',
            top: props.y + 'px',
          }
        : null;
    });

    const flyoutLeft = computed(() => {
      return (props.position === 'floating' && (props.x ?? 0) > window.innerWidth / 2) || props.position === 'right';
    });

    const setActive = (label: string, value: string) => {
      context.emit('update:active', value);
    };

    provide('collapsed', collapsedVal);
    provide(
      'active',
      computed(() => props.active),
    );
    provide('setActive', setActive);

    const getCoords = (
      ev: MouseEvent,
      offset: PointLike = { x: 0, y: 0 },
      { min, max }: { min: PointLike; max: PointLike },
    ) => {
      return {
        x: Math.max(min.x, Math.min(max.x, ev.clientX - offset.x)),
        y: Math.max(min.y, Math.min(max.y, ev.clientY - offset.y)),
      };
    };

    const setPosition = (p: PointLike) => {
      context.emit('update:x', p.x);
      context.emit('update:y', p.y);
    };

    const dragging = ref(false);
    watch(positionVal, (val) => {
      dragging.value = false;
    });

    const startToolbarDrag = async (ev: MouseEvent) => {
      dragging.value = true;
      const el = toolbar.value!;
      const offsetX = ev.clientX - el.offsetLeft;
      const offsetY = ev.clientY - el.offsetTop;
      const offset = { x: offsetX, y: offsetY };

      await nextTick();

      const posMinX = 0;
      const posMaxX = window.innerWidth - el.offsetWidth;
      const posMinY = 0;
      const posMaxY = window.innerHeight - el.offsetHeight;

      const min = { x: posMinX, y: posMinY };
      const max = { x: posMaxX, y: posMaxY };

      const startPos = getCoords(ev, offset, { min, max });

      setPosition(startPos);

      const onPointerMove = (ev: MouseEvent) => {
        setPosition(getCoords(ev, offset, { min, max }));
      };

      const onPointerUp = () => {
        dragging.value = false;
        document.removeEventListener('pointerup', onPointerUp);
        document.removeEventListener('pointermove', onPointerMove);
        document.removeEventListener('pointerleave', onPointerLeave);
      };

      const onPointerLeave = () => {
        setPosition(startPos);
      };

      document.addEventListener('pointerup', onPointerUp);
      document.addEventListener('pointermove', onPointerMove);
      document.addEventListener('pointerleave', onPointerLeave);
    };

    const onDocumentClick = (ev: MouseEvent) => {
      if (icon.value?.contains(ev.target as Node)) return;
      if (showMenu.value) showMenu.value = false;
    };

    document.addEventListener('click', onDocumentClick, { capture: true });
    document.addEventListener('contextmenu', onDocumentClick, { capture: true });

    onBeforeUnmount(() => {
      document.removeEventListener('click', onDocumentClick, { capture: true });
      document.removeEventListener('contextmenu', onDocumentClick, { capture: true });
    });

    const inFullscreen = ref(false);
    document.addEventListener('fullscreenchange', () => {
      inFullscreen.value = !!document.fullscreenElement;
    });

    return {
      toolbar,
      collapsedVal,
      startToolbarDrag,
      showMenu,
      icon,
      positionVal,
      coords,
      inFullscreen,
      flyoutLeft,
      dragging,
    };
  },
});
</script>

<style scoped>
.components_toolbar {
  /* background: #eeeeee; */
  font-family:
    Avenir Next LT Pro,
    sans-serif;
  font-weight: 400;
  flex-shrink: 0;
  z-index: 100;
  user-select: none;
  position: relative;
  background: white;
}

.components_toolbar.collapsed {
  width: auto;
}

.components_toolbar.floating {
  position: absolute;
  box-shadow:
    0px 8px 16px rgba(0, 0, 0, 0.1),
    0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border: 1px solid #9ea7a9;
}

.components_toolbar.left {
  order: -999;
  border-bottom-left-radius: 5px;
  padding-left: 3px;
}

.components_toolbar.right {
  order: 999;
  border-bottom-right-radius: 5px;
  padding-right: 3px;
}

.components_toolbar hr {
  border-color: #9ea7a9;
  width: 100%;
  margin: 2px 0;
}

.components_toolbar > .handle {
  display: flex;
  position: relative;
  height: 30px;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;
}

.components_toolbar > .handle > .title {
  flex-grow: 1;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  padding: 0 10px;
}

.components_toolbar.collapsed > .handle > .title {
  display: none;
}

.components_toolbar > .handle > .icon {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  display: flex;
  font-size: 1.9em;
  margin: 0 1px;
  cursor: pointer;
}

.components_toolbar > .handle > .menu {
  position: absolute;
  left: calc(100% + 20px);
  top: -6px;
  display: flex;
  flex-direction: column;
  background: #eeeeee;
  border: 1px solid rgba(158, 167, 169, 0.6);
  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.05),
    0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 10px;
  color: black;
  font-weight: bold;
}

.components_toolbar.flyoutLeft > .handle > .menu {
  left: unset;
  right: calc(100% + 20px);
}

.components_toolbar > .handle > .menu:before {
  content: '';
  border: 1px solid rgba(158, 167, 169, 0.6);
  border-right: none;
  border-top: none;
  position: absolute;
  top: 18px;
  left: -6px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background: #eeeeee;
}

.components_toolbar.flyoutLeft > .handle > .menu:before {
  left: unset;
  right: -6px;
  transform: rotate(225deg);
}

.components_toolbar > .content {
  padding: 5px;
  max-height: calc(100% - 20px);
}

.components_toolbar.right > .content {
  border-bottom-left-radius: 5px;
}

.components_toolbar.left > .content {
  border-bottom-right-radius: 5px;
}

.components_toolbar > .handle > .menu > .radio-switch {
  display: flex;
}

.components_toolbar > .handle > .menu > .radio-switch > label > div {
  display: flex;
  font-size: 16px;
  padding: 5px 10px;
  border-color: #9d9d9d;
  border-width: 1px 0;
  border-style: solid;
  cursor: pointer;
}

.components_toolbar > .handle > .menu > .radio-switch > label + label > div {
  border-left-width: 1px;
}

.components_toolbar > .handle > .menu > .radio-switch > label:first-child > div {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-left-width: 1px;
}

.components_toolbar > .handle > .menu > .radio-switch > label:last-child > div {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-right-width: 1px;
}

.components_toolbar > .handle > .menu > .radio-switch > label:hover > div {
  background: #6da1ad;
  color: white;
}

.components_toolbar > .handle > .menu > .radio-switch > label > input:checked + div {
  background: #406770;
  color: white;
}

.components_toolbar > .handle > .menu > .fake-checkbox-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}

.components_toolbar > .handle > .menu > .fake-checkbox-wrapper > .fake-checkbox {
  width: 30px;
  height: 30px;
  border: 1px solid #9d9d9d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.components_toolbar > .handle > .menu > .fake-checkbox-wrapper > input:checked + .fake-checkbox:after {
  content: '✓';
  font-size: 24px;
  color: black;
  font-weight: bold;
}
</style>
