<template>
  <Transition name="fade">
    <div ref="item" class="components_toolbar_item" :class="{ open, active, disabled }">
      <div
        class="item"
        :title="collapsed ? computedLabel : undefined"
        @click="computedClick"
        @contextmenu.prevent="hasSubMenu && (open = !open)"
      >
        <slot name="item" :icon="computedIcon" :label="computedLabel" :collapsed="collapsed">
          <div class="icon">
            <slot name="icon" :icon="computedIcon">
              <Icon v-if="computedIcon" :icon="computedIcon" :title="computedLabel" class="toolbarItem" />
            </slot>
          </div>
          <div v-if="!collapsed" class="item-label" v-bind="$attrs">
            <slot name="label" :label="computedLabel">
              {{ computedLabel }}
            </slot>
          </div>
        </slot>
        <span v-if="hasSubMenu" class="arrow items-center justify-center" @click.stop="wrappedClick">
          <IcRoundArrowRight />
        </span>
      </div>
      <Transition v-if="hasSubMenu" name="fade">
        <div class="children" :class="[contentClass, orientation]" v-show="open">
          <slot name="default" />
        </div>
      </Transition>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { inject, ref, computed, onBeforeUnmount, provide, Ref, useSlots, Component, PropType } from 'vue';
import Icon from '@/components/Icon.vue';
import IcRoundArrowRight from '~icons/ic/round-arrow-right';

type AnyFn = (...args: any) => void;

const props = defineProps({
  label: { type: String, default: '' },
  value: { type: String, default: '' },
  icon: { type: [String, Object] as PropType<string | Component> },
  disabled: { type: Boolean, default: false },
  onClick: { type: Function, default: () => undefined },
  contentClass: { type: String, default: '' },
  orientation: { type: String as PropType<'vertical' | 'horizontal'>, default: 'vertical' },
});

const slots = useSlots();
const collapsed = inject<Ref<boolean>>('collapsed');
const activeItem = inject<Ref<string>>('active');
const setActiveEntry = inject(
  'setActive',
  (_label: string, _value: string, _icon?: string | Component, _click?: AnyFn) => undefined,
);

// Is the submenu opened right now?
const open = ref(false);

// Dom node
const item = ref() as Ref<HTMLElement>;

// In case a submenu entry was selected, this are its props
const subLabel = ref<string>();
const subIcon = ref<string | Component>();
const subClick = ref<AnyFn>();
const subValue = ref<string>();

// Called when a submenu entry was clicked
const setActive = (label: string, value: string, icon: string | Component | undefined, click: AnyFn) => {
  subLabel.value = label;
  subIcon.value = icon;
  subClick.value = click;
  subValue.value = value;

  setActiveEntry(label, value, icon, click);
  open.value = !open.value;
};

// Wrap the click handler so that we can notify our main menu about the click
const wrappedClick = (ev: MouseEvent) => {
  props.onClick && props.onClick(ev);

  if (!ev.defaultPrevented && computedValue.value) {
    if (!hasSubMenu.value) {
      return setActiveEntry(computedLabel.value, computedValue.value, computedIcon.value, computedClick.value);
    }

    if (computedValue.value === props.value && props.value === '') {
      open.value = !open.value;
    } else {
      // open.value = false;
      setActive(computedLabel.value, computedValue.value, computedIcon.value, computedClick.value);
    }
  }
};

const hasSubMenu = computed(() => {
  return slots.default && !!slots.default().length;
});

const computedLabel = computed(() => {
  return subLabel.value || props.label;
});

const computedValue = computed(() => {
  return subValue.value || props.value;
});

const computedIcon = computed(() => {
  return subIcon.value || props.icon;
});

const computedClick = computed(() => {
  return /* subClick.value || */ wrappedClick;
});

const active = computed(() => {
  return computedValue.value === activeItem?.value;
});

const onDocumentClick = (ev: MouseEvent) => {
  if (item.value.contains(ev.target as Node)) return;
  if (open.value) open.value = false;
};

document.addEventListener('click', onDocumentClick, { capture: true });
document.addEventListener('contextmenu', onDocumentClick, { capture: true });

onBeforeUnmount(() => {
  document.removeEventListener('click', onDocumentClick, { capture: true });
  document.removeEventListener('contextmenu', onDocumentClick, { capture: true });
});

// Make sure that submenus are never collapsed
// provide('collapsed', ref(false));
provide('setActive', setActive);
</script>

<style scoped>
.components_toolbar_item {
  position: relative;
}
.components_toolbar_item.disabled {
  pointer-events: none;
}

.components_toolbar_item {
  color: #272a31;
  cursor: pointer;
  border-radius: 3px;
  background: #eee;
  border: 1px solid gray;
}

.components_toolbar_item > .item {
  display: flex;
  border-radius: 3px;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
}

.components_toolbar_item.active {
  background: #bbb;
}

.components_toolbar_item:not(.open, .active):hover {
  background: #ddd;
}

.components_toolbar_item.disabled {
  opacity: 0.5;
  background: none;
}

.components_toolbar_item > .item .icon {
  order: 1;
}

.components_toolbar_item > .item.right .icon {
  order: 3;
}

.components_toolbar_item > .item .item-label {
  padding-left: 5px;
  white-space: nowrap;
  flex-grow: 1;
  order: 2;
}

.components_toolbar_item > .item > .arrow {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 0 2px;
  position: absolute;
  right: 0px;
  order: 4;
}

.components_toolbar_item > .item > .arrow > svg {
  transform: rotate(45deg);
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: -8px;
  right: -8px;
}

/* .components_toolbar.right .components_toolbar_item > .item > .arrow > i {
  transform: rotate(135deg) scaleX(0.7);
} */

.components_toolbar_item > .item > .arrow:hover {
  background: rgba(0, 0, 0, 0.2);
}

.components_toolbar_item > .children {
  position: absolute;
  border: 1px solid rgba(158, 167, 169, 0.6);
  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.05),
    0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  order: 5;
  z-index: 1;
  background: white;
}

.components_toolbar_item > .children.vertical {
  left: calc(100% + 8px);
  top: -11px;
}

.components_toolbar_item > .children.horizontal {
  bottom: calc(100% + 8px);
  left: -11px;
}

.components_toolbar.flyoutLeft .components_toolbar_item > .children.vertical {
  left: unset;
  right: calc(100% + 20px);
}

.components_toolbar.flyoutLeft .components_toolbar_item > .children.horizontal {
  bottom: unset;
  top: calc(100% + 20px);
}

/*.components_toolbar_item:hover > .children,*/
.components_toolbar_item > .children {
  padding: 5px;
}

.components_toolbar_item > .children:before {
  content: '';
  border: 1px solid rgba(158, 167, 169, 0.6);
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
}

.components_toolbar_item > .children.vertical:before {
  border-right: none;
  border-top: none;
  top: 18px;
  left: -6px;
  transform: rotate(45deg);
}
.components_toolbar_item > .children.horizontal:before {
  border-left: none;
  border-bottom: none;
  bottom: -6px;
  left: 18px;
  transform: rotate(135deg);
}

.components_toolbar.flyoutLeft .components_toolbar_item > .children.vertical:before {
  left: unset;
  right: -6px;
  transform: rotate(235deg);
}
.components_toolbar.flyoutLeft .components_toolbar_item > .children.horizontal:before {
  top: unset;
  bottom: -6px;
  transform: rotate(315deg);
}

.components_toolbar_item > .children > .components_toolbar_item {
  color: black;
  /* min-width: 172px; */
}

.components_toolbar_item > .item > * {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 20px;
  cursor: pointer;
}

.components_toolbar_item > .item > * > *:not([hidden]) {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .toolbarItem label {
  display: flex;
} */
</style>
