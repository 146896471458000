import { BoxLike, MatrixLike } from '../utils/types';
import { Matrix } from './Matrix';
import { Point } from './Point';

export class Box {
  height!: number;
  width!: number;
  y!: number;
  x!: number;
  cx!: number;
  cy!: number;
  w!: number;
  h!: number;
  x2!: number;
  y2!: number;

  constructor();
  constructor(source: number[]);
  constructor(source: DOMRect);
  constructor(source: BoxLike);
  constructor(x: number, y: number, width: number, height: number);
  constructor(...args: any[]) {
    this.init(...args);
  }

  init(...args: any[]) {
    const base = [0, 0, 0, 0];
    let source = args[0];
    source = Array.isArray(source)
      ? source
      : typeof source === 'object'
        ? [
            source.left != null ? source.left : source.x,
            source.top != null ? source.top : source.y,
            source.width,
            source.height,
          ]
        : args.length === 4
          ? args
          : base;

    this.x = source[0] || 0;
    this.y = source[1] || 0;
    this.width = this.w = source[2] || 0;
    this.height = this.h = source[3] || 0;

    // Add more bounding box properties
    this.x2 = this.x + this.w;
    this.y2 = this.y + this.h;
    this.cx = this.x + this.w / 2;
    this.cy = this.y + this.h / 2;

    return this;
  }

  // Merge rect box with another, return a new instance
  merge(box: BoxLike) {
    const x = Math.min(this.x, box.x);
    const y = Math.min(this.y, box.y);
    const width = Math.max(this.x + this.width, box.x + box.width) - x;
    const height = Math.max(this.y + this.height, box.y + box.height) - y;

    return new Box(x, y, width, height);
  }

  toArray() {
    return [this.x, this.y, this.width, this.height];
  }

  transform(m: MatrixLike) {
    if (!(m instanceof Matrix)) {
      m = new Matrix(m);
    }

    let xMin = Infinity;
    let xMax = -Infinity;
    let yMin = Infinity;
    let yMax = -Infinity;

    const pts = [
      new Point(this.x, this.y),
      new Point(this.x2, this.y),
      new Point(this.x, this.y2),
      new Point(this.x2, this.y2),
    ];

    pts.forEach(function (p) {
      p = p.transform(m);
      xMin = Math.min(xMin, p.x);
      xMax = Math.max(xMax, p.x);
      yMin = Math.min(yMin, p.y);
      yMax = Math.max(yMax, p.y);
    });

    return new Box(xMin, yMin, xMax - xMin, yMax - yMin);
  }
}
