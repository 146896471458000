import axios from 'axios';
import { Board } from '../models';
import { EventEmitter } from './EventEmitter';
import {
  ErrorMessage,
  GetWithoutType,
  GetWithType,
  WebSocketAnswer,
  WebSocketMessage,
  WebSocketMessageType,
  WebSocketAnswerType,
} from './WebSocketMessage';
import { reactive, ref, watch } from 'vue';

const emitter = new EventEmitter();

export enum WebSocketStatus {
  CONNECTING = 1,
  OPEN = 2,
  READY = 3,
  CLOSING = 4,
  CLOSED = 5,
  WAITING = 6,
  OFFLINE = 7,
  ERROR = 8,
}

const wsStatusReadable = {
  [WebSocketStatus.CONNECTING]: 'connecting',
  [WebSocketStatus.OPEN]: 'open',
  [WebSocketStatus.READY]: 'ready',
  [WebSocketStatus.CLOSING]: 'closing',
  [WebSocketStatus.CLOSED]: 'closed',
  [WebSocketStatus.WAITING]: 'waiting',
  [WebSocketStatus.OFFLINE]: 'offline',
  [WebSocketStatus.ERROR]: 'error',
};

export function createWebSocket(
  conferenceName: string,
  onMessage: (...args: any[]) => any = () => {
    /**/
  },
) {
  let ws: WebSocket;

  let retries = 0;

  const wsStatus = ref(WebSocketStatus.CLOSED);

  watch(
    wsStatus,
    (status) => {
      console.log(`Websocket status changed to "${wsStatusReadable[status]}"`);
    },
    { flush: 'sync' },
  );

  const queue: string[] = [];

  const connect = (conferenceName: string, onMessage: (...args: any[]) => any) => {
    ws = new WebSocket(`${import.meta.env.DEV ? 'ws' : 'wss'}://${window.location.host}/ws/_ws/${conferenceName}`);
    wsStatus.value = WebSocketStatus.CONNECTING;

    ws.onopen = () => {
      wsStatus.value = WebSocketStatus.OPEN;
      tryFlush();

      pingPong();
    };

    ws.onerror = (ev) => {
      console.warn('The websockt errored. See error below:');
      console.error(ev);
      if (
        ws.readyState !== ws.OPEN &&
        (wsStatus.value === WebSocketStatus.OPEN || wsStatus.value === WebSocketStatus.CONNECTING)
      ) {
        wsStatus.value = WebSocketStatus.ERROR;
        connect(conferenceName, onMessage);
      }
    };

    ws.onclose = () => {
      clearTimeout(pingTimeout);

      if (wsStatus.value !== WebSocketStatus.OPEN) {
        wsStatus.value = WebSocketStatus.CLOSED;
        return;
      }

      wsStatus.value = WebSocketStatus.WAITING;
      console.warn('Websocket was closed unexpectedly. Try again in 5 sec');

      setTimeout(() => {
        if (++retries > 5) {
          console.error('Websocket was closed too many times.');
          wsStatus.value = WebSocketStatus.OFFLINE;
          return;
        }
        connect(conferenceName, onMessage);
      }, 5000);
    };

    ws.onmessage = ({ data }: { data: string }) => {
      retries = 0;
      pingRetries = 0;

      if (data.startsWith('[deleted]')) {
        console.log('[deleted] Board was deleted');
        clearTimeout(pingTimeout);
        wsStatus.value = WebSocketStatus.CLOSING;
        ws.close();
        ws.onmessage = () => {
          /* */
        };
        Board.first()!.delete();
        return;
      }

      if (data.startsWith('[pong]')) {
        console.log('RECEIVED', data, data.length, 'Bytes');
        clearTimeout(pingTimeout);
        pingPong();
        return;
      }

      const payload = JSON.parse(data) as (WebSocketMessage | WebSocketAnswer | ErrorMessage) & { time: number };

      if (payload.type === 'left') {
        Board.first()!.setPointer(payload.id, null, 0);
        return;
      }

      // Console log actions except pointer usage because it clutters the console like crazy!
      if (payload.type !== 'pointer') {
        console.log('RECEIVED', JSON.parse(data), data.length, 'Bytes');
      }

      if (payload.type === 'ready') {
        wsStatus.value = WebSocketStatus.READY;
        tryFlush();
        return;
      }

      if (requestPromises.has(payload.type)) {
        const promise = requestPromises.get(payload.type) as any;
        return promise[0](payload);
      }

      if (payload.type) {
        const isAnswer = (
          msg: WebSocketMessage | WebSocketAnswer | ErrorMessage,
        ): msg is WebSocketAnswer | ErrorMessage => {
          return (msg as any).success !== undefined;
        };

        if (isAnswer(payload)) {
          if (!payload.success) {
            console.warn('Server reported error which needs to be corrected!', payload);
          }
        }

        if (payload.time < lastTime) return;

        // if (payload.time) {

        //   received.push(payload as WebSocketMessage & {time: number})
        //   setTimeout(() => emitOldest(), 200)

        // }

        return emitter.emit(payload.type, payload);
      }

      onMessage(data);
    };
  };

  const lastTime = 0;
  // const received = [] as (WebSocketMessage & {time: number})[]

  // const emitOldest = () => {
  //   received.sort((a, b) => a.time - b.time)

  //   const now = Date.now()
  //   while (received[0] && received[0].time < now - 500) {
  //     const payload = received.shift() as (WebSocketMessage & {time: number})
  //     emitter.emit(payload.type, payload)
  //   }
  // }

  const send = (msg: string) => {
    if (wsStatus.value !== WebSocketStatus.READY) {
      queue.push(msg);
    } else {
      // Since msg is not always a json string, console.log-ing could lead to an error. The section below is commented as logging this data isn't too important for now.
      // if (JSON.parse(msg).type !== 'pointer') {
      //   console.log('SEND', msg, JSON.stringify(msg).length, 'Bytes')
      // }
      ws.send(msg);
    }
  };

  const sendJSON = (msg: Record<string, unknown>) => {
    return send(JSON.stringify(msg));
  };

  const flush = () => {
    let msg;

    while ((msg = queue.pop())) {
      send(msg);
    }
  };

  const tryFlush = () => {
    if (wsStatus.value === WebSocketStatus.READY) {
      flush();
    }
  };

  type RouteReturnType = void | string | Record<string, unknown> | Promise<void | string | Record<string, unknown>>;
  type RouteCallback<T> = (message: T) => RouteReturnType;

  const requestPromises = new Map<string, [(value: any) => any, (error: any) => any]>();

  function request<T extends WebSocketMessageType, K = GetWithType<WebSocketAnswer, T>>(
    type: T,
    msg: GetWithoutType<WebSocketMessage, T>,
  ) {
    const promise = new Promise<K>((resolve, reject) => {
      requestPromises.set(type, [resolve, reject]);
    });

    sendJSON({ type, ...msg } as WebSocketMessage);

    return promise;
  }

  let pingTimeout = 0;
  let pingRetries = 0;

  const pingPong = () => {
    const pingFailed = () => {
      if (pingRetries < 3) {
        ++pingRetries;
        return sendPing();
      }

      wsStatus.value = WebSocketStatus.OFFLINE;
      ws.close();
      // connect(conferenceName, onMessage);
    };

    const sendPing = () => {
      send('[ping]');

      pingTimeout = window.setTimeout(pingFailed, 5000);
    };

    pingTimeout = window.setTimeout(sendPing, 10000);
  };

  function on<T extends WebSocketMessageType | WebSocketAnswerType>(
    event: T,
    cb: RouteCallback<GetWithType<WebSocketMessage | WebSocketAnswer, T>>,
  ) {
    emitter.on(event, cb);
  }

  const ensureCookie = () => {
    axios
      .get('/ws/get-cookie')
      .then(() => {
        connect(conferenceName, onMessage);
      })
      .catch(() => {
        wsStatus.value = WebSocketStatus.OFFLINE;
      });
  };

  ensureCookie();

  return reactive({
    close: () => {
      wsStatus.value = WebSocketStatus.CLOSING;
      ws.close();
    },
    send,
    sendJSON,
    on,
    request,
    status: wsStatus,
    reconnect: (reset = false) => {
      if (reset) {
        retries = 0;
        ensureCookie();
      } else {
        connect(conferenceName, onMessage);
      }
    },
  });
}
