import { Node } from '../models';
import { Action, NodesToBackAction } from './';

type NodesToFrontActionObjects = { nodes: Node[] };
export class NodesToFrontAction extends Action<NodesToFrontActionObjects> {
  static actionName = 'NodesToFrontAction';

  async redo({ nodes }: NodesToFrontActionObjects) {
    nodes = nodes.filter((node) => !!node.next()).map((node) => node.next()) as Node[];
    new NodesToBackAction().execute({ nodes });
  }
}
