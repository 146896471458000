import { Node } from '../models';
import { Action } from './';

type NodesLockActionObjects = { nodes: Node[] };
export class NodesLockAction extends Action<NodesLockActionObjects> {
  static actionName = 'NodesLockAction';

  async redo({ nodes }: NodesLockActionObjects) {
    nodes.forEach((node) => {
      node.locked = true;
      node.selected = false;
    });
  }
}
