<template>
  <Toolbar
    position="floating"
    noHandle
    contentClass="flex gap-2 items-center"
    class="bottom-1 left-1/2 -translate-x-1/2"
    :class="{ '!opacity-100': colorMenuIsOpen }"
  >
    <ColorSelection :boardId="boardId" prop="fillColor" />

    <!-- <IconButton
      :icon="lineStyleIcons[strokeStyle]"
      @click="strokeMenuOpen = !strokeMenuOpen"
      :class="{ 'ring-gray-600 ring': strokeMenuOpen }"
    /> -->

    <div class="relative">
      <Transition name="fade">
        <ColorSelection
          :board-id="boardId"
          prop="strokeColor"
          class="bottom-full right-full absolute mb-1 mr-2"
          v-show="lineStyleMenuOpen"
        />
      </Transition>
      <IconButton :icon="lineStyleIcons[strokeStyle]" @click="lineStyleMenuOpen = !lineStyleMenuOpen" />
      <Transition name="fade">
        <ul
          v-show="lineStyleMenuOpen"
          @click="lineStyleMenuOpen = !lineStyleMenuOpen"
          class="absolute flex flex-col bottom-0 -left-1 gap-1 p-1 border-black -mb-1"
        >
          <li>
            <IconButton :icon="lineStyleIcons['dotted']" @click="strokeStyle = 'dotted'" />
          </li>
          <li>
            <IconButton :icon="lineStyleIcons['dashed']" @click="strokeStyle = 'dashed'" />
          </li>
          <li>
            <IconButton :icon="lineStyleIcons['solid']" @click="strokeStyle = 'solid'" />
          </li>
        </ul>
      </Transition>
    </div>

    <!-- <Transition name="fade">
      <ColorSelection :board-id="boardId" prop="strokeColor" class="bottom-full absolute mb-1" v-if="strokeMenuOpen" />
    </Transition> -->

    <SliderInput
      v-model="strokeWidth"
      :icon="StrokeWidth"
      :min="0"
      :max="20"
      :step="1"
      class="w-40"
      @change="finishShapePropUpdate"
      title="Click/Drag to Change Stroke Width"
    />

    <SliderInput
      v-model="opacity"
      :icon="CilOpacity"
      :min="0"
      :max="1"
      :step="0.01"
      class="w-40"
      @change="finishShapePropUpdate"
      title="Click/Drag to Change Opacity"
    />

    <!-- <button @click="strokeMenuOpen = !strokeMenuOpen">
      <component :is="strokeMenuOpen ? ShevronUp : ShevronDown" />
    </button>

    <ToolbarStroke :boardId="boardId" v-if="strokeMenuOpen" class="!bottom-full !mb-1" noTeleport /> -->
  </Toolbar>
</template>

<script lang="ts" setup>
import { createComputed, createSetter, finishShapePropUpdate } from '@/utils/shapeProps';
import { ref } from 'vue';
import CilOpacity from '~icons/cil/opacity';
import ShevronDown from '~icons/mdi/chevron-down';
import ShevronUp from '~icons/mdi/chevron-up';
import { Board } from '../models';
import ColorSelection from './ColorSelection.vue';
import SliderInput from './SliderInput.vue';
import Toolbar from './toolbar/Toolbar.vue';
import ToolbarStroke from './ToolbarStroke.vue';
import IconButton from './IconButton.vue';
import StrokeWidth from '~icons/radix-icons/border-width';
import LineDashed from '~icons/radix-icons/border-dashed';
import LineDotted from '~icons/radix-icons/border-dotted';
import LineSolid from '~icons/radix-icons/border-solid';

const props = defineProps<{ boardId: string }>();

const colorMenuIsOpen = ref(false);
const strokeMenuOpen = ref(false);
const lineStyleMenuOpen = ref(false);

const lineStyleIcons = {
  dotted: LineDotted,
  dashed: LineDashed,
  solid: LineSolid,
} as const;

const board = Board.get(props.boardId) as Board;

const opacity = createComputed(board, 'fillOpacity');
const strokeWidth = createComputed(board, 'strokeWidth');

const setStrokeStyle = createSetter(board, 'strokeStyle');
const strokeStyle = createComputed(board, 'strokeStyle', (val) => setStrokeStyle(val, true));
</script>
