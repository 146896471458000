import { Model } from '../models';
import { Action } from './';

export type DoWhatYouWantActionObjects = Record<string, Model | Model[]>;
// eslint-disable-next-line no-use-before-define
type cb<T extends DoWhatYouWantActionObjects> = (objects: T, ctx: DoWhatYouWantAction<T>) => void;
export type DoWhatYouWantActionArgs<T extends DoWhatYouWantActionObjects> = [cb: cb<T>];
export class DoWhatYouWantAction<T extends DoWhatYouWantActionObjects> extends Action<T, DoWhatYouWantActionArgs<T>> {
  static actionName = 'DoWhatYouWantAction';

  async redo(objects: T, cb: cb<T>) {
    return cb(objects, this);
  }
}

// window.DoWhatYouWantAction = DoWhatYouWantAction
