/* eslint-disable no-use-before-define */
import { Dehydrate, Model } from 'vue-model';
import { User } from '../models';

export const CREATE = 0;
export const UPDATE = 1;
export const DELETE = 2;

export type UpdateStep = [command: string, prop: string, value?: unknown, oldValue?: unknown];
export type idKeyRecord<T> = { [id: string]: T };
export type ChangeType = typeof CREATE | typeof UPDATE | typeof DELETE;
export type ChangeSet = Record<
  string,
  {
    [CREATE]?: idKeyRecord<Record<string, unknown>>;
    [UPDATE]?: idKeyRecord<UpdateStep[]>;
    [DELETE]?: idKeyRecord<Record<string, unknown>>;
  }
>;

export type UpdateChangeSet = Record<string, { [id: string]: UpdateStep[] }>;

export type ChangeSetNonOptional = Record<
  string,
  {
    [P in keyof ChangeSet[string]]-?: ChangeSet[string][P];
  }
>;

export type WebSocketMessage =
  | MessageAuth
  | MessageHello
  | MessageGetUser
  | MessageUndo
  | MessageRedo
  | MessageCopy
  | MessagePaste
  | MessageCommmand
  | MessagePointer
  | MessageAction
  | MessageLeft;

export type WebSocketAnswer = AnswerAuth | AnswerHello | AnswerHelloUpdate | AnswerGetUser | AnswerUpdate | AnswerAck;

// Types of all Messages
export type WebSocketMessageType = WebSocketMessage['type'];
export type WebSocketAnswerType = WebSocketAnswer['type'];
// Excludes the type field while preserving optional keys
export type ExcludeTypeField<A> = Pick<A, Exclude<keyof A, 'type'>>;
// Gets the correct message/answer for a type without the type
export type GetWithoutType<A, T> = Omit<Extract<A, { type: T }>, 'type'>;
// Gets the correct message/answer for a type with the type
export type GetWithType<A, T> = Extract<A, { type: T }>;

export type WithSequence<A> = A & { sequence: number };

export type MessageAction = {
  changeSet: ChangeSet;
  type: 'action';
  id: string;
  noHistory?: boolean;
  slideId: string;
  seq: number;
};

export type MessageAuth = {
  password: string;
  name: string;
  type: 'auth';
};

export type MessageHello = {
  userId?: string;
  boardId: string;
  password?: string;
  type: 'hello';
  lastActionId?: string;
  time?: number;
  objects?: { [key: string]: string[] };
};

export type MessageGetUser = {
  type: 'getUser';
};

export type MessageUndo = {
  type: 'undo';
  actionId: string;
  boardId: string;
  seq: number;
};

export type MessageRedo = {
  type: 'redo';
  actionId: string;
  boardId: string;
  seq: number;
};

export type MessageCopy = {
  type: 'copy';
  slideId: string;
};

export type MessagePaste = {
  type: 'paste';
  slideId: string;
  where: 'before' | 'after';
};

export type MessageCommmand = {
  type: 'command';
  name: string;
  args?: Record<string, unknown>;
};

export type MessagePointer = {
  type: 'pointer';
  id: string;
  slide: number;
  pos: null | {
    x: number;
    y: number;
  };
};

export type MessageLeft = {
  type: 'left';
  id: string;
};

// type ModelType = Models['model']
// type ModelTypes = { [P in ModelType]: }
// type Dehydrate<T extends typeof Model> = Partial<{[P in keyof InstanceType<T>]: unknown }> & { '__class__': T['model']}
// type Hydrate<T extends Record<string, unknown> & { '__class__': K}> =

export type AnswerHello = {
  type: 'hello';
  success: boolean;
  // board: Dehydrate<typeof Board>
  user: Dehydrate<typeof User>;
  // nodes: Dehydrate<typeof Node>[]
  state: ChangeSet;
  lastActionId: string;
  undo?: { actionId: string; slideId: string; changeSet: ChangeSet }[];
  redo?: { actionId: string; slideId: string; changeSet: ChangeSet }[];
  seq: number;
};

export type AnswerHelloUpdate = {
  type: 'hello';
  success: boolean;
  update: true;
  lastActionId: string;
  undo?: { actionId: string; slideId: string; changeSet: ChangeSet }[];
  redo?: { actionId: string; slideId: string; changeSet: ChangeSet }[];
  state?: ChangeSet;
  seq: number;
  user: Dehydrate<typeof User>;
};

export type AnswerAuth = {
  type: 'auth';
  success: boolean;
};

export type AnswerGetUser = {
  type: 'getUser';
  success: boolean;
  user: Dehydrate<typeof User>;
};

export type AnswerUpdate = {
  type: 'update';
  success: boolean;
  objects: Dehydrate<typeof Model>[];
};

export type AnswerAck = {
  type: 'ack';
  success: boolean;
  seq: number;
  id: string;
  changeSet?: UpdateChangeSet;
};

export type ErrorMessage = {
  type: string;
  success: false;
  code: number;
};
