import { v4 } from 'uuid';
import { Dehydrate, Model, Properties } from 'vue-model';
import { Action, NodesSaveAction, SlideCreateAction } from '../actions';
import { DoWhatYouWantAction } from '../actions/DoWhatYouWantAction';
import { Board, Circle, FreeLine, Group, Image, Line, Node, Point, Rect, Shape, Slide, Text } from '../models';
import { base16To64 } from './conversions';
import { bboxPoints } from './utils';

export async function jsonToCanvas(json: string, board: Board) {
  window.boardSetLoading(true);

  const dehydrated = JSON.parse(json) as Dehydrate<typeof Board>;

  let index = board.currentIndex;

  const action = new Action().start();
  await dehydrated.slides?.reduce(async (promise, slide) => {
    await promise;

    slide._id = v4();
    slide.boardId = board._id as any;

    const newSlide = Slide.hydrate(slide as unknown as Dehydrate<typeof Slide>) as Slide;
    newSlide.name = 'Board ' + board.slides.length;

    await new SlideCreateAction().execute({ slide: newSlide });
    await new NodesSaveAction().execute({ nodes: newSlide.nodes }, board._id, dehydrated.version);

    await new DoWhatYouWantAction().execute({ board, slide: newSlide }, ({ board, slide }) => {
      (board as Board).addSlide(slide as Slide, ++index);
    });
  }, Promise.resolve());

  action.stop();

  window.boardSetLoading(false);
}

let cache: Element | null = null;

export async function wbdToCanvas(text: string, board: Board) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/xml');
  const root = doc.documentElement;

  const screens = Array.from($$(root, ':scope > screen'));
  cache = $(root, ':scope > _MediaCache');

  let index = board.currentIndex;

  const action = new Action().start();

  await [...screens].reduce(async (promise, screen) => {
    // Wait for the other slide to be created
    await promise;

    // Create the slide
    const slide = Slide.create({ board, name: 'Board ' + board.slides.length });

    // Convert screen to slide
    const nodes = createSvgFromScreen(screen, slide);

    // Scale down images
    await nodes.reduce(async (promise, node) => {
      await promise;
      if (node.type === 'Image') {
        const image = node as Image;
        image.src = await Image.scaleImage(image.src);
      }
    }, Promise.resolve());

    slide.order = nodes.map((n) => n._id);

    // Execute save actions
    await new SlideCreateAction().execute({ slide });
    await new NodesSaveAction().execute({ nodes }, undefined, 1);

    await new DoWhatYouWantAction().execute({ board, slide }, ({ board, slide }) => {
      (board as Board).addSlide(slide as Slide, ++index);
    });
  }, Promise.resolve());

  cache = null;
  action.stop();
}

const getFromCache = (crc: string) => {
  if (!cache) return null;

  const data = $(cache, `[CRC="${crc}"]`)?.textContent || null;
  return data;
};

const attr = (el: Element, key: string) => {
  return el.getAttribute(key) as string;
};

function unCamelCase(s: string) {
  return s.replace(/([A-Z])/g, function (m, g) {
    return '-' + g.toLowerCase();
  });
}

const unCapapitalize = (str: string) => {
  return str.charAt(0).toLowerCase() + str.substr(1);
};

const getColor = (src: Element | null) => {
  if (!src) return null;

  const color = parseInt(attr(src, 'RGB'));
  if (isNaN(color)) return '#000000';

  return '#' + color.toString(16).padStart(6, '0');
};

const getOpacity = (src: Element | null) => {
  if (!src) return null;

  const opacity = parseInt(attr(src, 'ALPHA'));
  if (isNaN(opacity)) return null;

  return opacity / 255;
};

const $ = (src: Element, selector: string) => src.querySelector(selector);
const $$ = (src: Element, selector: string) => src.querySelectorAll(selector);

const setStroke = (src: Element, el: Shape) => {
  const stroke = $(src, 'penstroke') || $(src, 'toolstroke');

  if (!stroke) return;

  const [strokeWidth, strokeLineCap, strokeLineJoin, strokeMiterLimit] = ['WIDTH', 'CAP', 'JOIN', 'MITER'].map((key) =>
    attr(stroke, key),
  );

  const toolColor = $(src, 'toolcolor');
  const strokeColor = getColor(toolColor);
  const strokeOpacity = getOpacity(toolColor);

  Object.assign(el, {
    strokeColor,
    strokeOpacity,
    strokeWidth: strokeWidth == null ? 2 : strokeWidth,
    strokeLineCap,
    strokeLineJoin,
    strokeMiterLimit,
  });

  // attr(el, 'stroke', color)
  // attr(el, 'stroke-opacity', opacity)
  // attr(el, 'stroke-width', width)
  // attr(el, 'stroke-linecap', linecap)
  // attr(el, 'stroke-linejoin', linejoin)
  // attr(el, 'stroke-miterlimit', miterlimit)
};

const setFill = (src: Element, el: Shape) => {
  let fill = $(src, 'fillcolor');

  let fillColor = getColor(fill);
  let fillOpacity = getOpacity(fill);

  if (src.nodeName.startsWith('filled') || src.nodeName === 'texttool') {
    fill = $(src, 'toolcolor');
    if (fill) {
      fillColor = getColor(fill);
      fillOpacity = getOpacity(fill);
    }
  }

  Object.assign(el, {
    fill: !!fillColor,
    fillColor,
    fillOpacity,
  });

  // attr(el, 'fill', color)
  // attr(el, 'fill-opacity', opacity)
};

const setFont = (src: Element, el: Text) => {
  const font = $(src, 'font');

  if (!font) return;

  const size = attr(font, 'size');
  const name = convertFontFamily(attr(font, 'name'));

  const style = parseInt(attr(font, 'style'));
  let family = name;
  if (name) {
    family = unCamelCase(unCapapitalize(name));
  }

  el.underline = attr(font, 'underline') === 'true';
  el.italic = style === 2 || style === 3;
  el.bold = style === 1 || style === 3;
  el.fontFamily = family;
  // el.fontSize = +size * (85 / 96) Previously, this would convert pt to px, but the "* (85 / 96)" part doesn't seem needed.  Leaving this here in case board sets in the future do end up needing this conversion.
  el.fontSize = +size;
  el.textAlign = 'left';
};

// For performing "font family conversions" from .wbd files
const convertFontFamily = (fontFamily: string) => {
  if (fontFamily === 'SansSerif' || fontFamily === 'Dialog') {
    return 'Arial';
  } else if (fontFamily === 'Serif') {
    return 'Times New Roman';
  } else if (
    fontFamily === 'Monospaced' ||
    fontFamily === 'Dialog' ||
    fontFamily === 'DialogInput' ||
    fontFamily === 'monospace'
  ) {
    return 'Courier';
  } else {
    return 'Times New Roman';
  }
};

const setBorder = (src: Element, el: Image) => {
  const border = $(src, 'bordercolor');

  if (border) {
    el.strokeWidth = 2;
    el.strokeColor = '#000000';
  }
};

const boxToPoints = (x: number, y: number, width: number, height: number) => {
  return [new Point({ x, y }), new Point({ x: x + width, y: y + height })];
};

const rectToPoints = (el: Element) => {
  return boxToPoints(
    ...(attr(el, 'rect')
      .split(/\s*,\s*/)
      .map((a) => parseInt(a)) as [number, number, number, number]),
  );
};

const converters = {
  background(el: Element, boardId: string, lastNodeId: string | null, parentId: string | null) {
    return converters.grouptool(el, boardId, lastNodeId, parentId);
  },
  rectangletool(el: Element, boardId: string, lastNodeId: string | null, parentId: string | null) {
    const points = rectToPoints(el);
    const rect = Rect.create({
      points,
      strokeColor: '#000',
      strokeWidth: 2,
      fillColor: null,
      transform: {},
      boardId,
      parentId,
      prevId: lastNodeId,
    });

    setStroke(el, rect);
    setFill(el, rect);
    return [rect];
  },
  grouptool(el: Element, boardId: string, lastNodeId: string | null, parentId: string | null): Node[] {
    const id = v4();
    const children = process(Array.from(el.children), boardId, id);
    const group = Group.create({
      _id: id,
      transform: {},
      boardId,
      prevId: lastNodeId,
      firstNodeId: children[0]?._id,
      parentId,
    });

    return [group, ...children];
  },
  imagetool(el: Element, boardId: string, lastNodeId: string | null, parentId: string | null) {
    const imageTag = $(el, 'image');

    if (!imageTag) throw new Error('No image tag in image tool. Abort!');

    const points = rectToPoints(el);
    let src = attr(imageTag, 'name');
    const crc = attr(imageTag, 'CRC');

    const mime = src.slice(src.lastIndexOf('.') + 1);

    if (crc) {
      const data = getFromCache(crc);
      if (data) {
        src = 'data:image/' + mime + ';base64,' + base16To64(data);
      }
    }

    const image = Image.create({ points, src, transform: {}, boardId, prevId: lastNodeId, parentId });

    setStroke(el, image);
    setFill(el, image);
    setBorder(el, image);
    return [image];
  },
  texttool(el: Element, boardId: string, lastNodeId: string | null, parentId: string | null) {
    const textTag = $(el, 'Text');

    if (!textTag) throw new Error('No Text tag in text tool. Abort!');

    const points = bboxPoints(rectToPoints(el));
    const text = Text.create({
      points,
      text: textTag.textContent,
      transform: {},
      boardId,
      prevId: lastNodeId,
      parentId,
    });
    setFont(el, text);
    setFill(el, text);
    // setStroke(el, text)

    return [text];
  },
  pentool(el: Element, boardId: string, lastNodeId: string | null, parentId: string | null) {
    const path = $(el, 'Path');

    if (!path) throw new Error('No Path tag in pen tool. Abort!');

    const pathStr = path.textContent?.trim();

    if (!pathStr) throw new Error('No path data in path tag in pen tool. Abort!');

    const arr = pathStr.split(',').map((num) => parseInt(num) || 0);

    let x = 0;
    let y = 0;
    const points: Point[] = [];

    for (let i = 0; i < arr.length; i += 2) {
      x += arr[i];
      y += arr[i + 1];

      points.push(new Point({ x, y }));
    }

    const line = FreeLine.create({ points, transform: {}, boardId, prevId: lastNodeId, parentId });

    setStroke(el, line);

    return [line];
  },
  linetool(el: Element, boardId: string, lastNodeId: string | null, parentId: string | null) {
    const points = rectToPoints(el);

    const line = Line.create({ points, transform: {}, boardId, prevId: lastNodeId, parentId });

    setStroke(el, line);

    return [line];
  },
  ellipsetool(el: Element, boardId: string, lastNodeId: string | null, parentId: string | null) {
    const points = rectToPoints(el);
    const circle = Circle.create({
      points,
      strokeColor: '#000',
      strokeWidth: 2,
      fillColor: null,
      transform: {},
      boardId,
      prevId: lastNodeId,
      parentId,
    });

    setStroke(el, circle);
    setFill(el, circle);
    return [circle];
  },
};

const process = (els: Element[], boardId: string, parentId: string | null) => {
  let lastNodeId: string | null = null;
  // return els.map((el) => {
  //   const unfilledType = (el.nodeName.startsWith('filled')
  //     ? el.nodeName.substr(6)
  //     : el.nodeName) as keyof typeof converters

  //   if (!converters[unfilledType]) {
  //     console.warn(`Could not find a converter for ${el.nodeName}`)
  //     return Group.create({ transform: {}, boardId })
  //   }

  //   const node = converters[unfilledType](el, boardId, lastNodeId)
  //   lastNodeId = node._id
  //   return node
  // })

  return els.reduce((nodes, el) => {
    const unfilledType = (
      el.nodeName.startsWith('filled') ? el.nodeName.substr(6) : el.nodeName
    ) as keyof typeof converters;

    if (!converters[unfilledType]) {
      console.warn(`Could not find a converter for ${el.nodeName}`);
      return nodes;
    }

    const node = converters[unfilledType](el, boardId, lastNodeId, parentId);
    lastNodeId = node[0]._id;

    nodes.push(...node);
    return nodes;
  }, [] as Node[]);
};

const createSvgFromScreen = (screen: Element, slide: Slide) => {
  const size = attr(screen, 'size')
    .split(/\s*,\s*/)
    .map((s) => parseInt(s)) as [number, number, number, number];

  slide.viewbox = { x: 0, y: 0, width: size[0], height: size[1] };
  const boardId = slide.boardId as string;

  // const points = boxToPoints(0, 0, size[0], size[1])

  // Rect.create({ points, strokeWidth: 2, strokeColor: '#000', fillColor: 'none', transform: {}, boardId }),
  const id = v4();
  const nodes = process(Array.from(screen.children), boardId, id);
  return [
    Group.create({
      _id: id,
      boardId,
      slide,
      transform: {},
      firstNodeId: nodes[0]?._id,
    }),
    ...nodes,
  ];
};
