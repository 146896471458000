<template>
  <div class="relative h-[100vh] grow">
    <div ref="frame" class="canvas-container" v-bind="$attrs">
      <div
        ref="innerFrame"
        :style="transform"
        :class="{ 'cursor-select': slide.board.toolbar === 'select' && slide.board.shift }"
      >
        <svg
          ref="clickTarget"
          width="100%"
          height="100%"
          :viewBox="`${slide.viewbox.x} ${slide.viewbox.y} ${slide.viewbox.width} ${slide.viewbox.height}`"
          :style="{ cursor }"
          @dragstart.capture.prevent
        >
          <rect x="0" y="0" width="100%" height="100%" fill="white" style="pointer-events: none" />

          <Node v-for="node in slide.nodes" :id="node._id" :key="node._id" :frame="frame" />

          <Node v-if="slide.shapeInProgressId" :id="slide.shapeInProgressId" :frame="frame" />

          <slot />
        </svg>
      </div>
    </div>
    <slot name="controls" />
  </div>
</template>

<script lang="ts" setup>
import { computed, ComputedRef, ref, defineComponent, provide } from 'vue';
import Node from './Node.vue';
import { Board, Slide } from '../models';
import freeLine from '../assets/cursor_icons/freeLine.svg';
import text from '../assets/cursor_icons/text.svg';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  id: { type: String, required: true },
  compat: { type: Boolean },
});

const slide = computed(() => Slide.get(props.id)) as ComputedRef<Slide>;
const frame = ref<HTMLDivElement | undefined>();
const innerFrame = ref<HTMLDivElement | undefined>();
const clickTarget = ref<SVGSVGElement | undefined>();

provide('compat', props.compat);

const transform = computed(() => {
  const { a, e, f } = slide.value.board.transform;
  // Ignore translation lower than 0. It is handled by scroll
  return `transform: translate(${Math.max(0, e)}px, ${Math.max(0, f)}px) scale(${a})`;
});

const board = Board.get(slide.value.boardId as string) as Board;

const cursor = computed(() => {
  let value = '';
  switch (board.toolbar) {
    case 'freeLine': // Pen tool
      value = 'url(' + freeLine + ') 3 20, auto';
      break;
    case 'text': // Text tool
      value = 'url(' + text + ') 3 20, auto';
      break;

    default: // All other tools
      value = 'default';
      break;
  }

  return value;
});

defineExpose({ frame, innerFrame, clickTarget });
</script>

<style scoped>
.cursor-select {
  cursor: grab;
}

.reset-input {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
}

label {
  display: block;
  text-align: left;
}

.canvas-container {
  height: 100vh;
  flex: 1;
  position: relative;
  overflow: scroll;
  background: #f3f4f5;
  touch-action: none;
  /* padding-left: 115px; */
}

.canvas-container > div {
  width: 100%;
  height: 100%;
  overflow: visible;
  transform-origin: 0 0;
  box-sizing: border-box;
  /*padding: max(5%, 100px);*/
  box-sizing: border-box;
}

.canvas-container > div > svg {
  overflow: visible;
  display: block;
}
</style>
