export function update(key: string, cb: (val: any) => any) {
  set(key, cb(get(key)));
}

export function get(key: string) {
  try {
    return JSON.parse(localStorage.getItem(key) as string);
  } catch (e) {
    return null;
  }
}

export function set(key: string, val: any) {
  localStorage.setItem(key, JSON.stringify(val));
}

export function remove(key: string) {
  localStorage.removeItem(key);
}
