import { Baseline } from '..';

export class Polygon extends Baseline {
  static model = 'Polygon' as const;
  static base = Baseline;

  declare type: 'Polygon';

  static fields() {
    return {
      ...Baseline.fields(),
      type: this.string('Polygon'),
    };
  }
}

Polygon.boot();
