<template>
  <g style="pointer-events: none">
    <font-awesome-icon icon="hand-pointer" width="40" height="40" :x="x - 13" :y="y" :style="{ color }" />
    <text text-anchor="middle" :x="x + 13" :y="y + 55" font-size="12px" opacity="0.75x">
      {{ userInitials }}
    </text>
  </g>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { User } from '../models';

function hashCode(str: string) {
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i: number) {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}

export default defineComponent({
  name: 'Pointer',
  props: ['x', 'y', 'id'],
  setup(props) {
    // Create the initials for the user
    const userName = computed(() => User.get(props.id)?.name ?? '');

    // If userName.value === 'Unknown' we don't need to display a "U" (i.e. if the user is a local whiteboard user).
    // Also, some people could have more than 2 words for their first/last name (i.e. John Paul Smith).
    // So, we will create the initials based on the number of words in their name.
    const userInitials = computed(() => {
      if (userName.value === 'Unknown') return '';
      return userName.value
        .toUpperCase()
        .split(' ')
        .map((a) => a.charAt(0))
        .join('');
    });

    // Detect if the color of the pointer will be too light and thus not contrast enough against the whiteboard background.
    const possiblePointerColor = '#' + intToRGB(hashCode(props.id));
    const hex = possiblePointerColor.replace('#', '');
    const cR = parseInt(hex.substr(0, 2), 16);
    const cG = parseInt(hex.substr(2, 2), 16);
    const cB = parseInt(hex.substr(4, 2), 16);
    const brightness = (cR * 299 + cG * 587 + cB * 114) / 1000;
    console.log('Brightness Value: ' + brightness);

    // If the pointer color is too bright, make it black.  The value of 200 is arbitrary and should be adjusted as this is used in the wild.
    const color = brightness <= 200 ? computed(() => possiblePointerColor) : computed(() => '#000000');

    return {
      color,
      userInitials,
    };
  },
});
</script>
