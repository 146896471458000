import { Group, Image, Node, Shape, Text } from '../models';
import { Action } from './';

type NodesSaveActionObjects = { nodes: Node[] };
type NodesSaveActionArgs = [boardId?: string, version?: number];
export class NodesSaveAction extends Action<NodesSaveActionObjects, NodesSaveActionArgs> {
  static actionName = 'NodesSaveAction';

  async redo({ nodes }: NodesSaveActionObjects, boardId?: string, version?: number) {
    console.log('version', version);
    const store = async (nodes: Node[]) => {
      if (!version) {
        nodes.forEach((node) => {
          if (node.type !== 'Group') {
            const n = node as Shape;

            //Only set "filled" for non-filled in shapes 
            const openShapes = ['LineSegment', 'FreeLine', 'Line']
            if (!openShapes.includes(node.type)) {
              n.filled = !(n.fillColor === 'none' || !n.fillColor);
            }
          }
        });
      }

      await nodes.reduce(async (promise, n) => {
        await promise;

        if (n instanceof Image) {
          // Scale down images
          n.src = await Image.scaleImage(n.src);
        }

        if (boardId) {
          n.boardId = boardId;
        }

        if (n.type === 'Text' && (n as Text).fontFamily === 'times -new -roman') {
          (n as Text).fontFamily = 'Times New Roman';
        }

        this.create(n);

        if (n instanceof Group) {
          await store(n.nodes);
        }
      }, Promise.resolve());
    };

    return store(nodes);
  }
}
