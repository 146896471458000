import { DoWhatYouWantAction } from '@/actions';
import { Board, Group, Node, Shape } from '@/models';
import { computed, WritableComputedRef } from 'vue';

type OnlyTProps<OBJ, T> = {
  [K in keyof OBJ]: OBJ[K] extends T ? K : never;
}[keyof OBJ];

type PropThatStartsWithT<OBJ, KEY extends string, TYPE = string | number> = Extract<
  OnlyTProps<OBJ, TYPE>,
  `${KEY}${string}`
>;

type FillProps = PropThatStartsWithT<Shape, 'fill'>;
type StrokeProps = PropThatStartsWithT<Shape, 'stroke'>;

const changeProp = <FP extends FillProps, SP extends StrokeProps, T extends Shape[FP] & Shape[SP]>(
  nodes: Node[],
  val: T,
  prop: FP | SP,
) => {
  nodes.forEach((node) => {
    if (node instanceof Group) {
      changeProp(node.nodes, val, prop);
    } else if (node instanceof Shape) {
      node[prop] = val;
    }
  });
};

let currentAction: DoWhatYouWantAction<{ nodes: Node[] }> | null = null;

export const createSetter = <
  FP extends FillProps,
  SP extends StrokeProps,
  T extends Shape[FP] & Shape[SP] & Board[FP] & Board[SP],
>(
  board: Board,
  prop: FP | SP,
) => {
  return (val: T, immediate = false, nodes = board.currentSlide?.selectedNodes) => {
    if (nodes?.length) {
      // Trigger the whiteboard indicator to start
      window.reportWhiteboardAction('drawing-start');

      currentAction = currentAction || new DoWhatYouWantAction<{ nodes: Node[] }>().start();
      currentAction.execute({ nodes }, ({ nodes }) => {
        changeProp(nodes, val, prop);
      });

      if (immediate) {
        window.reportWhiteboardAction('drawing-stop');
        currentAction?.stop();
        currentAction = null;
      }

      return;
    }

    board[prop] = val;
  };
};

export const createGetter = <
  FP extends FillProps,
  SP extends StrokeProps,
  T extends Shape[FP] & Shape[SP] & Board[FP] & Board[SP],
>(
  board: Board,
  prop: FP | SP,
) => {
  return (nodes = board.currentSlide?.selectedNodes) => {
    if (nodes?.length) {
      const node = nodes[0] as Shape;

      if (node[prop] != null) {
        return node[prop] as T;
      }
    }

    return board[prop] as T;
  };
};

export const createComputed = <
  FP extends FillProps,
  SP extends StrokeProps,
  T extends Shape[FP] & Shape[SP] & Board[FP] & Board[SP],
>(
  board: Board,
  prop: FP | SP,
  set: (val: T) => void = createSetter(board, prop),
  get: (nodes?: Node[]) => T = createGetter(board, prop),
) => {
  return computed<T>({ get: () => get(), set: (val) => set(val) }) as WritableComputedRef<T>;
};

export const finishShapePropUpdate = () => {
  window.reportWhiteboardAction('drawing-stop');

  currentAction?.stop();
  currentAction = null;
};
