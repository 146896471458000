import { NodesDeleteAction } from '../../actions';
import { Shape } from '../../models';

export class Pointer extends Shape {
  static base = Shape;
  static model = 'Pointer' as const;
  static instance: Pointer | null = null;
  declare type: 'Pointer';
  mousedown!: boolean;

  static fields() {
    return {
      ...Shape.fields(),
      type: this.string('Pointer'),
      selectionComponent: this.string('No Selection'),
      mousedown: this.boolean(false),
    };
  }

  static create(value: Record<string, unknown>): Pointer;
  static create(value: Record<string, unknown>[]): Pointer[];
  static create(value: Record<string, unknown> | Record<string, unknown>[]): Pointer | Pointer[] {
    if (Array.isArray(value)) throw new Error('Cant create multiple pointers');
    if (this.instance) {
      new NodesDeleteAction().execute({ nodes: [this.instance] });
    }
    return (this.instance = super.create(value) as Pointer);
  }

  onMouseUp(_ev: MouseEvent) {
    this.mousedown = false;
  }

  onMouseDown(ev: MouseEvent) {
    this.mousedown = true;
    this.onMouseMove(ev);
    this.finishShape();
  }
}

Pointer.boot();
