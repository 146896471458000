import { capitalize, computed, onBeforeUnmount, Ref } from 'vue';
import { Slide as SlideModel, Node, Shape, Box, Board } from '../models';

import * as shapes from '../models/shapes';
import { aspectRatioPoints, assert, bbox, intersects } from '../utils/utils';
import { ShapeSaveAction } from '../actions';
import { history } from '../History';

const shapeDefaults = (board: Board, shapeType: string) => {
  const { fillColor, strokeColor, fillOpacity, strokeOpacity, strokeWidth, strokeStyle } = board;
  let isFilled = shapeType.startsWith('filled');
  const hasArrow = shapeType.startsWith('arrowed') ? 1 : shapeType.startsWith('doubleArrowed') ? 2 : 0;

  const actualShapeType = isFilled
    ? shapeType.slice(6)
    : hasArrow === 1
      ? shapeType.slice(7)
      : hasArrow === 2
        ? shapeType.slice(13)
        : shapeType;

  isFilled = isFilled || shapeType === 'text';

  switch (shapeType) {
    case 'select': {
      return {
        isTool: true,
        selected: true,
        selectionComponent: 'DumbSelection',
        fillColor: 'none',
        component: 'Rect',
      };
    }

    default: {
      return {
        isTool: false,
        selected: false,
        component: capitalize(actualShapeType),
        filled: isFilled,
        fillColor,
        fillOpacity,
        strokeColor,
        strokeOpacity,
        strokeWidth,
        strokeStyle,
        hasArrow,
      };
    }
  }
};

export function useDrawing(slide: Ref<SlideModel>) {
  onBeforeUnmount(() => {
    slide.value.shapeInProgress?.cancelDrawing();
  });

  const startDrawing = async (ev: MouseEvent, shapeType: string) => {
    const shapeInProgress = slide.value.shapeInProgress;
    if (shapeInProgress && !shapeInProgress.finished) {
      if (ev.button === 2) {
        return shapeInProgress.cancelDrawing();
      }

      if (shapeInProgress) {
        return shapeInProgress.onMouseDown(ev);
      }
    }

    if (ev.button === 2) {
      return;
    }

    // Trigger the whiteboard indicator to start
    window.reportWhiteboardAction('drawing-start');

    const shapesAny = shapes as { [key: string]: typeof Shape };

    const shapeInfo = shapeDefaults(slide.value.board, shapeType);
    const { component, ...defaultProps } = shapeInfo;

    assert(component in shapesAny, `Shape ${component} not found`);

    const shape = shapesAny[component]!.create({
      ...defaultProps,
      parentId: slide.value._id,
      boardId: slide.value.board._id,
      transform: {},
      finished: false,
    });

    // if (!slide.value.firstNodeId) {
    //   slide.value.firstNodeId = shape._id
    // }

    const matrix = computed(() => {
      return slide.value.screenCtm;
    });

    const keyDown = (ev: KeyboardEvent) => {
      if (ev.key === 'Control') {
        aspectRatioPoints(shape.points, shape instanceof shapes.Baseline);
      }
    };

    const keyUp = (ev: KeyboardEvent) => {
      if (ev.key === 'Control') {
        shape.onMouseMove(lastEv);
      }
    };

    document.addEventListener('keydown', keyDown, { capture: true });
    document.addEventListener('keyup', keyUp, { capture: true });

    shape.initDrawing(matrix);

    let lastEv = new PointerEvent('pointermove');
    const onMouseMove = (ev: PointerEvent) => {
      lastEv = ev;
      shape.onMouseMove(ev);
      if (ev.ctrlKey) {
        aspectRatioPoints(shape.points, shape instanceof shapes.Baseline);
      }
    };
    const onMouseUp = (ev: PointerEvent) => {
      lastEv = ev;
      shape.onMouseUp(ev);
      if (ev.ctrlKey) {
        aspectRatioPoints(shape.points, shape instanceof shapes.Baseline, true);
      }
    };

    // const onMouseLeave = shape.onMouseLeave.bind(shape)
    // const onMouseEnter = shape.onMouseEnter.bind(shape)

    const onShapeFinished = () => {
      document.removeEventListener('keydown', keyDown, { capture: true });
      document.removeEventListener('keyup', keyUp, { capture: true });
      document.removeEventListener('pointermove', onMouseMove, { capture: true });
      document.removeEventListener('pointerup', onMouseUp, { capture: true });

      // Trigger the whiteboard indicator to stop
      window.reportWhiteboardAction('drawing-stop');

      if (lastEv.ctrlKey) {
        aspectRatioPoints(shape.points, shape instanceof shapes.Baseline, true);
      }

      if (shape.isTool) {
        shape.delete();
        // action.cancel()
      } else {
        if (shape.type === 'Pointer') {
          //
        } else {
          new ShapeSaveAction().execute({ node: shape, slide: slide.value });
        }

        // action.stop()
      }

      slide.value.shapeInProgress = null;
      // frame.value.frame.removeEventListener('mouseleave', onMouseLeave)
      // frame.value.frame.removeEventListener('mouseenter', onMouseEnter)
    };

    const onShapeCanceled = () => {
      document.removeEventListener('keydown', keyDown, { capture: true });
      document.removeEventListener('keyup', keyUp, { capture: true });
      document.removeEventListener('pointermove', onMouseMove, { capture: true });
      document.removeEventListener('pointerup', onMouseUp, { capture: true });

      // if (shape._id === slide.value.firstNodeId) {
      //   slide.value.firstNodeId = null
      // }

      shape.delete();

      slide.value.shapeInProgress = null;

      // Trigger the whiteboard indicator to stop
      window.reportWhiteboardAction('drawing-stop');
      // frame.value.frame.removeEventListener('mouseleave', onMouseLeave)
      // frame.value.frame.removeEventListener('mouseenter', onMouseEnter)
    };

    const onShapeUpdated = () => {
      const box1 = new Box(bbox(shape.points));
      const isMod = history().user.moderator;

      slide.value.nodes.forEach((node: Node) => {
        const selectable = (!node.locked || slide.value.board.locksDisabled) && (!node.hidden || isMod);

        const box2 = new Box(bbox(node.generateCornerPoints())); // .transform(node.transform)
        node.selected = selectable && (intersects(box1, box2) || (slide.value.board.shift && node.selected));
      });
    };

    shape.on('finished', onShapeFinished);
    shape.on('canceled', onShapeCanceled);

    if (shape.isTool) {
      shape.on('update:points', onShapeUpdated);
      shape.on('canceled', onShapeUpdated);
    }

    slide.value.shapeInProgress = shape;
    document.addEventListener('pointermove', onMouseMove, { capture: true });
    document.addEventListener('pointerup', onMouseUp, { capture: true });
    // frame.value.frame.addEventListener('mouseleave', onMouseLeave)
    // frame.value.frame.addEventListener('mouseenter', onMouseEnter)

    shape.onMouseDown(ev);
  };

  return {
    startDrawing,
  };
}
