import { v4 } from 'uuid';
import { Board, Model, User } from '../models';

export class Participant extends Model {
  static model = 'Participant' as const;

  static primaryKey = '_id';
  board!: Board | null;
  userId!: string | null;
  boardId!: string | null;
  canDraw!: boolean;
  canNavigate!: boolean;
  isOwner!: boolean;

  static fields() {
    return {
      _id: this.string(v4),
      board: this.belongsTo(Board),
      user: this.belongsTo(User),
      boardId: this.string(null),
      userId: this.string(null),
      canDraw: this.boolean(true),
      canNavigate: this.boolean(true),
      isOwner: this.boolean(false),
    };
  }
}

Participant.boot();
