import { Node } from '../models';
import { Action } from './';

type NodesUnlockActionObjects = { nodes: Node[] };
export class NodesUnlockAction extends Action<NodesUnlockActionObjects> {
  static actionName = 'NodesUnlockAction';

  async redo({ nodes }: NodesUnlockActionObjects) {
    nodes.forEach((node) => {
      node.locked = false;
    });
  }
}
