import { createWebHistory, createRouter } from 'vue-router';
import Welcome from './components/Welcome.vue';
import Board from './components/Board.vue';
const history = createWebHistory();
const routes = [
  { path: '/', component: Welcome },
  { path: '/:id', component: Board, props: true },
  { path: '/:id/:index', component: Board, props: true },
];
const router = createRouter({ history, routes });
export default router;
