<template>
  <ToolbarStroke v-if="isStroke" :boardId="boardId" v-bind="$attrs" />
  <ToolbarFill v-else :boardId="boardId" v-bind="$attrs" />
</template>

<script lang="ts" setup>
import { Board, Group, Shape } from '@/models';
import ToolbarFill from './ToolbarFill.vue';
import ToolbarStroke from './ToolbarStroke.vue';
import { computed } from 'vue';

const props = defineProps<{ boardId: string }>();

const board = Board.get(props.boardId) as Board;

const isStroke = computed(() => {
  if (
    board.currentSlide?.shapeInProgress &&
    !board.currentSlide?.shapeInProgress.filled &&
    board.toolbar !== 'select'
  ) {
    return true;
  }

  if (!['select', 'pointer'].includes(board.toolbar) && !board.toolbar.toLowerCase().includes('filled')) return true;

  return (
    board.currentSlide?.selectedNodes.length &&
    board.currentSlide?.selectedNodes.every((node) => !(node instanceof Group || (node as Shape).filled))
  );
});
</script>
