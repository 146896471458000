import * as actions from '../actions';
import { useHistory } from './useHistory';
import { WebSocketStatus, createWebSocket } from '../utils/websocket';
import { update } from 'idb-keyval';
import { cleanUpStateChangeSet, mergeChangeSet } from '../utils/utils';
import { ChangeSet, ChangeSetNonOptional } from '../utils/WebSocketMessage';
import { nextTick, ref } from 'vue';
import * as store from '../utils/store';

const history = useHistory();
let ws: ReturnType<typeof createWebSocket>;

const actionHistory = new Map<string, actions.Action>();

const instantActions = [
  actions.DoWhatYouWantAction.actionName,
  actions.NodesDeleteAction.actionName,
  actions.NodesGroupAction.actionName,
  actions.NodesLockAction.actionName,
  actions.NodesSaveAction.actionName,
  actions.NodesToBackAction.actionName,
  actions.NodesToFrontAction.actionName,
  actions.NodesUnlockAction.actionName,
  actions.NodeUngroupAction.actionName,
  actions.ShapeSaveAction.actionName,
  actions.SlideCreateAction.actionName,
  actions.SlideDeleteAction.actionName,
  actions.SlidePasteAction.actionName,
  actions.SlideRenameAction.actionName,
  actions.Action.actionName,
];

const noop = (..._args: any[]): any => {
  /* noop */
};
// const handleImageUpload = (wsMessage: MessageAction) => {
//   const data = {} as {[id: string]: string}

//   if (wsMessage.changeSet.Node?.[CREATE]) {
//     for (const [ id, record ] of Object.entries(wsMessage.changeSet.Node[CREATE])) {
//       if (record.type === 'Image') {
//         data[id] = record.src as string
//         record.src = '/images/' + id + '.webp'
//       }
//     }
//   }

//   Object.entries(data).forEach(([id, src]) => {

//     resizeImage(src)

//   })
// }

const getWebsocket = (boardId: string, local = false) => {
  const processAction = (action: actions.Action) => {
    if (action.status !== 'success') {
      return false;
    }

    action.executeRedo();
    history.board.seq = action.seq;

    update('board-' + boardId, (changeSet: ChangeSet | undefined) => {
      if (!changeSet) {
        return action.changeSet;
      } else {
        return cleanUpStateChangeSet(
          mergeChangeSet(action.changeSet as ChangeSetNonOptional, changeSet as ChangeSetNonOptional),
        );
      }
    });

    store.set('actionId-' + boardId, action.id);

    actionHistory.delete(action.id);

    if (action.noHistory) return true;

    history.pushToStack(['remote', action]);

    return true;
  };

  const processActions = () => {
    for (const action of actionHistory.values()) {
      if (!processAction(action)) {
        break;
      }
    }
  };

  if (local) {
    /* START: This code chunk is somewhat of a hack to make undo and redo work when the user is working locally.  Example: https://localhost:3001/c4ca4238#local  */
    // This function is only executed for local action and NOT for remote actions
    history.on('actionExecuted', (action: actions.Action, _isLocal: boolean) => {
      const wsMessage = action.toWSMessage();

      if (Object.keys(wsMessage.changeSet).length === 0) {
        return;
      }

      action.solidifyChangeSet();
      console.log('Solidified', action.solidified);
      actionHistory.set(action.id, action);

      // Optimicistly assume the action was successful
      history.board.seq++;

      store.set('actionId-' + boardId, action.id);

      const isUndo = action.status === 'undo';
      if (action.status === 'redo' || action.status === 'pending') {
        history.pushToStack([history.user._id, action], action.status === 'redo');
      } else if (isUndo) {
        history.redoStack.push([history.user._id, action]);
      }

      update('board-' + boardId, (changeSet: ChangeSet | undefined) => {
        if (!changeSet) {
          return action.changeSet;
        } else {
          return cleanUpStateChangeSet(
            mergeChangeSet(action.changeSet as ChangeSetNonOptional, changeSet as ChangeSetNonOptional, isUndo),
          );
        }
      });
    });

    // Put local user on slide that the undo just occurred for
    history.on('undoAction', (action: actions.Action) => {
      history.board.setSlide(history.board.order.indexOf(action.slideId));

      actionHistory.set(action.id, action);
      action.status = 'undo';
      history.board.seq--;

      history.redoStack.push([history.user._id, action]);
    });

    // Put local user on slide that the undo just occurred for
    history.on('redoAction', (action: actions.Action) => {
      history.board.setSlide(history.board.order.indexOf(action.slideId));

      actionHistory.set(action.id, action);
      action.status = 'redo';
      history.board.seq++;

      history.pushToStack([history.user._id, action]);
    });

    /* END: This code chunk is somewhat of a hack to make undo and redo work when the user is working locally.  Example: https://localhost:3001/c4ca4238#local  */

    ws = {
      close: noop,
      send: noop,
      sendJSON: noop,
      on: noop,
      request: noop,
      status: ref(WebSocketStatus.READY),
      reconnect: noop,
    };
    return ws;
  }

  ws = createWebSocket(boardId);

  // This function is only executed for local action and NOT for remote actions
  history.on('actionExecuted', (action: actions.Action, _isLocal: boolean) => {
    const wsMessage = action.toWSMessage();

    if (Object.keys(wsMessage.changeSet).length === 0) {
      return;
    }

    ws.sendJSON(wsMessage);
    action.solidifyChangeSet();
    console.log('Solidified', action.solidified);
    actionHistory.set(action.id, action);

    // Optimicistly assume the action was successful
    history.board.seq++;

    if (instantActions.includes(action.constructor.actionName)) {
      // Trigger the whiteboard indicator to start
      window.reportWhiteboardAction('drawing-start');

      // Wait a short time then stop the whitebaord indicator from showing
      setTimeout(function () {
        window.reportWhiteboardAction('drawing-stop');
      }, 500); // Delaying 1/2 sec.
    }

    store.set('actionId-' + boardId, action.id);
  });

  // This function is executed for both local action and remote actions
  history.on('undoAction', (action: actions.Action, remote = false) => {
    const wsMessage = action.toWSMessage();

    if (!remote) {
      // Put local user on slide that the undo just occurred for
      history.board.setSlide(history.board.order.indexOf(action.slideId));

      actionHistory.set(action.id, action);
      action.status = 'undo';
      ws.sendJSON(action.toWSUndo());
      history.board.seq--;
    } else {
      update('board-' + boardId, (changeSet: ChangeSet | undefined) => {
        if (!changeSet) {
          return wsMessage.changeSet;
        } else {
          return cleanUpStateChangeSet(
            mergeChangeSet(wsMessage.changeSet as ChangeSetNonOptional, changeSet as ChangeSetNonOptional, true),
          );
        }
      });
    }
  });

  // This function is executed for both local action and remote actions
  history.on('redoAction', (action: actions.Action, remote = false) => {
    const wsMessage = action.toWSMessage();

    if (!remote) {
      // Put local user on slide that the undo just occurred for
      history.board.setSlide(history.board.order.indexOf(action.slideId));

      actionHistory.set(action.id, action);
      action.status = 'redo';
      ws.sendJSON(action.toWSRedo());
      history.board.seq++;
    } else {
      update('board-' + boardId, (changeSet: ChangeSet | undefined) => {
        if (!changeSet) {
          return wsMessage.changeSet;
        } else {
          return cleanUpStateChangeSet(
            mergeChangeSet(wsMessage.changeSet as ChangeSetNonOptional, changeSet as ChangeSetNonOptional),
          );
        }
      });
    }
  });

  // This function is only executed for remote actions
  ws.on('action', (payload) => {
    // Board not ready yet. Ignore actions
    if (!history.board) return;

    const action = actions.Action.fromWSMessage(payload);

    if (actionHistory.size === 0) {
      processAction(action);
    } else {
      actionHistory.set(action.id, action);
    }
  });

  // This function is only executed for remote actions
  ws.on('undo', (message) => {
    // Board not ready yet. Ignore actions
    if (!history.board) return;

    history.undoById(message.actionId);
    history.board.seq = message.seq;
  });

  // This function is only executed for remote actions
  ws.on('redo', (message) => {
    // Board not ready yet. Ignore actions
    if (!history.board) return;

    history.redoById(message.actionId);
    history.board.seq = message.seq;
  });

  ws.on('pointer', (message) => {
    // Board not ready yet. Ignore actions
    if (!history.board) return;

    const { id, pos, slide } = message;

    history.getActiveBoard().setPointer(id, pos, slide);
  });

  ws.on('ack', (message) => {
    const { seq, id, success, changeSet } = message;

    let newLastActionId = id;
    const action = actionHistory.get(id);

    if (!action) {
      console.warn('Received ACK for action we never sent');
      store.set('actionId-' + history.board._id, id);
      history.board.seq = seq;
      return;
    }

    if (action.id !== [...actionHistory][0][0]) {
      console.warn('Received ACK in wrong order');

      for (const [id, action] of actionHistory) {
        if (action.id === id) {
          break;
        }

        actionHistory.delete(id);
      }
    }

    const isUndo = action.status === 'undo';

    if (!success) {
      if (isUndo) {
        action.executeRedo();
      } else {
        action.executeUndo();
      }
      actionHistory.delete(id);
      processActions();
      history.board.seq = seq;
      return;
    }

    if (changeSet) {
      action.executeUndo();
      action.solidified = changeSet;
      action.solidifyChangeSet();
      nextTick(() => action.executeRedo());
    }

    if (action.status === 'redo' || action.status === 'pending') {
      history.pushToStack([history.user._id, action], action.status === 'redo');
    } else if (isUndo) {
      newLastActionId = history.undoStack[history.undoStack.length - 1]?.[1].id;
      history.redoStack.push([history.user._id, action]);
    }

    update('board-' + boardId, (changeSet: ChangeSet | undefined) => {
      if (!changeSet) {
        return action.changeSet;
      } else {
        return cleanUpStateChangeSet(
          mergeChangeSet(action.changeSet as ChangeSetNonOptional, changeSet as ChangeSetNonOptional, isUndo),
        );
      }
    });

    action.status = 'success';

    actionHistory.delete(id);
    store.set('actionId-' + history.board._id, newLastActionId);
    processActions();
    history.board.seq = seq;
  });

  return ws;
};

export function useWebSocket(boardId: string, local: boolean) {
  if (!ws) {
    return getWebsocket(boardId, local);
  }

  return ws;
}
