<template>
  <div class="flex items-center gap-1">
    <Icon v-if="icon" :icon="icon" @click="enableEditing" class="shrink-0" :class="iconClass" />
    <span v-if="label" @click="enableEditing" class="shrink-0">{{ label }}</span>

    <div class="number-input grow" :class="{ 'slider-input-dragging': isDragging }">
      <button class="arrow" @click="decrement">-</button>
      <template v-if="isEditing">
        <input
          ref="input"
          v-model.number="value"
          type="number"
          :placeholder="placeholder"
          :min="min"
          :disabled="disabled"
          :max="max"
          :step="step"
          @blur="disableEditing"
          @change="emitChangeEvent"
          class="w-full"
          @keydown.stop.prevent.enter="disableEditing"
          @keydown.stop.prevent.escape="disableEditing"
        />
      </template>
      <template v-else>
        <button
          class="input"
          :class="{ 'cursor-not-allowed': disabled }"
          @mousedown="startDrag"
          @dblclick="enableEditing"
        >
          {{ value.toFixed(Math.max(0, Math.ceil(Math.log10(1 / step)))) ?? placeholder }}
        </button>
      </template>
      <button class="arrow" @click="increment">+</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import Icon from './Icon.vue';
import { ref, computed, nextTick, PropType, Component } from 'vue';

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0,
  },
  label: {
    type: String,
    required: false,
  },
  placeholder: {
    type: String,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
  },
  icon: {
    type: [String, Object] as PropType<string | Component>,
    required: false,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  },
  iconClass: {
    type: String,
  },
});

const emit = defineEmits(['update:modelValue', 'change']);

const value = computed({
  get: () => props.modelValue,
  set: (value) => {
    value = Math.max(props.min, Math.min(props.max, value));
    emit('update:modelValue', Number(value));
  },
});

const emitChangeEvent = () => {
  emit('change', Number(value.value));
};

const isEditing = ref(false);
const input = ref<HTMLInputElement>();
let isDragging = false;
let startX = 0;
let targetWidth = 0;

const startDrag = (event: MouseEvent) => {
  startX = event.pageX;
  targetWidth = (event.target as HTMLElement).clientWidth;
  isDragging = true;

  document.addEventListener('mousemove', drag);
  document.addEventListener('mouseup', endDrag);
};

const drag = (event: MouseEvent) => {
  if (!isDragging || props.disabled) return;

  const travelDistance = Math.round(
    (((event.pageX - startX) / targetWidth) * (props.max - props.min) + value.value) / props.step,
  );

  // calculate traveled distance from starting point to current point and map it to the value range
  const newValue = travelDistance * props.step;

  if (newValue === value.value) return;

  startX = event.pageX;

  value.value = newValue;
};

const endDrag = () => {
  isDragging = false;
  document.removeEventListener('mousemove', drag);
  document.removeEventListener('mouseup', endDrag);
  emitChangeEvent();
};

const increment = () => {
  if (props.disabled) return;
  value.value += props.step;
  emitChangeEvent();
};
const decrement = () => {
  if (props.disabled) return;
  value.value -= props.step;
  emitChangeEvent();
};
const enableEditing = () => {
  if (props.disabled) return;
  isEditing.value = true;
  nextTick(() => {
    input.value?.focus();
  });
};
const disableEditing = () => {
  isEditing.value = false;
};
</script>

<style scoped>
.number-input {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  align-items: stretch;
}

.arrow {
  all: unset;
  color: black;
  border: none;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
}

.number-input:not(.slider-input-dragging) .arrow:hover {
  background-color: gray;
}

button.input,
input {
  all: unset;
  flex-grow: 1;
  padding: 4px 8px;
  border: none;
  text-align: center;
  font-size: 14px;
  background: none;
  min-width: 0;
  display: block;
  width: auto !important;
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

input:focus {
  outline: none;
}
input:disabled {
  color: #999;
}

/*hide number input arrows*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
