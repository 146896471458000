import { Matrix } from '../models';
import { MatrixLike } from '../utils/types';

export type PointLike = { x: number; y: number };

export class Point {
  x = 0;
  y = 0;
  fixed = true;

  constructor(source: number[] | number | Point | { x: number; y: number } | undefined = 0, p2?: number) {
    this.init(source, p2);
  }

  add(p: Point) {
    return new Point({ x: this.x + p.x, y: this.y + p.y });
  }

  sub(p: Point) {
    return new Point({ x: this.x - p.x, y: this.y - p.y });
  }

  mul(factor: number) {
    return new Point({ x: this.x * factor, y: this.y * factor });
  }

  div(factor: number) {
    return this.mul(1 / factor);
  }

  lengthQuad() {
    return this.x * this.x + this.y * this.y;
  }

  length() {
    return Math.sqrt(this.lengthQuad());
  }

  isZero() {
    return this.x === 0 && this.y === 0;
  }

  norm() {
    const len = this.length();
    return this.div(len);
  }

  normal(p: Point) {
    const newp = p.sub(this).norm();
    const temp = newp.x;

    newp.x = newp.y;
    newp.y = -temp;
    return newp;
  }

  equals(p: Point) {
    return this.x === p.x && this.y === p.y;
  }

  dot(p: Point) {
    return this.x * p.x + this.y * p.y;
  }

  det(p: Point) {
    return this.x * p.y - p.x * this.y;
  }

  angleToCos(p: Point) {
    return this.dot(p) / (this.length() * p.length());
  }

  // This is the left vector, p is the right vector
  angleTo(p: Point) {
    return Math.atan2(this.det(p), this.dot(p));
    // return Math.acos(this.angleToCos(p))
  }

  pointAtAngle(alpha: number, length: number) {
    const x = this.x * Math.cos(alpha) - this.y * Math.sin(alpha);
    const y = this.x * Math.sin(alpha) + this.y * Math.cos(alpha);
    return new Point({ x, y }).mul(length);
  }

  init(x: number[] | number | Point | { x: number; y: number } | undefined = 0, y?: number) {
    const base = { x: 0, y: 0 };

    // ensure source as object
    const result = Array.isArray(x)
      ? { x: x[0], y: x[1] }
      : typeof x === 'object'
        ? { x: x.x, y: x.y }
        : { x: x, y: y };

    // merge source
    this.x = result.x == null ? base.x : result.x;
    this.y = result.y == null ? base.y : result.y;

    return this;
  }

  toArray() {
    return [this.x, this.y];
  }

  transform(m: MatrixLike) {
    return this.clone().transformO(m);
  }

  // Transform point with matrix
  transformO(m: MatrixLike) {
    if (!Matrix.isMatrixLike(m)) {
      m = new Matrix(m);
    }

    const { x, y } = this;

    const n = m as Matrix;

    // Perform the matrix multiplication
    this.x = n.a * x + n.c * y + n.e;
    this.y = n.b * x + n.d * y + n.f;

    return this;
  }

  clone(): Point {
    return new Point(this);
  }

  round(): this {
    this.x = Math.round(this.x * 1000) / 1000;
    this.y = Math.round(this.y * 1000) / 1000;
    return this;
  }

  toJSON() {
    return { x: this.x, y: this.y };
  }
}

// window.Point = Point
