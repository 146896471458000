import { capitalize, computed, Ref } from 'vue';
import { Matrix, Node, Point } from '../../models';
import { bboxPoints, getPointFromEvent } from '../../utils/utils';

class PointWrapper {
  constructor(arr: { x: number; y: number }[], ...args: any[]) {
    if (!Array.isArray(arr)) {
      return new Point(arr, ...args);
    }
    return arr.map((p) => new Point(p.x, p.y));
  }
}

export class Shape extends Node {
  static base = Node;
  static model = 'Shape';

  points!: Point[];
  filled!: boolean;
  stroked!: boolean;
  isTool!: boolean;
  hasArrow!: 0 | 1 | 2;
  closed!: boolean;
  strokeOpacity!: number;
  strokeDashArray!: number[];
  strokeStyle!: 'solid' | 'dashed' | 'dotted';

  hasMoved = false;
  matrix: Ref<Matrix> | undefined;

  static fields() {
    return {
      ...Node.fields(),
      type: this.string('Shape'),
      points: this.array<Point[]>([], PointWrapper as any),
      fillColor: this.string(null),
      fillOpacity: this.number(null),
      filled: this.boolean(false),
      strokeColor: this.string(null),
      strokeOpacity: this.number(null),
      strokeWidth: this.number(null),
      strokeMeterLimit: this.number(null),
      strokeLineCap: this.number(null),
      strokeLineJoin: this.number(null),
      strokeDashArray: this.computed((shape) => {
        return shape.strokeStyle === 'dashed' ? [3, 3] : shape.strokeStyle === 'dotted' ? [1, 1] : [];
      }),
      strokeStyle: this.string('solid'),
      stroked: this.computed((shape) => !!shape.strokeWidth),
      isTool: this.boolean(false),
      hasArrow: this.number(0),
      closed: this.boolean(true),
    };
  }

  static hidden() {
    return [...super.hidden(), 'isTool'];
  }

  getPath() {
    return computed(() => {
      if (!this.points.length) return 'M0 0';

      return this.points.reduce((d: string, p: Point) => `${d} ${p.x} ${p.y}`, 'M') + (this.closed ? 'z' : '');
    });
  }

  generateCornerPoints() {
    return bboxPoints(this.points).map((p: Point) => p.transform(this.transform)) as [Point, Point, Point, Point];
  }

  generateUntransformedCornerPoints() {
    return bboxPoints(this.points);
  }

  addPoint(p: Point, fix = false) {
    // p.shapeId = this.$id as string
    p.fixed = fix;
    // p.save()
    this.points.push(p.round());
    this.emit('update:points');
    return p;
  }

  getLastPoint() {
    return this.points[this.points.length - 1];
  }

  initDrawing(cssMatrix: Ref<Matrix>) {
    this.matrix = cssMatrix;
    document.addEventListener('keydown', this.onKeyDown, { capture: true });
  }

  onMouseDown(ev: MouseEvent): any {
    const p = getPointFromEvent(ev, this.matrix?.value, true);
    return this.addPoint(p, true);
  }

  onMouseMove(ev: MouseEvent): any {
    const p = getPointFromEvent(ev, this.matrix?.value, true);
    const last = this.getLastPoint();
    this.hasMoved = true;

    if (!last || last.fixed) {
      this.addPoint(p, false);
      return;
    }

    Object.assign(last, { x: p.x, y: p.y }).round();
    this.emit('update:points');
  }

  onMouseUp(ev: MouseEvent): any {
    this.onMouseMove(ev);

    // const last = this.getLastPoint()

    // if (last.fixed) {
    //   return this.addPoint(p, true)
    // }

    // Object.assign(last, { x: p.x, y: p.y, fixed: true })
  }

  cancelDrawing() {
    document.removeEventListener('keydown', this.onKeyDown, { capture: true });
    this.delete();
    this.emit('canceled');
  }

  onMouseLeave() {
    const last = this.getLastPoint();
    if (!last?.fixed) this.points.splice(this.points.length - 1, 1);
  }

  onMouseEnter() {
    //
  }

  finishShape() {
    const lastPoint = this.getLastPoint();
    if (!lastPoint?.fixed) {
      this.points.pop();
    }

    this.finished = true;
    delete this.matrix;
    document.removeEventListener('keydown', this.onKeyDown, { capture: true });
    this.emit('finished');
  }

  onKeyDown = (ev: KeyboardEvent) => {
    const method = `on${capitalize(ev.key)}`;

    if ((this as any)[method]) {
      return (this as any)[method]();
    }
  };

  onEnter() {
    this.finishShape();
  }

  onEscape() {
    this.cancelDrawing();
  }

  onBackspace() {
    let last = this.getLastPoint();

    if (!last?.fixed) {
      last = this.points[this.points.length - 2];
    }

    this.points.splice(this.points.length - 1, 1);
  }
}

Shape.boot();
