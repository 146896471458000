import { Point, Shape } from '../models';
import { Action } from './';

type PointLike = { x: number; y: number };
type NodeMovePointsByOffsetActionObjects = { node: Shape };
type NodeMovePointsByOffsetActionArgs = [indexes: number[], offset: PointLike];
export class NodeMovePointsByOffsetAction extends Action<
  NodeMovePointsByOffsetActionObjects,
  NodeMovePointsByOffsetActionArgs
> {
  static actionName = 'NodeMovePointsByOffsetAction';
  mergable = true;

  async redo({ node }: NodeMovePointsByOffsetActionObjects, indexes: number[], offset: PointLike) {
    indexes.forEach((index) => {
      const point = node.points[index];
      node.points[index] = new Point({
        x: point.x + offset.x,
        y: point.y + offset.y,
      });
    });
  }

  merge([i1, { x: x2, y: y2 }]: NodeMovePointsByOffsetActionArgs): NodeMovePointsByOffsetActionArgs {
    const [i2, { x: x1, y: y1 }] = this.args;
    return [[...new Set([...i1, ...i2])], { x: x1 + x2, y: y1 + y2 }];
  }
}
