<script lang="ts">
import { computed } from 'vue';
import { Node, Point, Baseline, Slide } from '../../models';

export default {
  name: 'NodeSelection',
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const node = computed(() => Node.get(props.id) as Baseline);

    const points = computed(() => {
      // We have to access p.x and p.y to add them as a dependency because transform will clone the point before transforming it
      // Which will NOT access the attributes and therefore not adding them as dependency
      // eslint-disable-next-line no-sequences
      return node.value.points.map(
        (p: Point) => (
          p.x,
          p.y,
          p
            .transform(node.value.transform)
            .transformO((node.value.parent as Slide).ctm)
            .transformO(node.value.parent.board.transform2)
        ),
      );
    });

    const onPointerDownLine = node.value.onLineMove.bind(node.value);
    const onPointerDownRect = node.value.onPointMove.bind(node.value);

    return {
      points,
      onPointerDownLine,
      onPointerDownRect,
      node,
    };
  },
};
</script>

<style scoped>
svg {
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
}

line:hover {
  cursor: pointer;
  stroke: rgba(100, 100, 100, 0.5);
  stroke-dashoffset: 0;
}

rect {
  width: 8px;
  height: 8px;
  stroke: black;
  fill: white;
}

rect:hover {
  fill: gray;
}
</style>

<template>
  <svg>
    <template v-for="(point, index) in points">
      <line
        v-if="points[index + 1]"
        :key="points[index]._id"
        :x1="points[index].x"
        :x2="points[index + 1].x"
        :y1="points[index].y"
        :y2="points[index + 1].y"
        stroke-width="8"
        stroke="transparent"
        class="dummy"
        @pointerdown.stop.prevent="onPointerDownLine($event, index)"
      />
    </template>

    <line
      v-if="points[1]"
      :key="points[points.length - 1]._id"
      :x1="points[points.length - 1].x"
      :x2="points[0].x"
      :y1="points[points.length - 1].y"
      :y2="points[0].y"
      stroke-width="8"
      stroke="transparent"
      class="dummy"
      @pointerdown.stop.prevent="onPointerDownLine($event, points.length - 1)"
    />

    <template v-for="(point, index) in points" :key="points[index]._id + 'rect'">
      <rect
        :x="points[index].x - 4"
        :y="points[index].y - 4"
        @pointerdown.stop.prevent="onPointerDownRect($event, index)"
      />
    </template>
  </svg>
</template>
