import { Baseline } from '../../models';

export class Line extends Baseline {
  static model = 'Line' as const;
  static base = Baseline;

  static fields() {
    return {
      ...Baseline.fields(),
      type: this.string('Line'),
      closed: this.boolean(false),
      fillColor: this.string('none'),
      strokeColor: this.string('#000000'),
      filled: this.boolean(false),
      stroked: this.boolean(true),
    };
  }
}

Line.boot();
