<template>
  <button
    ref="button"
    class="border rounded px-1 py-0.5 border-[##3B34D] bg-[#e3c54e] enabled:hover:bg-[#E3D74D] enabled:active:bg-[#CDE34D] disabled:text-gray-400 text-white font-bold"
  >
    <slot />
  </button>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const button = ref<HTMLButtonElement>();

defineExpose({ focus: () => button.value?.focus() });
</script>
