import { Board } from '../models';

export async function saveAsJson(board: Board, indexes: number[] = []) {
  if (indexes.length === 0) {
    indexes = Array.from(Array(board.slides.length).keys());
  }

  const slides = board.slides.filter((_, i) => indexes.includes(i));
  const obj = { slides: slides.map((s) => s.dehydrateAsCopy()), version: board.version };

  return 'data:application/json;base64,' + btoa(unescape(encodeURIComponent(JSON.stringify(obj, null, 2))));
}

export function saveAs(board: Board, indexes: number[] = [], type: 'json' | 'pdf' | 'png' = 'json') {
  saveFns[type](board, indexes).then((dataUrl) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = dataUrl;
    // the filename you want
    a.download = board._id + '.' + type;
    document.body.appendChild(a);
    a.click();
    a.remove();
  });
}

export async function saveAsPdf(board: Board, indexes: number[] = []) {
  const slides = await board.getSVGNodes(indexes, true);
  const { jsPDF: PDF } = await import('jspdf');
  await import('@/third-party/svg2pdf.es.js');

  const doc = new PDF({ orientation: 'landscape', unit: 'px', format: [1000, 700], hotfixes: ['px_scaling'] });

  for (let i = 0; i < slides.length; i++) {
    if (i) {
      doc.addPage();
    }
    await doc.svg(slides[i]!, { x: 0, y: 0, width: 1000, height: 700 });
  }

  return doc.output('dataurlstring', { filename: board._id + '.pdf' });
}

export async function saveAsPng(board: Board, indexes: number[] = []) {
  if (indexes.length === 0) {
    throw new Error('No indexes provided');
  }

  const slides = await board.getSVGNodes(indexes, true);
  const slide = slides[0]!;

  // get svg data
  const xml = new XMLSerializer().serializeToString(slide);

  const dataUrl = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(xml)));

  const img = document.createElement('img');
  const canvas = document.createElement('canvas');
  // canvas.width = slide.width.baseVal.value
  // canvas.height = slide.height.baseVal.value

  canvas.width = 1000;
  canvas.height = 700;

  return new Promise<string>((resolve, reject) => {
    img.onload = () => {
      canvas.getContext('2d')?.drawImage(img, 0, 0, 1000, 700);
      resolve(canvas.toDataURL('image/png'));
    };
    img.onerror = reject;
    img.src = dataUrl;
  });
}

const saveFns = {
  json: saveAsJson,
  pdf: saveAsPdf,
  png: saveAsPng,
};
