import { Node } from '../models';
import { Action } from './';

type NodesToBackActionObjects = { nodes: Node[] };
export class NodesToBackAction extends Action<NodesToBackActionObjects> {
  static actionName = 'NodesToBackAction';

  async redo({ nodes }: NodesToBackActionObjects) {
    nodes.forEach((node) => {
      const index = node.index();

      if (!index) return;

      const prev = node.prev();

      node.parent.order.splice(index - 1, 2, node._id, prev._id);
    });
  }
}
