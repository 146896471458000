import { Base64 } from 'js-base64';

// const base64map = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

// function bigIntToBase64 (number: bigint) {
//   const elements = []
//   let value = number

//   while (value > 0n) {
//     elements.push(base64map[Number(value % 64n)])
//     value = value / 64n
//   }

//   return elements.reverse().join('')
// }

function base16ToUint8Array(hex: string) {
  const byteLength = hex.length / 2;

  // const buf = new ArrayBuffer(byteLength);
  const buf = new Uint8Array(byteLength);

  for (let i = 0; i < byteLength; ++i) {
    const int = parseInt(hex.charAt(i * 2) + hex.charAt(i * 2 + 1), 16);
    buf[i] = int;
  }

  return buf;
}

export function base16To64(hex: string) {
  return Base64.fromUint8Array(base16ToUint8Array(hex));
}
