import { ShapeSaveAction } from '../actions';
import { getPointFromEvent } from '../utils/utils';
import { Slide, Image } from '../models';

export function useFiles() {
  const handleImages = (files: File[], ev: DragEvent, slide: Slide) => {
    if (!files.length) return;

    const p = getPointFromEvent(ev, slide.screenCtm.inverse());

    // const formData = new FormData()

    // files.forEach(file => {
    //   formData.append('image', file)
    // })

    // const config = {
    //   onUploadProgress: function (ev: ProgressEvent) {
    //     const percentCompleted = Math.round((ev.loaded * 100) / ev.total)
    //     console.log(percentCompleted)
    //   },
    //   headers: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }

    // axios.post('upload', formData, config)

    files.forEach((file) => {
      const f = new FileReader();
      f.onload = async () => {
        const dataUrl = f.result as string;

        const image = await Image.createWithOrWithoutPosition(dataUrl, slide, p);

        new ShapeSaveAction().execute({ node: image, slide });
      };

      f.readAsDataURL(file);
    });
  };

  return { handleImages };
}
