<template>
  <svg v-if="points.length" xmlns:xhtml="http://www.w3.org/1999/xhtml">
    <line
      :x1="points[0].x"
      :x2="points[1].x"
      :y1="points[0].y"
      :y2="points[1].y"
      stroke-width="8"
      stroke="transparent"
      class="dummy"
      @pointerdown.stop.prevent="onPointerDownLine($event, 0)"
    />
    <line
      :x1="points[0].x"
      :x2="points[1].x"
      :y1="points[0].y"
      :y2="points[1].y"
      sroke-width="1"
      stroke="black"
      stroke-dasharray="5 5"
    />

    <line
      :x1="points[1].x"
      :x2="points[2].x"
      :y1="points[1].y"
      :y2="points[2].y"
      stroke-width="8"
      stroke="transparent"
      class="dummy"
      @pointerdown.stop.prevent="onPointerDownLine($event, 1)"
    />
    <line
      :x1="points[1].x"
      :x2="points[2].x"
      :y1="points[1].y"
      :y2="points[2].y"
      sroke-width="1"
      stroke="black"
      stroke-dasharray="5 5"
    />

    <line
      :x1="points[2].x"
      :x2="points[3].x"
      :y1="points[2].y"
      :y2="points[3].y"
      stroke-width="8"
      stroke="transparent"
      class="dummy"
      @pointerdown.stop.prevent="onPointerDownLine($event, 2)"
    />
    <line
      :x1="points[2].x"
      :x2="points[3].x"
      :y1="points[2].y"
      :y2="points[3].y"
      sroke-width="1"
      stroke="black"
      stroke-dasharray="5 5"
    />

    <line
      :x1="points[3].x"
      :x2="points[0].x"
      :y1="points[3].y"
      :y2="points[0].y"
      stroke-width="8"
      stroke="transparent"
      class="dummy"
      @pointerdown.stop.prevent="onPointerDownLine($event, 3)"
    />
    <line
      :x1="points[3].x"
      :x2="points[0].x"
      :y1="points[3].y"
      :y2="points[0].y"
      sroke-width="1"
      stroke="black"
      stroke-dasharray="5 5"
    />

    <template v-if="node.finished">
      <rect :x="points[0].x - 4" :y="points[0].y - 4" @pointerdown.stop.prevent="onPointerDownRect($event, 0)" />
      <rect :x="points[1].x - 4" :y="points[1].y - 4" @pointerdown.stop.prevent="onPointerDownRect($event, 1)" />
      <rect :x="points[2].x - 4" :y="points[2].y - 4" @pointerdown.stop.prevent="onPointerDownRect($event, 2)" />
      <rect :x="points[3].x - 4" :y="points[3].y - 4" @pointerdown.stop.prevent="onPointerDownRect($event, 3)" />

      <g class="cursor-move" @pointerdown.stop.prevent="onPointerDownRotate" @dblclick="node.resetRotation()">
        <circle :cx="rotationPoint.x" :cy="rotationPoint.y" r="10" fill="transparent" />
        <MdiRotateRight :x="rotationPoint.x - 10" :y="rotationPoint.y - 10" width="20" height="20" />
      </g>
    </template>
  </svg>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Node, Point } from '../../models';
import MdiRotateRight from '~icons/mdi/rotate-right';

const props = defineProps<{ id: string }>();

const node = Node.get(props.id)!;

const points = computed(() => {
  const slide = node.parent;
  return node.generateCornerPoints().map((p) => p.transformO(node.parent.ctm).transformO(slide.board.transform2)) as [
    Point,
    Point,
    Point,
    Point,
  ];
});

const rotationPoint = computed(() => {
  const [p1, p2] = points.value;

  const normal = p1.normal(p2).norm();
  return new Point(p1.x + (p2.x - p1.x) / 2 + normal.x * 16, p1.y + (p2.y - p1.y) / 2 + normal.y * 16);
});

const onPointerDownLine = node.onScale.bind(node);
const onPointerDownRect = node.onScaleCorner.bind(node);
const onPointerDownRotate = node.onRotate.bind(node);
</script>

<style scoped>
svg {
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
}

.dummy:hover {
  cursor: pointer;
  stroke: rgba(100, 100, 100, 0.5);
  stroke-dashoffset: 0;
}

.dummy + line {
  animation: 1s infinite linear stroke;
}

rect {
  width: 8px;
  height: 8px;
  stroke: black;
  fill: white;
}

rect:hover {
  fill: gray;
}

@keyframes stroke {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 10;
  }
}
</style>
