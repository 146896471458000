<template>
  <path
    :id="htmlId(shape._id)"
    v-bind="$attrs"
    :d="path"
    :stroke="shape.stroked ? shape.strokeColor : undefined"
    :stroke-width="shape.stroked ? shape.strokeWidth : undefined"
    :stroke-opacity="shape.filled ? undefined : shape.fillOpacity"
    :stroke-dasharray="shape.stroked ? shape.strokeDashArray.map((x) => x * shape.strokeWidth).join(',') : undefined"
    :fill="shape.filled ? shape.fillColor : 'none'"
    :fill-opacity="shape.filled ? shape.fillOpacity : undefined"
    stroke-linejoin="round"
    :class="shape.isTool ? 'tool' : 'shape'"
    vector-effect="non-scaling-stroke"
    :marker-end="shape.closed || !shape.hasArrow ? undefined : markerWidth"
    :marker-start="shape.closed || shape.hasArrow !== 2 ? undefined : markerWidth"
  >
    <slot />
  </path>
</template>

<script lang="ts">
import { htmlId } from '../utils/utils';
import { Shape } from '../models';
import { computed } from 'vue';

export default {
  name: 'Shape',
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const shape = Shape.get(props.id) as Shape;
    const path = shape.getPath();

    const markerWidth = computed(() => {
      if (shape.strokeWidth == 1) {
        return 'url(#marker-arrow-head-10)';
      } else if (shape.strokeWidth == 2) {
        return 'url(#marker-arrow-head-6)';
      } else if (shape.strokeWidth === 3 || shape.strokeWidth === 4) {
        return 'url(#marker-arrow-head-4)';
      } else if (shape.strokeWidth === 5 || shape.strokeWidth === 6) {
        return 'url(#marker-arrow-head-3.5)';
      } else if (shape.strokeWidth >= 7 && shape.strokeWidth <= 12) {
        return 'url(#marker-arrow-head-3)';
      } else {
        return 'url(#marker-arrow-head-2.75)';
      }
    });

    return {
      shape,
      htmlId,
      path,
      markerWidth,
    };
  },
};
</script>

<style scoped>
.tool {
  animation: 1s infinite linear stroke;
}

.shape {
  pointer-events: fill;
}

@keyframes stroke {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 10;
  }
}
</style>
