import { Key, Dehydrate, modelRegistry, Model as BaseModel } from 'vue-model';
import { User, Model } from '../models';
import {
  UpdateChangeSet,
  ChangeSet,
  ChangeSetNonOptional,
  ChangeType,
  CREATE,
  DELETE,
  MessageAction,
  MessageRedo,
  MessageUndo,
  UPDATE,
  UpdateStep,
} from '../utils/WebSocketMessage';
import { history } from '../History';
import { v4 } from 'uuid';
import { cleanUpChangeSet, getObjByPath } from '../utils/utils';

const noop = async (): Promise<any> => undefined;
function wrapArray<T extends unknown>(valOrArray: T | T[]): T[] {
  return Array.isArray(valOrArray) ? valOrArray : [valOrArray];
}

const sameOjects = (a: Record<string, Model | Model[]>, b: Record<string, Model | Model[]>) => {
  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (!keysA.every((a) => keysB.includes(a) || !keysB.every((b) => keysA.includes(b)))) {
    return false;
  }

  for (const key of keysA) {
    const ms = wrapArray(a[key]).map((m) => m.$id);
    const ns = wrapArray(b[key]).map((m) => m.$id);

    if (!ms.every((m) => ns.includes(m) || !ns.every((n) => ms.includes(n)))) {
      return false;
    }
  }

  return true;
};

// export type ActionPayloadObjects<T, K extends keyof T = keyof T> = Record<K, Record<string, unknown> | Record<string, unknown>[]>
// export type ActionPayloadObjects<T extends ActionObjects, K extends keyof T = keyof T> = Record<K, T[K] extends Array<any> ? Record<string, unknown>[] : Record<string, unknown>>
export type ActionObjects = Record<string, Model | Model[]>;
export type ActionJsonObjects<T extends ActionObjects> = {
  [P in keyof T]: T[P] extends any[] ? Record<string, unknown>[] : Record<string, unknown>;
};

export type ActionArgs = Array<any>;
export class Action<T extends ActionObjects = ActionObjects, K extends Array<any> = ActionArgs> {
  ['constructor']!: typeof Action;

  static actionName: string;
  static subscriber: { emit: (event: string, ...args: any[]) => any; user: User } = history();
  childActions: Action[] = [];
  objects!: T;
  args!: K;
  undoCb = noop;
  objectStore: Record<string, Map<Key, Model>> = {};
  getUserId: () => string;
  mergable = false;
  started = false;
  executed = false;
  id: string;
  history = true;
  // changeSet = {} as Record<string, {[CREATE]: Set<string>, [UPDATE]: Record<string, Record<string, unknown[]>>, [DELETE]: Set<string>}>
  changeSet = {} as ChangeSet;
  proxies!: T;
  noHistory = false;
  slideId!: string;
  boardId!: string;
  proxyCache: Map<any, any>;
  status: 'pending' | 'success' | 'error' | 'undo' | 'redo' = 'pending';
  seq = 0;
  solidified: UpdateChangeSet = {};

  constructor(userId?: string, id = v4()) {
    this.getUserId = () => {
      if (!userId) return history().user._id;
      return userId;
    };
    this.id = id;
    this.proxyCache = new Map<any, any>();
  }

  start() {
    console.log('Action', this.constructor.actionName, 'was started');
    this.slideId = history().getActiveBoard()?.currentSlide?._id as string;
    this.history && history().emit('start', this, this.getUserId());
    this.started = true;
    return this;
  }

  stop() {
    console.log('Action', this.constructor.actionName, 'was stopped');
    console.log(
      'The changeSet holds this:',
      JSON.parse(JSON.stringify(this.changeSet)),
      JSON.stringify(this.changeSet).length,
      this.solidified,
    );
    this.history && history().emit('stop', this, this.getUserId());
    return this;
  }

  cancel() {
    console.log('Action', this.constructor.actionName, 'was canceled');
    this.history && history().emit('cancel', this, this.getUserId());
    return this;
  }

  // Not used yet
  abort() {
    throw new Error('Action aborted');
  }

  getObjectProxy(obj: Record<string, unknown> | any[], model: BaseModel, key1: string): typeof obj {
    if ((obj as any).__isProxy) {
      return obj;
    }

    if (this.proxyCache.has(obj)) {
      return this.proxyCache.get(obj);
    }

    const proxy = new Proxy(obj, {
      set: (target, key2, value, receiver) => {
        if (typeof key2 === 'symbol') {
          return Reflect.set(model, key2, value, receiver);
        }

        if (key2 === 'length' && Array.isArray(target)) {
          return Reflect.set(model, key2, value, receiver);
        }

        const key = [key1, key2].join('.');

        const store = this.ensureChangeSet(UPDATE, model, key);
        const store2 = this.ensureChangeSet2(model);

        const lastUpdateStep = store[store.length - 1];
        const lastUpdateStep2 = store2[store2.length - 1];

        if (lastUpdateStep && lastUpdateStep[0] === '$set' && lastUpdateStep[1] === key) {
          lastUpdateStep[2] = value;
          lastUpdateStep2[2] = value;
        } else {
          store.push(['$set', key, value, (target as any)[key2]]);
          store2.push(['$set', key, value, (target as any)[key2]]);
        }

        if (JSON.stringify(store[store.length - 1][2]) === JSON.stringify(store[store.length - 1][3])) {
          store.pop();
          store2.pop();
        }

        return Reflect.set(target, key2, value, target);
      },
      // deleteProperty: (target, key) => {
      //   console.log(target, key)

      //   if (typeof key === 'symbol') {
      //     return Reflect.deleteProperty(target, key)
      //   }

      //   const store = this.ensureChangeSet(UPDATE, model, key)
      //   if (Array.isArray(target)) {
      //     store.push([ '$unsetItem', key, target[+key] ])
      //   } else {
      //     store.push([ '$unset', key, target[key] ])
      //   }
      //   return Reflect.deleteProperty(target, key)
      // },
      get: (target, key2, receiver) => {
        if (typeof key2 === 'symbol') {
          return Reflect.get(model, key2, receiver);
        }

        if (key2 === '__isProxy') return true;

        const key = [key1, key2].join('.');

        const val = Reflect.get(target, key2, target);

        if (!val) return val;

        if (Array.isArray(target)) {
          switch (key2) {
            case 'push':
              return (...args: any[]) => {
                const store = this.ensureChangeSet(UPDATE, model, key1);
                store.push(['$add', key1, args]);
                const store2 = this.ensureChangeSet2(model);
                store2.push(['$set', key1 + '.length', target.length + args.length, target.length]);
                args.forEach((arg, index) => {
                  store2.push(['$set', key1 + '.' + (target.length + index), arg]);
                });
                return Reflect.apply(target[key2], target, args);
              };
            case 'pop':
              return () => {
                const store = this.ensureChangeSet(UPDATE, model, key1);
                store.push(['$delete', key1, target[target.length - 1]]);
                const store2 = this.ensureChangeSet2(model);
                store2.push(['$set', key1 + '.' + (target.length - 1), undefined, target[target.length - 1]]);
                store2.push(['$set', key1 + '.length', target.length - 1, target.length]);
                return Reflect.apply(target[key2], target, []);
              };
            case 'shift':
              return () => {
                const store = this.ensureChangeSet(UPDATE, model, key1);
                store.push(['$delete', key1, target[0], 0]);
                const store2 = this.ensureChangeSet2(model);
                for (let i = 0; i < target.length; i++) {
                  store2.push(['$set', key1 + '.' + i, target[i + 1], target[i]]);
                }
                store2.push(['$set', key1 + '.length', target.length - 1, target.length]);
                return Reflect.apply(target[key2], target, []);
              };
            case 'unshift':
              return (...args: any[]) => {
                const store = this.ensureChangeSet(UPDATE, model, key1);
                store.push(['$add', key1, args, 0]);
                const store2 = this.ensureChangeSet2(model);
                const newArr = [...args, ...target];
                store2.push(['$set', key1 + '.length', newArr.length, target.length]);
                for (let i = 0; i < newArr.length; i++) {
                  store2.push(['$set', key1 + '.' + i, newArr[i], target[i]]);
                }
                return Reflect.apply(target[key2], target, args);
              };
            case 'splice':
              return (index: number, howMany: number, ...args: any[]) => {
                const store = this.ensureChangeSet(UPDATE, model, key1);
                const store2 = this.ensureChangeSet2(model);
                const copy = [...target];
                const deleted = Reflect.apply(target[key2], target, [index, howMany, ...args]);
                const newArr = [...copy.slice(0, index), ...args, ...copy.slice(index + deleted.length)];

                if (deleted.length) {
                  store.push(['$delete', key1, deleted, index]);
                }
                if (args.length) {
                  store.push(['$add', key1, args, index]);
                }

                if (newArr.length > copy.length) {
                  store2.push(['$set', key1 + '.length', newArr.length, copy.length]);
                }

                const mostLength = Math.max(copy.length, newArr.length);
                for (let i = index; i < mostLength; i++) {
                  if (newArr[i] !== copy[i]) {
                    store2.push(['$set', key1 + '.' + i, newArr[i], copy[i]]);
                  }
                }

                if (newArr.length < copy.length) {
                  store2.push(['$set', key1 + '.length', newArr.length, copy.length]);
                }

                return deleted;
              };
          }
        }

        if (typeof val === 'object') {
          if (val instanceof Model) {
            return this.createProxy(val);
          }

          return this.getObjectProxy(val, model, key);
        }

        return val;
      },
    });

    this.proxyCache.set(obj, proxy);
    return proxy;
  }

  createProxy(model: Model): Model {
    // In case model was already proxified (throuh a parent action)
    // we dont need to do it again
    if ((model as any).__isProxy) {
      return model;
    }

    if (this.proxyCache.has(model)) {
      return this.proxyCache.get(model);
    }

    const hidden = model.static().hidden();

    const proxy = new Proxy(model, {
      set: (model, key, value, receiver) => {
        if (typeof key === 'symbol' || hidden.includes(key)) {
          return Reflect.set(model, key, value, receiver);
        }

        const store = this.ensureChangeSet(UPDATE, model, key);
        const store2 = this.ensureChangeSet2(model);

        const lastUpdateStep = store[store.length - 1];
        const lastUpdateStep2 = store2[store2.length - 1];

        if (lastUpdateStep && lastUpdateStep[0] === '$set' && lastUpdateStep[1] === key) {
          lastUpdateStep[2] = value;
          lastUpdateStep2[2] = value;
        } else {
          store.push(['$set', key, value, (model as any)[key]]);
          store2.push(['$set', key, value, (model as any)[key]]);
        }

        if (JSON.stringify(store[store.length - 1][2]) === JSON.stringify(store[store.length - 1][3])) {
          store.pop();
          store2.pop();
        }

        return Reflect.set(model, key, value, receiver);
      },

      get: (model, key, receiver) => {
        if (typeof key === 'symbol') {
          return Reflect.set(model, key, receiver);
        }

        if (key === '__isProxy') return true;

        const val = Reflect.get(model, key, receiver);

        if (!val) return val;

        if (typeof val === 'object' && key !== '_cache' && key !== '_foreignKeyCache' && key !== '_events') {
          if (val instanceof Model) {
            return this.createProxy(val);
          }

          return this.getObjectProxy(val, model, key);
        }

        return val;
      },
    });

    this.proxyCache.set(model, proxy);
    return proxy;
  }

  getProxy(objects: T): T {
    return Object.fromEntries(
      Object.entries(objects).map(([propName, model]) => {
        if (Array.isArray(model)) {
          return [propName, model.map((m) => this.createProxy(m))];
        } else {
          return [propName, this.createProxy(model)];
        }
      }),
    );
  }

  ensureChangeSet2(model: BaseModel) {
    let base = model.static();

    while (base.base) {
      base = base.base as typeof BaseModel;
    }

    const modelName = base.model;
    const id = model.$id as string;
    const set = this.solidified;

    set[modelName] = set[modelName] || {};
    const store = (set[modelName][id] = set[modelName][id] || []);
    return store;
  }

  ensureChangeSet(type: typeof CREATE, model: BaseModel): Record<string, unknown>;
  ensureChangeSet(type: typeof DELETE, model: BaseModel): Record<string, unknown>;
  ensureChangeSet(type: typeof UPDATE, model: BaseModel, prop: string): UpdateStep[];
  ensureChangeSet(type: ChangeType, model: BaseModel, _prop?: string) {
    let base = model.static();

    while (base.base) {
      base = base.base as typeof BaseModel;
    }

    const modelName = base.model;
    const id = model.$id as string;
    const set = this.changeSet as ChangeSetNonOptional;

    set[modelName] = set[modelName] || {};
    set[modelName][type] = (set[modelName][type] || {}) as any;
    const store = (set[modelName][type][id] = set[modelName][type][id] || (type === UPDATE ? [] : {}));

    // if (prop) {
    //   store[prop] = store[prop] || []

    //   Object.keys(store).filter((key) => key.startsWith(prop + '.')).forEach((key) => {
    //     delete store[key]
    //   })
    // }
    return store;
  }

  delete(model: BaseModel) {
    const store = this.ensureChangeSet(DELETE, model);
    Object.assign(store, model.dehydrate([]));
  }

  // TODO: Get cascades and create every related model as well
  create(model: Model) {
    const store = this.ensureChangeSet(CREATE, model);
    Object.assign(store, JSON.parse(JSON.stringify(model.dehydrate([]))));
  }

  async execute(objects: T, ...args: K) {
    // We simulate an action chain for the rare case that someone wants to use
    // an action in an action
    let single = false;
    if (!this.started) {
      this.start();
      single = true;
    }

    if (this.executed) {
      this.args = this.merge(args);
    } else {
      this.objects = objects;
      this.proxies = this.getProxy(objects);
      this.args = args;
      // this.boardId = history().getActiveBoard()._id
    }

    const promise = this.redo(this.proxies, ...args).catch((e) => {
      console.error(e);
      // Ignore canceled actions
    });

    return promise.then(() => {
      if (!this.executed) {
        console.log('Action', this.constructor.actionName, 'is executed');
      }

      this.executed = true;

      // In case the action chain was simulated
      // We have to stop once we executed the action
      if (single) {
        this.stop();
      }
    });
  }

  async redo(_objects: T, ..._args: K) {
    // abstract
    throw new Error('This method is abstract');
  }

  async undo() {
    return this.undoCb();
  }

  executeUndo() {
    Action.applyChangeSet(this.changeSet as ChangeSetNonOptional, true);
  }

  executeRedo() {
    Action.applyChangeSet(this.changeSet as ChangeSetNonOptional);
  }

  static applyChangeSet(changeSet: ChangeSetNonOptional, backwards = false) {
    Object.entries(changeSet).forEach(([modelName, changes]) => {
      const SomeModel = modelRegistry.get(modelName);

      if (!SomeModel) {
        console.error(
          'Error applying changeset. The model',
          modelName,
          'was not found in the registry. Changes:',
          changes,
        );
        return;
      }

      if (changes[backwards ? DELETE : CREATE]) {
        Object.values(changes[backwards ? DELETE : CREATE]).forEach((m) => {
          Model.hydrate(m as Dehydrate<typeof Model>);
        });
      }

      if (changes[UPDATE]) {
        Object.entries(changes[UPDATE]).forEach(([id, steps]) => {
          const model = SomeModel.get(id);

          if (!model) {
            console.error(
              'Error applying changeset. The model',
              modelName,
              'with id',
              id,
              'was not found. Updates:',
              steps,
            );
            return;
          }

          if (backwards) {
            steps = steps.slice().reverse();
          }

          steps.forEach(([cmd, key, value, oldValue]) => {
            if (cmd !== '$set') {
              console.error('Update step is not count. Abort!', [cmd, key, value, oldValue]);
            }

            const path = key.split('.');
            const prop = path.pop() as string;
            const obj = getObjByPath(model, path);

            obj[prop] = backwards ? oldValue : value;
          });
        });
      }

      if (changes[backwards ? CREATE : DELETE]) {
        Object.keys(changes[backwards ? CREATE : DELETE]).forEach((id) => {
          SomeModel.get(id)?.delete(false);
        });
      }
    });
  }

  merge(_args: K): K {
    return [] as unknown as K;
  }

  add(action: Action) {
    function isMergable(action1: Action<T, K>, action2: Action): action2 is Action<T, K> {
      return (
        action1.mergable &&
        action2.mergable &&
        action1.constructor === action2.constructor &&
        sameOjects(action1.proxies, action2.proxies)
      );
    }

    // If same action with same objects, merge action together
    if (this.proxies && action.proxies && isMergable(this, action)) {
      // action.constructor === this.constructor && sameOjects(action.objects, this.objects) && action.mergable) {
      this.args = this.merge(action.args);
      return;
    }

    this.childActions.push(action);

    // Link the objectStore together so that everything is saved on the outermost action
    action.objectStore = this.objectStore;
    action.changeSet = this.changeSet;
    action.history = this.history;
    action.solidified = this.solidified;
  }

  reset() {
    this.args = [] as unknown as K;
    this.executed = false;
    return this;
  }

  toWSMessage() {
    cleanUpChangeSet(this.changeSet);
    this.seq = history().getActiveBoard().seq;
    return {
      type: 'action',
      changeSet: this.changeSet,
      id: this.id,
      noHistory: this.noHistory,
      slideId: history().getActiveBoard().currentSlide?._id,
      seq: this.seq,
    } as MessageAction;
  }

  static fromWSMessage(payload: MessageAction) {
    const a = new Action('remote', payload.id);
    a.changeSet = payload.changeSet;
    a.noHistory = payload.noHistory ?? false;
    a.slideId = payload.slideId;
    a.seq = payload.seq;
    a.status = 'success';
    return a;
  }

  static fromChangeSet(changeSet: ChangeSetNonOptional) {
    const a = new Action('remote', v4());
    a.changeSet = changeSet;
    a.noHistory = true;
    return a;
  }

  toWSUndo() {
    return {
      type: 'undo',
      actionId: this.id,
      boardId: history().getActiveBoard()._id, // this.boardId,
      seq: history().getActiveBoard().seq,
    } as MessageUndo;
  }

  toWSRedo() {
    return {
      type: 'redo',
      actionId: this.id,
      boardId: history().getActiveBoard()._id, // this.boardId
      seq: history().getActiveBoard().seq,
    } as MessageRedo;
  }

  solidifyChangeSet() {
    Object.entries(this.solidified).forEach(([modelName, solidified]) => {
      this.changeSet[modelName][UPDATE] = solidified;
    });
  }
}
