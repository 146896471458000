import { Slide } from '../models';
import { Action } from '.';

export type SlideRenameActionObjects = { slide: Slide };
export type SlideRenameActionArgs = [name: string];
export class SlideRenameAction extends Action<SlideRenameActionObjects, SlideRenameActionArgs> {
  static actionName = 'SlideRenameAction';
  noHistory = true;

  async redo({ slide }: SlideRenameActionObjects, name: string) {
    slide.name = name;
  }
}
