<template>
  <div :class="{ disabled, active }" :title="title">
    <template v-if="icon">
      <i class="bx max-w-full max-h-full" :class="icon" v-if="typeof icon === 'string'" />
      <component :is="icon" v-else class="max-w-full max-h-full" />
    </template>
    <slot v-else />
  </div>
</template>

<script>
import { useBoard } from '../compositions/useBoard';
import { capitalize, computed } from 'vue';

function replaceUppercaseWithSpace(str) {
  return str.replace(/[A-Z]/g, ' $&');
}

export default {
  name: 'Icon',
  props: ['icon', 'group', 'value', 'active', 'disabled'],
  setup(props) {
    const board = useBoard();

    const title = computed(() => capitalize(replaceUppercaseWithSpace(props.value || '')));

    return {
      board,
      title,
    };
  },
};
</script>

<style scoped lang="scss">
.disabled {
  pointer-events: none;
  color: gray;
}
</style>
