import { Shape, Slide } from '../models';
import { Action } from './';

type ShapeSaveActionObjects = { node: Shape; slide: Slide };
export class ShapeSaveAction extends Action<ShapeSaveActionObjects> {
  static actionName = 'ShapeSaveAction';

  async redo({ node, slide }: ShapeSaveActionObjects) {
    this.create(node);

    slide.addNode(node);
  }
}
