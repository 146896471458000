<template>
  <Teleport v-if="modelValue" to="body">
    <div ref="menu" class="contextmenu" :style="position" @contextmenu.prevent>
      <template v-if="title">
        <div class="contextmenu-header">
          <strong>
            <span class="contextmenu-header-type">{{ title }}</span>
            <span v-if="subtitle" class="text-muted">{{ subtitle }}</span>
          </strong>
        </div>
        <div role="separator" class="divider" />
      </template>
      <div @click="noclose ? null : hide()">
        <slot :hide="hide" />
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
.contextmenu {
  border: 1px solid rgba(0, 0, 0, 0.15);
  width: max-content;
  position: absolute;
  background-color: white;
  background-clip: padding-box;
  color: #555;
  border-radius: 4px;
  z-index: 1000;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

:deep(.contextmenu-item) {
  padding: 6px 20px;
  cursor: pointer;
  display: flex;
  gap: 4px;
  align-items: center;
}

:deep(.contextmenu-item:hover) {
  background: rgba(0, 0, 0, 0.1);
  color: #000;
}

:deep(.disabled) {
  pointer-events: none;
  color: gray;
}

.contextmenu-item > i {
  margin-right: 15px;
}

.contextmenu-header-type {
  display: block;
}

.contextmenu-header {
  color: #ff7f2f;
  font-weight: bold;
  padding: 6px 12px;
}

.contextmenu-header .text-muted {
  color: #8b8b8b;
  font-size: 0.9em;
}

:deep(.divider) {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
</style>

<script lang="ts">
import { ref, watch } from 'vue';
const position = ref<{ left: string | null; top: string | null }>({ left: null, top: null });

document.addEventListener(
  'contextmenu',
  (ev: MouseEvent) => {
    if ((ev.target as HTMLElement).closest('.contextmenu')) return;

    position.value.left = ev.clientX + 'px';
    position.value.top = ev.clientY + 'px';
  },
  { capture: true },
);

export default {
  props: {
    modelValue: Boolean,
    title: String,
    subtitle: String,
    noclose: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    watch(
      () => props.modelValue,
      (newValue) => {
        if (newValue) {
          document.addEventListener('pointerdown', hideWithCheck, { capture: true });
          document.addEventListener('contextmenu', hideWithCheck, { capture: true });

          const box = menu.value?.getBoundingClientRect();

          if (box && box.right > window.innerWidth) {
            position.value.left = parseFloat(position.value.left as string) - box.width + 'px';
          }

          if (box && box.bottom > window.innerHeight) {
            position.value.top = parseFloat(position.value.top as string) - box.height + 'px';
          }
        } else {
          document.removeEventListener('pointerdown', hideWithCheck, { capture: true });
          document.removeEventListener('contextmenu', hideWithCheck, { capture: true });
        }
      },
      { flush: 'post' },
    );

    const hide = () => {
      context.emit('update:modelValue', false);
    };

    const hideWithCheck = (ev: MouseEvent) => {
      if (menu.value && !menu.value.contains(ev.target as HTMLElement)) {
        hide();
      }
    };

    const menu = ref<HTMLElement | null>(null);

    return {
      position,
      menu,
      hide,
    };
  },
};
</script>
