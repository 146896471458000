import { Ref } from 'vue';
import { Slide } from '../models';

export function useDragging(
  instance: Ref<Slide>,
  node: Ref<Node>,
  move: (x: number, y: number) => void,
  start?: () => void,
  stop?: (canceled?: boolean) => void,
) {
  // const onDrag = (ev: DragEvent) => {
  //   ev.preventDefault()
  // }

  // onMounted(() => {
  //   node.value.addEventListener('pointerdown', onPointerDown as EventListenerOrEventListenerObject, { capture: true })
  // })

  // onBeforeUnmount(() => {
  //   removeEvents()
  //   node.value.removeEventListener('pointerdown', onPointerDown as EventListenerOrEventListenerObject, { capture: true })
  //   node.value.removeEventListener('dragstart', onDrag as EventListenerOrEventListenerObject, { capture: true })
  // })

  // to satisfy typescript
  const ev0 = new MouseEvent('mousedown');
  let last = ev0;
  let sumX = 0;
  let sumY = 0;

  const removeEvents = () => {
    node.value.removeEventListener('pointermove', onPointerMove as EventListenerOrEventListenerObject);
    window.removeEventListener('pointerup', onPointerUp as EventListenerOrEventListenerObject, { capture: true });
    node.value.removeEventListener('pointerleave', onPointerLeave as EventListenerOrEventListenerObject);
    node.value.removeEventListener('pointerenter', onPointerEnter as EventListenerOrEventListenerObject);
  };

  const moveTransform = (x: number, y: number) => {
    move(x / instance.value.screenCtm.a, y / instance.value.screenCtm.a);
  };

  const onPointerMove = (ev: PointerEvent) => {
    moveTransform(ev.clientX - last.clientX, ev.clientY - last.clientY);
    sumX += ev.clientX - last.clientX;
    sumY += ev.clientY - last.clientY;
    last = ev;
  };

  const onPointerUp = () => {
    const cancel = !sumX && !sumY;
    sumX = 0;
    sumY = 0;
    last = ev0;
    removeEvents();
    stop?.(cancel);
  };

  const onPointerLeave = () => {
    moveTransform(-sumX, -sumY);
  };

  const onPointerEnter = () => {
    moveTransform(sumX, sumY);
  };

  const onPointerDown = (ev: PointerEvent) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (ev.type === 'mouse' && ev.button === 2) {
      if (last) {
        cancelDrag();
      }
      return;
    }

    start?.();

    last = ev;

    node.value.addEventListener('pointerleave', onPointerLeave as EventListenerOrEventListenerObject);
    node.value.addEventListener('pointerenter', onPointerEnter as EventListenerOrEventListenerObject);
    node.value.addEventListener('pointermove', onPointerMove as EventListenerOrEventListenerObject);
    window.addEventListener('pointerup', onPointerUp as EventListenerOrEventListenerObject, { capture: true });
  };

  const cancelDrag = () => {
    onPointerLeave();
    onPointerUp();
    stop?.(true);
  };

  return { cancelDrag, onPointerDown };
}
