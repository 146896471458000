import { Field } from 'vue-model';
import { Slide, Model, Participant } from '../models';

export class User extends Model {
  static model = 'User' as const;

  participants!: Participant;
  slides!: Slide;
  name!: string;
  moderator!: boolean;

  static fields(): Record<string, Field> {
    return {
      _id: this.uid(),
      participants: this.hasMany(Participant),
      name: this.string('Unknown'),
      moderator: this.boolean(false),
      // roles: this.belongsToMany(Role)
    };
  }
}

User.boot();
