<template>
  <Dialog v-model="show" :yes-or-no="yesNo" @ok="ok">
    <p>{{ msg }}</p>
  </Dialog>
</template>

<script lang="ts">
import Dialog from './Dialog.vue';
import { showConfirmationDialog, dialogMessage, yesNoConfirmation, dialogOk } from '../utils/utils';
export default {
  name: 'ConfirmDialog',
  components: { Dialog },
  setup() {
    const show = showConfirmationDialog;
    const msg = dialogMessage;
    const yesNo = yesNoConfirmation;

    const ok = (yesOrNo?: boolean) => {
      dialogOk(yesOrNo);
    };

    return {
      ok,
      show,
      msg,
      yesNo,
    };
  },
};
</script>
