import { Node } from '../models';
import { Action } from './';

type NodesMoveActionObjects = { nodes: Node[] };
type NodesMoveActionArgs = [x: number, y: number];
export class NodesMoveAction extends Action<NodesMoveActionObjects, NodesMoveActionArgs> {
  static actionName = 'NodesMoveAction';
  mergable = true;

  async redo({ nodes }: NodesMoveActionObjects, x: number, y: number) {
    nodes.forEach((node) => {
      node.transform.translateO(x, y);
    });
  }

  merge([x2, y2]: NodesMoveActionArgs): NodesMoveActionArgs {
    const [x1, y1] = this.args;
    return [x1 + x2, y1 + y2];
  }
}
