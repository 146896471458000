<script lang="ts">
import { Group, Node as NodeModel, Slide, Board } from '../models';
import Shape from './Shape.vue';
import Image from './Image.vue';
import Pointer from './Pointer.vue';
import Text from './Text.vue';
import { htmlId } from '../utils/utils';
// import { useDragging } from '../compositions/useDragging'
import { computed, Ref, ref, defineComponent } from 'vue';
import * as SelectionComponents from './selections';
import { history } from '../History';

export default defineComponent({
  name: 'Node',
  components: {
    Shape,
  },
  props: {
    id: { type: String, required: true },
    frame: { type: HTMLDivElement },
  },
  setup(props) {
    // FIXME: Nodes stay selected even when they are remotely grouped
    // Move "selected" out of node and to Slide or Board
    const node = NodeModel.get(props.id) as NodeModel;
    const nodeRef: Ref<Node | null> = ref(null);

    // const frame = computed(() => props.frame)
    const matrix = computed(() => node.transform.toString());

    const getComponent = (node: NodeModel) => {
      if (node.static().model === 'Image') return Image;
      if (node.static().model === 'Pointer') return Pointer;
      if (node.static().model === 'Text') return Text;
      return Shape;
    };

    const hasWBControl = () => {
      return node.board.localParticipant.canDraw;
    };

    const openLink = (ev: MouseEvent, node: NodeModel) => {
      node.board.shift = false; // This is to fix the "selection stickiness" that previously would occur after clicking a link (https://github.com/gtilflm/whiteboard/commit/be32cb4a6eb2bb3f8c1c7c1cc962cd18beb28f5c).

      // Users with whiteboard control need to shift+click to open links.  Otherwise, open links with a regular click
      if (hasWBControl()) {
        if (node.locked || ev.shiftKey) {
          window.open(node.url as string, '_blank');
        }
      } else {
        if (!node.locked) {
          window.open(node.url as string, '_blank');
        }
      }
    };

    const isMod = computed(() => history().user.moderator);

    const hasSelection = computed(() => {
      return (
        props.frame &&
        node.selected &&
        node.parent instanceof Slide &&
        (!node.locked || node.parent.board.locksDisabled) &&
        (!node.hidden || isMod)
      );
    });

    return {
      node,
      htmlId,
      nodeRef,
      matrix,
      SelectionComponents,
      getComponent,
      openLink,
      isMod,
      Group,
      hasSelection,
      hasWBControl,
    };
  },
});
</script>

<template>
  <g
    v-if="node instanceof Group"
    v-show="!node.hidden || isMod"
    :id="htmlId(node._id)"
    ref="nodeRef"
    :style="{ outline: node.hidden ? '2px dotted red' : undefined, cursor: hasWBControl() ? 'default' : 'pointer' }"
    :transform="matrix"
    @contextmenu.prevent
    @click="node.url ? openLink($event, node) : null"
  >
    <Node v-for="child in node.nodes" :id="child._id" :key="child._id" :frame="frame" />
    <title v-if="node.url && !node.locked && hasWBControl()">To follow the link use shift+click.</title>
    <title v-if="node.url && !node.locked && !hasWBControl()">Click to follow the link.</title>
  </g>
  <component
    :is="getComponent(node)"
    v-else
    v-show="!node.hidden || isMod"
    :id="node._id"
    ref="nodeRef"
    :style="{ outline: node.hidden ? '2px dotted red' : undefined, cursor: hasWBControl() ? 'default' : 'pointer' }"
    :transform="matrix"
    @contextmenu.prevent
    @click="node.url ? openLink($event, node) : null"
  >
    <title v-if="node.url && !node.locked && hasWBControl()">To follow the link use shift+click.</title>
    <title v-if="node.url && !node.locked && !hasWBControl()">Click to follow the link.</title>
  </component>
  <Teleport v-if="hasSelection" :to="frame">
    <component :is="(SelectionComponents as any)[node.selectionComponent]" :id="id" />
  </Teleport>
</template>
