import { Slide } from '../models';
import { Action, NodesSaveAction } from '.';

export type SlidePasteActionObjects = { slide: Slide; copy: Slide };
export type SlidePasteActionArgs = [where: 'before' | 'after'];
export class SlidePasteAction extends Action<SlidePasteActionObjects, SlidePasteActionArgs> {
  static actionName = 'SlidePasteAction';

  async redo({ slide, copy }: SlidePasteActionObjects, where: 'before' | 'after') {
    this.create(copy);

    slide.board.addSlide(copy, slide.board.indexOfSlide(slide) + (where === 'before' ? 0 : 1));

    await new NodesSaveAction().execute({ nodes: copy.nodes }, undefined, 1);
  }
}
