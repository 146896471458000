import { Node, Group } from '../models';
import { Action } from '.';

type NodeUngroupActionObjects = { group: Group };
export class NodeUngroupAction extends Action<NodeUngroupActionObjects> {
  static actionName = 'NodeUngroupAction';

  async redo({ group }: NodeUngroupActionObjects) {
    const index = group.index();
    const parent = group.parent;
    group.nodes
      .slice()
      .reverse()
      .forEach((node: Node) => {
        node.transform.lmultiplyO(group.transform);
        node.selected = group.selected;
        node.locked = group.locked;
        node.hidden = group.hidden;
        parent.addNode(node, index);
        return node;
      });

    group.delete(true, (m) => this.delete(m));
  }
}
