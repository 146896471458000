import { Node } from '../models';
import { Action } from './';

type NodesHideActionObjects = { nodes: Node[] };
export class NodesHideAction extends Action<NodesHideActionObjects> {
  static actionName = 'NodesHideAction';

  async redo({ nodes }: NodesHideActionObjects) {
    nodes.forEach((node) => {
      node.hidden = true;
      node.selected = false;
    });
  }
}
