import { Slide, Model } from '../models';

export class Role extends Model {
  static model = 'Role' as const;

  slides!: Slide;

  static fields() {
    return {
      _id: this.uid(),
      // participants: this.hasMany('Participant'),
      slides: this.hasMany(Slide, 'ownerId'),
      // users: this.belongsToMany(User)
    };
  }
}

Role.boot();
