import { bboxPoints, getPointFromEvent } from '../../utils/utils';
import { Point, Shape, Slide } from '..';
import { NodeMovePointAction, NodeMovePointsByOffsetAction } from '../../actions';
import { Field } from 'vue-model';

export class Baseline extends Shape {
  static model = 'Baseline';
  static base = Shape;

  static fields() {
    return {
      ...Shape.fields(),
      selectionComponent: this.string('LineSelection'),
    };
  }

  onMouseDown(ev: MouseEvent): any {
    const p = getPointFromEvent(ev, this.matrix?.value, true);

    const last = this.getLastPoint();

    if (!last) {
      this.addPoint(p, true);
    } else {
      Object.assign(last, { x: p.x, y: p.y }).round();
    }

    return p;
  }

  onMouseUp(ev: MouseEvent) {
    const p = getPointFromEvent(ev, this.matrix?.value, true);
    const last = this.getLastPoint();

    if (!this.hasMoved) {
      return this.cancelDrawing();
    }

    if (last.fixed) {
      this.addPoint(p, true);
    } else {
      Object.assign(last, { x: p.x, y: p.y, fixed: true }).round();
    }
  }

  onLineMove(ev: MouseEvent, pointIndex: number) {
    // Trigger the whiteboard indicator to start
    window.reportWhiteboardAction('drawing-start');

    const slide = this.parent as Slide;
    const matrix = slide.screenCtm;

    let ev1 = ev;

    const action = new NodeMovePointsByOffsetAction();
    action.start();

    const pointermove = (ev2: PointerEvent) => {
      const { x: x1, y: y1 } = getPointFromEvent(ev1, matrix.inverse()).transform(this.transform.inverse());
      const { x: x2, y: y2 } = getPointFromEvent(ev2, matrix.inverse()).transform(this.transform.inverse());

      ev1 = ev2;

      action.execute({ node: this }, [pointIndex, (pointIndex + 1) % this.points.length], { x: x2 - x1, y: y2 - y1 });
    };

    const pointerup = (ev2: PointerEvent) => {
      pointermove(ev2);

      const diffX = ev.clientX - ev2.clientX;
      const diffY = ev.clientY - ev2.clientY;

      const cancel = !diffX && !diffY;

      // Trigger the whiteboard indicator to stop
      window.reportWhiteboardAction('drawing-stop');
      if (cancel) {
        action.cancel();
      } else {
        action.stop();
      }

      document.removeEventListener('pointermove', pointermove);
      document.removeEventListener('pointerup', pointerup);
    };

    document.addEventListener('pointermove', pointermove);
    document.addEventListener('pointerup', pointerup);
  }

  // TODO: Add rightclicklistener so that we can cancel the move by moving the point back to its original position
  onPointMove(ev: MouseEvent, pointIndex: number) {
    // Trigger the whiteboard indicator to start
    window.reportWhiteboardAction('drawing-start');

    const slide = this.parent as Slide;
    const matrix = slide.screenCtm;

    const action = new NodeMovePointAction();
    action.start();

    const pointermove = (ev2: PointerEvent) => {
      const { x, y } = getPointFromEvent(ev2, matrix.inverse()).transformO(this.transform.inverse());

      action.execute({ node: this }, pointIndex, { x, y });
    };

    const pointerup = (ev2: PointerEvent) => {
      pointermove(ev2);

      // Trigger the whiteboard indicator to stop
      window.reportWhiteboardAction('drawing-stop');

      const diffX = ev.clientX - ev2.clientX;
      const diffY = ev.clientY - ev2.clientY;

      const cancel = !diffX && !diffY;

      if (cancel) {
        action.cancel();
      } else {
        action.stop();
      }

      document.removeEventListener('pointermove', pointermove);
      document.removeEventListener('pointerup', pointerup);
    };

    document.addEventListener('pointermove', pointermove);
    document.addEventListener('pointerup', pointerup);
  }

  generateCornerPoints() {
    return bboxPoints(this.points.map((p: Point) => p.transform(this.transform)));
  }
}

Baseline.boot();
