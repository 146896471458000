<template>
  <div class="wrapper">
    <!-- <a href="#" style="position: absolute; top: 0; left: 0; z-index: 200" @click.prevent="close">Disconnect</a> -->
    <div class="canvas-status-overlay" v-if="ws.status !== WebSocketStatus.READY && !local">
      <span v-if="ws.status === WebSocketStatus.CONNECTING">Connecting...</span>
      <span v-else-if="ws.status === WebSocketStatus.OPEN">Waiting to be ready...</span>
      <span v-else-if="ws.status === WebSocketStatus.CLOSING">About to close connection...</span>
      <span v-else-if="ws.status === WebSocketStatus.CLOSED">
        Connection closed.
        <a href="#" @click.prevent="ws.reconnect()">Reconnect</a>
      </span>
      <span v-else-if="ws.status === WebSocketStatus.WAITING">
        Waiting 5s to reconnect.
        <a href="#" @click.prevent="ws.reconnect()">Click to retry now</a>
      </span>
      <span v-else-if="ws.status === WebSocketStatus.OFFLINE">
        It seems you are offline.
        <a href="#" @click.prevent="ws.reconnect(true)">Click here to reconnect</a>
      </span>
      <span v-else-if="ws.status === WebSocketStatus.ERROR">Connection error</span>
    </div>

    <ToolbarVertical
      v-if="board.localParticipant.isOwner || (!readonly && board.localParticipant.canDraw)"
      :board-id="board._id"
      :setBoardPosition
    />

    <template v-if="board.localParticipant.isOwner || (!readonly && board.localParticipant.canDraw)">
      <ToolbarHorizontal
        :class="{
          invisible: !(
            (!['select', 'pointer'].includes(board.toolbar) && !board.currentSlide?.shapeInProgress) ||
            (board.currentSlide?.selectedNodes.length && !nodesMoving)
          ),
        }"
        :board-id="board._id"
        class="opacity-50 hover:opacity-100 transition-opacity transition-duration-200"
      />
    </template>

    <div v-if="!board.seq" class="loading pulse">Board is loading...</div>

    <Slide
      v-if="slide"
      :id="slide._id"
      ref="frame"
      @wheel="onWheel"
      @pointerdown="onMouseDown"
      @scroll="onScroll"
      @contextmenu="onContextMenu"
    >
      <Pointer v-for="(pos, id) in board.pointers" :id="id" :key="'pointer_' + id" :x="pos.x" :y="pos.y" />

      <template #controls>
        <div
          v-if="
            !noNavigation &&
            (board.localParticipant.isOwner || (board.navigationEnabled && board.localParticipant.canNavigate))
          "
          class="navigation flex gap-1"
        >
          <ButtonSimple :disabled="board.currentIndex === 0" @click="prev(board._id)">
            <font-awesome-icon icon="arrow-left" fixed-width />
          </ButtonSimple>
          <ButtonSimple :disabled="board.currentIndex >= board.slides.length - 1" @click="next(board._id)">
            <font-awesome-icon icon="arrow-right" fixed-width />
          </ButtonSimple>
          <ButtonSimple @click="readonly ? null : addSlide(board._id)">
            <font-awesome-icon icon="plus" fixed-width />
          </ButtonSimple>
          <ButtonSimple :disabled="board.slides.length < 2" @click="readonly ? null : deleteSlide(board._id)">
            <font-awesome-icon icon="trash" fixed-width />
          </ButtonSimple>
        </div>

        <div class="absolute bottom-6 right-6">
          <SliderWithButtons v-model="zoomSlider" logarithmic :min="0.1" :max="10" :step="5" orientation="vertical" />
        </div>
      </template>
    </Slide>
  </div>

  <Arrows key="arrow-heads" />

  <ContextMenu
    v-if="board.localParticipant.isOwner || (!readonly && board.localParticipant.canDraw)"
    v-model="showContextMenu"
    :title="contextNodes.length ? 'Shape' : 'Board'"
    :subtitle="contextNodes.length === 1 ? contextNodes[0]!.owner?.name ?? 'Unknown' : undefined"
  >
    <template v-if="contextNodes.length">
      <div style="column-count: 2">
        <div class="contextmenu-item" @click="copyNodes(contextNodes, board)">
          <i class="bx bxs-copy" />
          Copy
        </div>
        <div
          class="contextmenu-item"
          :class="{ disabled: contextNodes.length === 1 }"
          @click="alignNodes(contextNodes)"
        >
          <i class="bx bx-align-left" />
          Align
        </div>
        <div
          class="contextmenu-item"
          :class="{ disabled: contextNodes.length === 1 }"
          @click="groupNodes(contextNodes)"
        >
          <font-awesome-icon icon="object-group" />
          Group
        </div>
        <div
          class="contextmenu-item"
          :class="{ disabled: contextNodes.length > 1 || contextNodes[0]!.type !== 'Group' }"
          @click="ungroupNode(contextNodes[0]!)"
        >
          <font-awesome-icon icon="object-ungroup" />
          Ungroup
        </div>
        <div
          class="contextmenu-item"
          :class="{ disabled: contextNodes.every((node) => node.locked) }"
          @click="lockNodes(contextNodes)"
        >
          <i class="bx bxs-lock-alt" />
          Lock Objects
        </div>
        <div
          class="contextmenu-item"
          :class="{ disabled: contextNodes.every((node) => !node.locked) }"
          @click="unlockNodes(contextNodes)"
        >
          <i class="bx bxs-lock-open-alt" />
          Unlock Objects
        </div>
        <div
          v-if="isMod"
          class="contextmenu-item"
          :class="{ disabled: contextNodes.every((node) => node.hidden) }"
          @click="hideNodes(contextNodes)"
        >
          <font-awesome-icon icon="eye-slash" />
          Hide Objects
        </div>
        <div
          v-if="isMod"
          class="contextmenu-item"
          :class="{ disabled: contextNodes.every((node) => !node.hidden) }"
          @click="showNodes(contextNodes)"
        >
          <font-awesome-icon icon="eye" />
          Show Objects
        </div>
        <div class="contextmenu-item" @click="deleteNodes(contextNodes)">
          <i class="bx bxs-trash-alt" />
          Delete
        </div>
        <div class="contextmenu-item" @click="sendBackwards(contextNodes)">
          <i class="bx bxs-chevron-down" />
          Move Backward
        </div>
        <div class="contextmenu-item" @click="sendForwards(contextNodes)">
          <i class="bx bxs-chevron-up" />
          Move Forward
        </div>
        <div class="contextmenu-item" @click="addLinkToNodes(contextNodes)">
          <i class="bx bx-link" />
          Add Link
        </div>
        <div
          class="contextmenu-item"
          :class="{ disabled: contextNodes.every((node) => !node.url) }"
          @click="removeLinkFromNodes(contextNodes)"
        >
          <i class="bx bx-unlink" />
          Remove Link
        </div>
        <template v-if="shapeContextNode">
          <div
            class="contextmenu-item"
            v-if="shapeContextNode.filled"
            @click="changeNode(shapeContextNode, 'filled', false)"
          >
            <i class="bx bx-circle" />
            Make Hollow
          </div>
          <div class="contextmenu-item" v-else @click="changeNode(shapeContextNode, 'filled', true)">
            <i class="bx bxs-circle" />
            Make Filled
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="contextmenu-item" :class="{ disabled: !board.copied.length }" @click="pasteNodes(board.copied)">
        <i class="bx bxs-paste" />
        Paste Objects
      </div>
      <div v-if="isMod || local" class="contextmenu-item" @click="copySlide(board.currentSlide)">
        <i class="bx bx-copy-alt" />
        Copy Board
      </div>
      <div v-if="isMod || local" class="contextmenu-item" @click="cutSlide(board.currentSlide)">
        <i class="bx bx-cut" />
        Cut Board
      </div>
      <div
        v-if="board.copiedSlide && (isMod || local)"
        class="contextmenu-item"
        @click="pasteSlide(board.copiedSlide, 'before')"
      >
        <i class="bx bx-left-arrow-alt" />
        <i class="bx bx-paste" />
        Paste Board Before
      </div>
      <div
        v-if="board.copiedSlide && (isMod || local)"
        class="contextmenu-item"
        @click="pasteSlide(board.copiedSlide, 'after')"
      >
        <i class="bx bx-paste" />
        <i class="bx bx-right-arrow-alt" />
        Paste Board After
      </div>
      <div v-if="isMod || local" class="contextmenu-item" @click="deleteSlide(board._id)">
        <font-awesome-icon icon="trash" />
        Delete Board
      </div>
    </template>
  </ContextMenu>
  <PromptDialog />
  <ConfirmDialog />

  <Teleport v-if="board.showHiddenSlides.length" to="body">
    <div ref="board.hiddenContainerRef" class="hiddenContainer">
      <Slide
        v-for="index in board.showHiddenSlides"
        :id="board.slides[index]!._id"
        :key="board.slides[index]!._id + 'hidden'"
        :ref="(ref: any) => board.hiddenSlideRefs.push(ref)"
        :compat="board.needsCompat"
      >
        <Arrows />
      </Slide>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import {
  Ref,
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  shallowReactive,
  shallowRef,
  watch,
  watchEffect,
} from 'vue';
import { history } from '../History';
import { Board, Box, Group, Matrix, Node, Shape, Slide as SlideModel, Text, User } from '../models';
import Pointer from './Pointer.vue';
import Slide from './Slide.vue';

import { BoxLike } from '@/utils/types';
import { WebSocketStatus } from '@/utils/websocket';
import { Dehydrate } from 'vue-model';
import { useRouter } from 'vue-router';
import {
  DoWhatYouWantAction,
  NodeUngroupAction,
  NodesDeleteAction,
  NodesGroupAction,
  NodesHideAction,
  NodesLockAction,
  NodesMoveAction,
  NodesShowAction,
  NodesToBackAction,
  NodesToFrontAction,
  NodesUnlockAction,
  ShapeSaveAction,
  SlidePasteAction,
} from '../actions';
import { addSlide, deleteSlide, load, next, prev } from '../api';
import { useDragging } from '../compositions/useDraggingCanvas';
import { useDrawing } from '../compositions/useDrawing';
import { useFiles } from '../compositions/useFiles';
import { useWebSocket } from '../compositions/useWebSocket';
import { jsonToCanvas, wbdToCanvas } from '../utils/import';
import { assert, bbox, copyNodes, getPointFromEvent, intersects, paste, prompt } from '../utils/utils';
import ButtonSimple from './ButtonSimple.vue';
import ConfirmDialog from './ConfirmDialog.vue';
import ContextMenu from './ContextMenu.vue';
import PromptDialog from './PromptDialog.vue';
import SliderWithButtons from './SliderWithButtons.vue';
import ToolbarHorizontal from './ToolbarHorizontal.vue';
import ToolbarVertical from './ToolbarVertical.vue';
import Arrows from './Arrows.vue';

function transformInverseSimple(box: BoxLike, m: Matrix) {
  return {
    x: box.x - m.e,
    y: box.y - m.f,
    width: box.width / m.a,
    height: box.height / m.d,
  };
}

const getPixelDelta = (ev: WheelEvent) => {
  const wheelZoomDeltaModeLinePixels = 17;
  const wheelZoomDeltaModeScreenPixels = 53;

  let normalizedPixelDeltaY;

  switch (ev.deltaMode) {
    case 1:
      normalizedPixelDeltaY = ev.deltaY * wheelZoomDeltaModeLinePixels;
      break;

    case 2:
      normalizedPixelDeltaY = ev.deltaY * wheelZoomDeltaModeScreenPixels;
      break;

    default:
      // 0 (already pixels) or new mode (avoid crashing)
      normalizedPixelDeltaY = ev.deltaY;
      break;
  }

  return normalizedPixelDeltaY;
};

const getMatrixForZoom = (level: number, x: number, y: number, zoom: number) => {
  if (!level) return [zoom, 0, 0, zoom, 0, 0];

  const zoomAmount = zoom / level;

  const a = zoomAmount;
  const b = 0;
  const c = 0;
  const d = zoomAmount;
  const e = -x * zoomAmount + x;
  const f = -y * zoomAmount + y;

  return [a, b, c, d, e, f];
};

const props = defineProps({
  id: { type: String, required: true },
  index: { type: String },
});

const router = useRouter();
const local = router.currentRoute.value.hash.includes('local');
const manualLoad = router.currentRoute.value.hash.includes('manual');
let board = shallowRef<Board>() as Ref<Board>;

watchEffect(() => {
  board.value = Board.getOrCreate({ _id: props.id });
  board.value.on('set-slide', (index) => {
    router.replace({ path: `/${board.value._id}/${index + 1}` });
  });
});

if (local) {
  const localUser = new User({ moderator: true });
  const board = Board.create({ _id: props.id, seq: 1, localUserId: localUser._id });
  board.slides = [{} as any];
  history().setLocalUser(localUser);
  history().setActiveSlide(board.currentSlide as SlideModel);
  history().setActiveBoard(board);
} else {
  if (!manualLoad) {
    load(props.id);
  }
}

const noNavigation = router.currentRoute.value.hash.includes('navigation=false');
const ws = useWebSocket(props.id, local);
const slide = computed(() => board.value.currentSlide as SlideModel);
const readonly = computed(() => board.value.readonly);

watch(
  () => router.currentRoute.value.hash.includes('readonly=true'),
  (isReadonly) => {
    board.value.readonly = isReadonly;
  },
);

watchEffect(
  // () => props.index,
  // (index = 1, oldVal) => {
  () => {
    const index = Math.min(board.value.slides.length, Math.max(1, Number(props.index ?? 0))) - 1;

    if (index === board.value.currentIndex) return;

    board.value.currentIndex = index;
    window.changeSlide(board.value._id, index);

    const id = history().user?._id;
    if (id) {
      ws.sendJSON({ type: 'pointer', id: history().user._id, pos: null, slide: board.value.currentIndex });
      board.value.pointers = {};
    }
  },
  // { immediate: true },
);

watch(
  () => board.value?.slides.map((s) => s.name).join(),
  () => {
    window.slideNames(board.value?.slides);
  },
);

watch(
  () => readonly.value,
  (newValue) => {
    if (newValue) {
      board.value.slides.forEach((s) => {
        s.nodes.forEach((n) => {
          n.selected = false;
        });
      });
    }
  },
);

let fineTuneAction: NodesMoveAction | null = null;

const globalKeyDown = (ev: KeyboardEvent) => {
  if (ev.key === 'Control') {
    slide.value.board.ctrl = true;
  }
  if (ev.key === 'Shift') {
    slide.value.board.shift = true;
  }

  if (readonly.value) return;

  if (ev.key === 'Delete' || ev.key === 'Backspace') {
    if (document.activeElement !== document.body) return;

    if (board.value.currentSlide) {
      new NodesDeleteAction().execute({
        nodes: board.value.currentSlide.selectedNodes,
      });
    }
  }

  if (ev.key.includes('Arrow')) {
    if (document.activeElement !== document.body) return;

    const nodes = board.value.currentSlide?.selectedNodes;

    if (!nodes?.length) return;

    if (!fineTuneAction) {
      fineTuneAction = new NodesMoveAction().start();
    }

    // If CTRL is held, adjust by units of 10 instead of 1
    let adjustValue = ev.ctrlKey ? 10 : 1 
    
    const x = ev.key.includes('Right') ? adjustValue : ev.key.includes('Left') ? (-1 * adjustValue) : 0;
    const y = ev.key.includes('Down') ? adjustValue : ev.key.includes('Up') ? (-1 * adjustValue) : 0;

    fineTuneAction.execute({ nodes }, x, y);
  }

  if (ev.ctrlKey) {
    if (ev.key === 'c') {
      ev.preventDefault();
      copyNodes(slide.value.selectedNodes, board.value);
      // board.value.copied = slide.value.selectedNodes.map((n) => n.dehydrateAsCopy())
    }

    if (ev.key === 'a') {
      ev.preventDefault();
      slide.value.nodes.forEach((n) => {
        n.selected = true;
      });
    }

    if (ev.key === 'g') {
      ev.preventDefault();
      groupNodes(slide.value.selectedNodes);
    }

    if (ev.key === 'u' && slide.value.selectedNodes.length === 1 && slide.value.selectedNodes[0]!.type === 'Group') {
      ev.preventDefault();
      ungroupNode(slide.value.selectedNodes[0]! as unknown as Group);
    }

    if (ev.key === 'z') {
      ev.preventDefault();
      history().undo();
    }

    if (ev.key === 'y') {
      ev.preventDefault();
      history().redo();
    }

    if (ev.key === 'l') {
      ev.preventDefault();
      lockNodes(slide.value.selectedNodes);
    }

    // Cycle through the selection of the select arrow, pen, or pointer quickly using CTRL+Spacebar
    if (ev.key === ' ') {
      ev.preventDefault();
      if (board.value.toolbar === 'select') {
        board.value.toolbar = 'freeLine';
      } else if (board.value.toolbar === 'freeLine') {
        board.value.toolbar = 'pointer';
      } else if (board.value.toolbar === 'pointer') {
        board.value.toolbar = 'select';
      } else {
        board.value.toolbar = 'select';
      }
    }
  }
};

const globalKeyUp = (ev: KeyboardEvent) => {
  if (ev.key === 'Control') {
    slide.value.board.ctrl = false;
  }
  if (ev.key === 'Shift') {
    slide.value.board.shift = false;
  }
  if (ev.key.includes('Arrow')) {
    if (fineTuneAction) {
      fineTuneAction.stop();
      fineTuneAction = null;
    }
  }
};

const { handleImages } = useFiles();

const onDropOrPaste = (ev: DragEvent | ClipboardEvent) => {
  if (board.value.currentSlide?.shapeInProgress) return;
  if (document.activeElement?.closest('.dialog') != null) return;

  ev.preventDefault();

  if (readonly.value) return;

  const data = (ev as DragEvent).dataTransfer || (ev as ClipboardEvent).clipboardData;

  if (!data) return;

  let evWithPoint = ev;
  if (ev instanceof ClipboardEvent) {
    evWithPoint = new DragEvent('drop', { clientX: window.innerWidth / 2, clientY: window.innerHeight / 2 });
  }

  const files = data.files;

  const text = data.getData('text/plain');

  let shapes = [] as Dehydrate<typeof Node>[];
  try {
    const parsed = JSON.parse(text);
    if (Array.isArray(parsed)) {
      if (parsed.length && parsed[0].type) {
        shapes = parsed;
      }
    }
  } catch (e) {}

  if (shapes.length) {
    paste(shapes, board.value);
    return;
  }

  const filtered = Array.from(files).filter((s) => s.type.includes('image'));
  const wbd = Array.from(files).find((s) => s.name.endsWith('.wbd'));
  const json = Array.from(files).find((s) => s.name.endsWith('.json'));

  if (!filtered.length && !wbd && !json && text) insertText(text, evWithPoint as DragEvent);

  if (wbd) {
    const fr = new FileReader();
    fr.onload = () => {
      wbdToCanvas(fr.result as string, board.value).then(() => {
        next(board.value._id);
        window.notifySlidesImported();
      });
    };

    fr.readAsText(wbd);
    return;
  }

  if (json) {
    const fr = new FileReader();
    fr.onload = () => {
      jsonToCanvas(fr.result as string, board.value).then(() => {
        next(board.value._id);
        window.notifySlidesImported();
      });
    };

    fr.readAsText(json);
    return;
  }

  if (filtered.length) {
    handleImages(filtered, evWithPoint as DragEvent, slide.value);
  }
};

const insertText = (text: string, ev: DragEvent) => {
  const p = getPointFromEvent(ev, slide.value.screenCtm, true, { y: -13 });

  const t = Text.create({
    parentId: slide.value._id,
    points: [p],
    text,
    transform: {},
    fillColor: '#000000',
    boardId: board.value._id,
  });

  new ShapeSaveAction().execute({ node: t, slide: slide.value });
};

const onDragOver = (ev: DragEvent) => {
  ev.preventDefault();
};

onMounted(() => {
  document.addEventListener('keydown', globalKeyDown);
  document.addEventListener('keyup', globalKeyUp);
  document.addEventListener('drop', onDropOrPaste);
  document.addEventListener('dragover', onDragOver);
  document.addEventListener('paste', onDropOrPaste);
});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', globalKeyDown);
  document.removeEventListener('keyup', globalKeyUp);
  document.removeEventListener('drop', onDropOrPaste);
  document.removeEventListener('dragover', onDragOver);
  document.removeEventListener('paste', onDropOrPaste);
});

const frame = ref<InstanceType<typeof Slide>>();

watch(
  frame,
  (frame) => {
    if (frame) {
      setBoardPosition();
    }
  },
  { flush: 'post' },
);

// FIXME: hack because ref watcher is not fired
const setBoardPosition = () => {
  if (!frame.value?.innerFrame) {
    return;
  }

  slide.value.board.position = transformInverseSimple(
    frame.value.innerFrame.getBoundingClientRect(),
    slide.value.board.transform,
  );
};

setBoardPosition();

window.addEventListener('resize', () => {
  if (frame.value) {
    setBoardPosition();
  }
});

const cssMatrix = computed(() => board.value.transform);
const transform2 = computed(() => board.value.transform2);

const onWheel = (ev: WheelEvent) => {
  // If ctrl key is not pressed, we let the browser handle scroll
  if (!ev.ctrlKey) return;

  ev.preventDefault();

  const zoomFactor = 0.2;
  const delta = getPixelDelta(ev);

  const zoom = cssMatrix.value.a;
  const { e, f } = cssMatrix.value;
  const lvl = Math.pow(1 + zoomFactor, delta / 100) * zoom;
  const p = [(ev.clientX - e - board.value.position.x) / zoom, (ev.clientY - f - board.value.position.y) / zoom] as [
    number,
    number,
  ];

  const matrix = getMatrixForZoom(lvl, p[0], p[1], zoom);
  cssMatrix.value.multiplyO(matrix);

  setZoomAndScroll();
};

const zoomSlider = computed({
  get: () => cssMatrix.value.a,
  set: (val) => {
    const { a: zoom, e, f } = cssMatrix.value;
    const halfScreen = [window.innerWidth / 2, window.innerHeight / 2] as [number, number];
    const p = [
      (halfScreen[0] - e - board.value.position.x) / zoom,
      (halfScreen[1] - f - board.value.position.y) / zoom,
    ] as [number, number];

    const matrix = getMatrixForZoom(1 / val, p[0], p[1], 1 / zoom);
    cssMatrix.value.multiplyO(matrix);

    setZoomAndScroll();
  },
});

const setZoomAndScroll = () => {
  const { a, e, f } = cssMatrix.value;

  assert(frame.value?.frame);

  // Dimensions of the canvas area
  const width = frame.value.frame.clientWidth;
  const height = frame.value.frame.clientHeight;

  // Dimensions of the scaled area
  const scrollWidth = Math.round(a * width);
  const scrollHeight = Math.round(a * height);

  // Maximum amount of scrolling possible
  const maxScrollLeft = scrollWidth - width;
  const maxScrollTop = scrollHeight - height;

  // Amount of scrolling we need
  const scrollLeft = Math.min(maxScrollLeft, -e);
  const scrollTop = Math.min(maxScrollTop, -f);

  // Restrain canvas to its edges
  cssMatrix.value.e = Math.min(-scrollLeft, 0);
  cssMatrix.value.f = Math.min(-scrollTop, 0);

  // Push the canvas into the middle if zoomed out enough
  if (maxScrollLeft < 0) {
    cssMatrix.value.e = -maxScrollLeft / 2;
  }
  if (maxScrollTop < 0) {
    cssMatrix.value.f = -maxScrollTop / 2;
  }

  Object.assign(transform2.value, cssMatrix.value);

  if (maxScrollLeft > 0) {
    transform2.value.e = 0;
  }
  if (maxScrollTop > 0) {
    transform2.value.f = 0;
  }

  nextTick(() => {
    if (frame.value?.frame) {
      ignoreScroll = true;
      frame.value.frame.style.top = frame.value.frame.style.top === '0px' ? '' : '0px';
      frame.value.frame.scrollLeft = scrollLeft;
      frame.value.frame.scrollTop = scrollTop;
    }
  });
};

let ignoreScroll = false;

const onScroll = (_ev: Event) => {
  if (ignoreScroll) {
    ignoreScroll = false;
    return;
  }

  nextTick(() => {
    if (frame.value) {
      cssMatrix.value.e = -frame.value.frame!.scrollLeft;
      cssMatrix.value.f = -frame.value.frame!.scrollTop;
    }
  });
};

let nodesMoveAction = new NodesMoveAction();
const nodesMoving = ref(false);
const { onPointerDown } = useDragging(
  slide,
  computed(() => frame.value?.frame) as Ref<HTMLDivElement>,
  (x, y) => {
    nodesMoveAction.execute({ nodes: slide.value.selectedNodes }, x, y);
  },
  () => {
    // Trigger the whiteboard indicator to start
    window.reportWhiteboardAction('drawing-start');

    nodesMoveAction = new NodesMoveAction().start();
    nodesMoving.value = true;
  },
  (canceled = false) => {
    // Trigger the whiteboard indicator to stop
    window.reportWhiteboardAction('drawing-stop');

    canceled ? nodesMoveAction.cancel() : nodesMoveAction.stop();
    nodesMoving.value = false;
  },
);

let lastTime = 0;
let nextSendTimeout = 0;

const throttledSend = (msg: { type: 'pointer'; id: string; pos: { x: number; y: number } | null; slide: number }) => {
  window.clearTimeout(nextSendTimeout);

  const time = Date.now();
  if (time - lastTime < 30) {
    nextSendTimeout = window.setTimeout(() => throttledSend(msg));
  } else {
    ws.sendJSON(msg);
    lastTime = time;
  }
};

let pointerUp: any = null;
const handlePointer = (ev: PointerEvent) => {
  const move = (ev: PointerEvent) => {
    const { x, y } = getPointFromEvent(ev, slide.value.screenCtm, true);
    const id = history().user._id;
    board.value.setPointer(id, { x, y }, board.value.currentIndex);
    throttledSend({ type: 'pointer', id, pos: { x, y }, slide: board.value.currentIndex });
    pointerUp = up.bind(null, ev);
    // ws.sendJSON({ type: 'pointer', id, pos: { x, y } })
  };

  const up = (ev: PointerEvent) => {
    move(ev);
    document.removeEventListener('pointermove', move, { capture: true });
    document.removeEventListener('pointerup', up, { capture: true });
    pointerUp = null;
  };

  document.addEventListener('pointermove', move, { capture: true });
  document.addEventListener('pointerup', up, { capture: true });

  pointerUp = up.bind(null, ev);

  move(ev);
};

watch(readonly, (newValue) => {
  const id = history().user._id;
  if (newValue && pointerUp) {
    pointerUp();
    board.value.setPointer(id, null, board.value.currentIndex);
  }

  if (newValue) {
    throttledSend({ type: 'pointer', id, pos: null, slide: board.value.currentIndex });
  }
});

watch(
  () => board.value.toolbar,
  (newVal, oldVal) => {
    if (oldVal === 'pointer' && newVal !== 'pointer') {
      const id = history().user._id;
      board.value.setPointer(id, null, board.value.currentIndex);
      ws.sendJSON({ type: 'pointer', id, pos: null });
    }

    if (board.value.currentSlide?.shapeInProgress) {
      board.value.currentSlide.shapeInProgress.finishShape();
    }

    board.value.currentSlide && (board.value.currentSlide.selectedNodes = []);
  },
);

watch(
  () => board.value.locksDisabled,
  () => {
    if (!board.value.locksDisabled) {
      board.value.currentSlide && (board.value.currentSlide.selectedNodes = []);
    }
  },
);

const onMouseDown = (ev: PointerEvent) => {
  ev.preventDefault();
  if ((document.activeElement as HTMLInputElement)?.blur) {
    (document.activeElement as HTMLInputElement).blur();
  }

  if (ev.button === 1) {
    return onPan(ev);
  }

  if (ev.button !== 0) {
    return;
  }

  if (readonly.value) return;

  // Dont allow clicks on scrollbars to recognize
  if (ev.clientX >= (frame.value?.frame?.clientWidth ?? 0) + (frame.value?.frame?.parentElement?.offsetLeft ?? 0)) {
    return;
  }

  if (ev.clientY >= (frame.value?.frame?.clientHeight ?? 0) + (frame.value?.frame?.parentElement?.offsetTop ?? 0)) {
    return;
  }

  if (board.value.toolbar === 'pointer') {
    return handlePointer(ev);
  }

  if (board.value.toolbar === 'select') {
    const p = getPointFromEvent(ev, slide.value.screenCtm, true);
    const box1 = { x: p.x, y: p.y, width: 1, height: 1 } as Box;

    const candidate = slide.value.unlockedNodes
      .slice()
      .reverse()
      .find((node: Node) => {
        const box2 = new Box(bbox(node.generateCornerPoints()));
        return intersects(box1, box2);
      });

    const clickedSelectedNode = slide.value.selectedNodes.find((node: Node) => {
      const box2 = new Box(bbox(node.generateCornerPoints()));
      return intersects(box1, box2);
    });

    // When the user clicks on multiple nodes we want to select the one on top
    // However, if nodes were already selected, we assume that the user only wants to move those
    // So we dont change the selection. That allows us to move selected nodes that are covered
    // by other nodes
    if (candidate) {
      // If the shiftkey is pressed we only want to toggle the selection from the node on top
      // We dont allow for drag when shiftkey is pressed
      if (ev.shiftKey) {
        candidate.selected = !candidate.selected;
        return;
      }

      // We only select if no selected node was clicked
      if (!clickedSelectedNode) {
        slide.value.selectedNodes = [candidate];
      }

      // This triggers the drag on the selected node(s)
      nextTick(() => onPointerDown(ev));
      return;
    }
  }

  // If selection tool is not active, or no node was clicked
  // draw whatever shape is active (which also could be a selection)
  startDrawing(ev, slide.value.board.toolbar);
};

const onPan = (_ev: MouseEvent) => {
  const pointermove = (ev: MouseEvent) => {
    const dx = ev.movementX;
    const dy = ev.movementY;

    cssMatrix.value.translateO(dx, dy);
    setZoomAndScroll();
  };

  const pointerup = (ev: MouseEvent) => {
    pointermove(ev);
    document.removeEventListener('pointermove', pointermove, { capture: true });
    document.removeEventListener('pointerup', pointerup, { capture: true });
  };

  document.addEventListener('pointermove', pointermove, { capture: true });
  document.addEventListener('pointerup', pointerup, { capture: true });
};

const { startDrawing } = useDrawing(slide);

const contextNodes = shallowReactive<Node[]>([]);
const showContextMenu = ref(false);

const onContextMenu = (ev: MouseEvent) => {
  ev.preventDefault();

  if (readonly.value) return;

  showContextMenu.value = true;

  const p = getPointFromEvent(ev, slide.value.screenCtm, true);
  const box1 = { x: p.x, y: p.y, width: 1, height: 1 } as Box;

  const anySelectedNodeClicked = slide.value.selectedNodes.some((node: Node) => {
    const box2 = new Box(bbox(node.generateCornerPoints())); // .transform(node.transform)
    return intersects(box1, box2);
  });

  if (anySelectedNodeClicked) {
    contextNodes.splice(0, contextNodes.length, ...slide.value.selectedNodes);
    // contextNodes.value = slide.value.selectedNodes
    return;
  }

  slide.value.selectedNodes = [];

  const candidate = slide.value.nodes
    .slice()
    .reverse()
    .find((node: Node) => {
      const box2 = new Box(bbox(node.generateCornerPoints())); // .transform(node.transform)
      return intersects(box1, box2) && (history().user.moderator || !node.hidden);
    });

  if (candidate) {
    candidate.selected = !candidate.locked;
    // contextNodes.value = [ candidate ]
    contextNodes.splice(0, contextNodes.length, candidate);
    return;
  }

  contextNodes.length = 0;
  // contextNodes.value = []
};

const copySlide = (slide: SlideModel | null) => {
  const copy = slide?.dehydrateAsCopy() as Dehydrate<typeof SlideModel>;
  board.value.copiedSlide = copy;
  window.setCopiedSlide(copy);
};

const cutSlide = (slide: SlideModel | null) => {
  const cut = slide?.dehydrateAsCopy() as Dehydrate<typeof SlideModel>;
  board.value.copiedSlide = cut;
  window.setCopiedSlide(cut);
  deleteSlide(board.value._id, true);
};

const pasteSlide = (slide: Dehydrate<typeof SlideModel> | null, where: 'after' | 'before') => {
  return new SlidePasteAction().execute(
    {
      slide: board.value.currentSlide as SlideModel,
      copy: SlideModel.hydrate(JSON.parse(JSON.stringify(slide)) as Dehydrate<typeof SlideModel>),
    },
    where,
  );
};

const pasteNodes = (nodes: Dehydrate<typeof Node>[]) => {
  paste(nodes, board.value);
};

const alignNodes = (_nodes: Node[]) => {
  // TODO: align nodes
};
const groupNodes = (nodes: Node[]) => {
  const group = Group.create({ parentId: nodes[0]!.parentId, transform: {}, boardId: nodes[0]!.boardId });
  new NodesGroupAction().execute({ nodes: nodes, group });
};
const ungroupNode = (node: Group | Node) => {
  new NodeUngroupAction().execute({ group: node as Group });
};
const lockNodes = (nodes: Node[]) => {
  new NodesLockAction().execute({ nodes: nodes });
};
const unlockNodes = (nodes: Node[]) => {
  new NodesUnlockAction().execute({ nodes: nodes });
};
const hideNodes = (nodes: Node[]) => {
  new NodesHideAction().execute({ nodes: nodes });
};
const showNodes = (nodes: Node[]) => {
  new NodesShowAction().execute({ nodes: nodes });
};
const deleteNodes = (nodes: Node[]) => {
  new NodesDeleteAction().execute({ nodes: nodes });
};
const sendBackwards = (nodes: Node[]) => {
  new NodesToBackAction().execute({ nodes: nodes });
};
const sendForwards = (nodes: Node[]) => {
  new NodesToFrontAction().execute({ nodes: nodes });
};
const addLinkToNodes = async (nodes: Node[]) => {
  const url = await prompt('Enter the URL of the link:');
  if (!url) return;

  new DoWhatYouWantAction().execute({ nodes }, ({ nodes }) => {
    (nodes as Node[]).forEach((node) => {
      node.url = url;
    });
  });
};
const removeLinkFromNodes = (nodes: Node[]) => {
  new DoWhatYouWantAction().execute({ nodes }, ({ nodes }) => {
    (nodes as Node[]).forEach((node) => {
      node.url = null;
    });
  });
};

const changeNode = <T extends Node, K extends Exclude<keyof T, '$id'>, V extends T[K]>(
  node: T,
  property: K,
  value: V,
) => {
  new DoWhatYouWantAction<{ node: T }>().execute({ node }, ({ node }) => {
    node[property] = value;
  });
};

const isMod = computed(() => board.value?.localUser?.moderator);

const shapeContextNode = computed(
  () => contextNodes.length === 1 && contextNodes[0] instanceof Shape && contextNodes[0],
);
</script>

<style scoped lang="scss">
.navigation {
  position: absolute;
  right: 25px;
  top: 20px;
  z-index: 10;
}

.wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
}
.contextmenu {
  position: absolute;
  background: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px 0;
  margin: 0;
  list-style: none;

  > li {
    padding: 5px 20px;
    cursor: pointer;

    &:hover {
      background: #ddd;
    }
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #999;
  font-size: 1.5em;
  font-weight: bold;
  padding: 10px;
}

.pulse {
  animation: pulse-effect 3s ease-in-out infinite;
}

.canvas-status-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-status-overlay > span {
  font-size: 1.2em;
  padding: 10px 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes pulse-effect {
  0% {
    //transform: scale(0.75);
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }

  25% {
    // transform: scale(0.90);
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0);
    border-radius: 10px;
  }

  50% {
    // transform: scale(1.1);
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }

  75% {
    // transform: scale(0.90);
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0);
    border-radius: 10px;
  }

  100% {
    //  transform: scale(0.75);
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
}
</style>
