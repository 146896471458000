import { createApp } from 'vue';
import App from './App.vue';
import './index.css';
import { Slide } from './models';
import router from './router';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHandPointer,
  faArrowLeft,
  faArrowRight,
  faTrash,
  faPlus,
  faObjectGroup,
  faObjectUngroup,
  faEye,
  faEyeSlash,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Dehydrate, modelRegistry } from 'vue-model';
import * as API from './api';
import 'boxicons/css/boxicons.min.css';
import { confirm } from './utils/utils';
import PrimeVue from 'primevue/config';
// import Aura from '@primevue/themes/aura';

window.readyHandler = () => {
  const fn = (event: MessageEvent) => (event.source as WindowProxy | Window).postMessage('ready', event.origin);

  connectedEv ? fn(connectedEv) : messages.push(fn);
};

window.changeSlide = (boardId, index) => {
  const fn = (event: MessageEvent) =>
    (event.source as WindowProxy | Window).postMessage(
      { type: 'event', name: 'changeSlide', index, boardId },
      event.origin,
    );

  connectedEv ? fn(connectedEv) : messages.push(fn);
};

window.setCopiedSlide = (slide) => {
  const fn = (event: MessageEvent) =>
    (event.source as WindowProxy | Window).postMessage({ type: 'event', name: 'setCopiedSlide', slide }, event.origin);

  connectedEv ? fn(connectedEv) : messages.push(fn);
};

window.slideNames = (slides) => {
  const names = slides.map((slide) => slide.name);
  const fn = (event: MessageEvent) =>
    (event.source as WindowProxy | Window).postMessage({ type: 'event', name: 'slideNames', names }, event.origin);

  connectedEv ? fn(connectedEv) : messages.push(fn);
};

window.reportWhiteboardAction = (action: string) => {
  const fn = (event: MessageEvent) =>
    (event.source as WindowProxy | Window).postMessage(
      { type: 'event', name: 'reportWhiteboardAction', action },
      event.origin,
    );
  connectedEv ? fn(connectedEv) : messages.push(fn);
};

type resolveType = (value: boolean) => void;
const confirms = new Map<string, resolveType>();
window.confirmMe = (msg: string) => {
  const fn = (event: MessageEvent) =>
    (event.source as WindowProxy | Window).postMessage({ type: 'event', name: 'confirm', msg }, event.origin);

  return new Promise<boolean>((resolve) => {
    if (connectedEv) {
      confirms.set(msg, resolve);
      fn(connectedEv);
    } else {
      resolve(confirm(msg));
    }
  });
};

window.focusWhiteboard = () => {
  const fn = (event: MessageEvent) =>
    (event.source as WindowProxy | Window).postMessage({ type: 'event', name: 'focusWhiteboard' }, event.origin);
  connectedEv ? fn(connectedEv) : messages.push(fn);
};

window.boardSetLoading = (status) => {
  const fn = (event: MessageEvent) =>
    (event.source as WindowProxy | Window).postMessage(
      { type: 'event', name: 'boardSetLoading', status },
      event.origin,
    );
  connectedEv ? fn(connectedEv) : messages.push(fn);
};

window.boardSetDeleting = (status) => {
  const fn = (event: MessageEvent) =>
    (event.source as WindowProxy | Window).postMessage(
      { type: 'event', name: 'boardSetDeleting', status },
      event.origin,
    );
  connectedEv ? fn(connectedEv) : messages.push(fn);
};

window.notifySlidesImported = () => {
  const fn = (event: MessageEvent) =>
    (event.source as WindowProxy | Window).postMessage({ type: 'event', name: 'notifySlidesImported' }, event.origin);
  connectedEv ? fn(connectedEv) : messages.push(fn);
};

declare global {
  interface Window {
    modelRegistry: typeof modelRegistry;
    API: typeof API;
    readyHandler(): void;
    changeSlide(boardId: string, index: number): void;
    setCopiedSlide(slide: Dehydrate<typeof Slide>): void;
    slideNames(slides: Slide[]): void;
    confirmMe(msg: string): Promise<boolean>;
    reportWhiteboardAction(action: string): void;
    focusWhiteboard(): void;
    boardSetLoading(status: boolean): void;
    boardSetDeleting(status: boolean): void;
    notifySlidesImported(): void;
  }
}

window.modelRegistry = modelRegistry;
window.API = API;

Slide.boot();
library.add(
  faHandPointer,
  faArrowLeft,
  faArrowRight,
  faTrash,
  faPlus,
  faObjectGroup,
  faObjectUngroup,
  faEye,
  faEyeSlash,
  faQuestionCircle,
);

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .use(PrimeVue, {
    theme: {
      // preset: Aura,
    },
  })
  .mount('#app');

let connectedEv: MessageEvent | null = null;
const messages: ((event: MessageEvent) => void)[] = [];

window.addEventListener('message', (event) => {
  // if (event.origin !== 'http://example.org:8080') {}

  // console.log('Received message from other window', event)

  if (event.data === 'connect') {
    connectedEv = event;

    messages.forEach((m) => {
      m(event);
    });
  }

  if (event.data.method === 'confirm') {
    const msg = event.data.args[0];
    const result = event.data.args[1] ?? true;
    confirms.get(msg)?.(result);
  }

  const api = API as any;

  if (api[event.data.method]) {
    api[event.data.method](...(event.data.args || []));
  }
});

window.document.addEventListener(
  'pointerdown',
  () => {
    window.focusWhiteboard();
  },
  { capture: true },
);
