import { adaptNodeStructureForHydration, bboxPoints } from '../utils/utils';
import { Baseline, Model, Node, Point } from '../models';
import { Dehydrate } from '../../../vue-model/src/Model';
import { Model as BaseModel } from 'vue-model';

export class Group extends Node {
  static model = 'Group' as const;
  static base = Node;

  declare type: 'Group';
  nodes!: Node[];
  nodesCache!: Node[];
  firstNode!: Node | null;
  lastNode!: Node | null;
  order!: string[];

  static fields() {
    return {
      ...Node.fields(),
      type: this.string('Group'),
      nodesCache: this.hasMany(Node, 'parentId'),
      order: this.array<string>([], Object as any),
      firstNode: this.computed((group) => {
        return group.nodes[0];
      }),
      nodes: this.computed(
        (group) => {
          return Node.get(group.order);
        },
        (group, val: Record<string, unknown>[]) => {
          group.removeAll();

          if (!val) return;

          Node.getOrCreate(val).forEach((node) => {
            group.addNode(node);
          });
        },
      ),
      lastNode: this.computed((group) => {
        return group.nodes[group.nodes.length - 1];
      }),
      fillColor: this.computed(
        (group) => {
          const colors = new Set(group.nodes.map((c) => c.fillColor));
          if (colors.size === 1) return colors.values().next().value;
          return '#fff';
        },
        (group, value) => {
          group.nodes.forEach((c) => {
            c.fillColor = value;
          });
        },
      ),
      fillOpacity: this.computed(
        (group) => {
          return Math.max(
            ...group.nodes.map((c) => {
              if (c instanceof Baseline) {
                return c.strokeOpacity;
              }
              return c.fillOpacity;
            }),
          );
        },
        (group, value) => {
          group.nodes.forEach((c) => {
            c.fillOpacity = value;
          });
        },
      ),
      strokeColor: this.computed(
        (group) => {
          const colors = new Set(group.nodes.map((c) => c.strokeColor));
          if (colors.size === 1) return colors.values().next().value;
          return '#fff';
        },
        (group, value) => {
          group.nodes.forEach((c) => {
            c.strokeColor = value;
          });
        },
      ),
      strokeWidth: this.computed(
        (group) => {
          return Math.max(...group.nodes.map((c) => c.strokeWidth));
        },
        (group, value) => {
          group.nodes.forEach((c) => {
            c.strokeWidth = value;
          });
        },
      ),
    };
  }

  static cascades() {
    return [...super.cascades(), 'nodesCache'];
  }

  dehydrateAsCopy<T extends typeof Model>(this: InstanceType<T>, relations = this.static().cascades()) {
    const obj = super.dehydrateAsCopy(relations) as Dehydrate<T>;
    const o = obj as Dehydrate<typeof Group>;
    const order = o.order as string[];

    const newOrder = (this as Group).nodesCache.slice().sort((a, b) => order.indexOf(a._id) - order.indexOf(b._id));
    o.nodes = newOrder.map((node) => (o.nodesCache as Node[])[(this as Group).nodesCache.indexOf(node)]);

    delete o.nodesCache;
    delete o.order;
    return obj;
  }

  static hydrate<T extends typeof BaseModel>(this: T, values: Dehydrate<T>): InstanceType<T> {
    if (Array.isArray(values)) {
      values.forEach((v) => adaptNodeStructureForHydration(v));
    } else {
      adaptNodeStructureForHydration(values as Dehydrate<any>);
    }
    return super.hydrate(values) as InstanceType<T>;
  }

  generateCornerPoints() {
    if (!this.nodes || !this.nodes.length) return [];

    return bboxPoints(this.nodes.reduce((acc: Point[], curr: Node) => acc.concat(curr.generateCornerPoints()), [])).map(
      (p: Point) => p.transform(this.transform),
    );
  }

  removeNode(node: Node) {
    const index = this.order.indexOf(node._id);
    if (index > -1) {
      this.order.splice(index, 1);
    }
    node.parentId = null;
  }

  addNode(node: Node, index?: number) {
    if (node.parent !== this) node.remove();

    if (index == null) {
      this.order.push(node._id);
    } else {
      this.order.splice(index, 0, node._id);
    }

    node.parentId = this._id;
  }

  removeAll() {
    this.nodes.forEach((node) => this.removeNode(node));
  }

  indexOfNode(node: Node) {
    return this.order.indexOf(node._id);
  }
}

Group.boot();
