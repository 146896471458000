<template>
  <svg v-once>
    <defs>
      <marker
        id="marker-arrow-head-10"
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        markerWidth="10"
        markerHeight="10"
        orient="auto-start-reverse"
      >
        <path d="M 0 0 L 10 5 L 0 10 z" fill="context-stroke" />
      </marker>
      <marker
        id="marker-arrow-head-6"
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        markerWidth="6"
        markerHeight="6"
        orient="auto-start-reverse"
      >
        <path d="M 0 0 L 10 5 L 0 10 z" fill="context-stroke" />
      </marker>
      <marker
        id="marker-arrow-head-4"
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        markerWidth="4"
        markerHeight="4"
        orient="auto-start-reverse"
      >
        <path d="M 0 0 L 10 5 L 0 10 z" fill="context-stroke" />
      </marker>
      <marker
        id="marker-arrow-head-3.5"
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        markerWidth="3.5"
        markerHeight="3.5"
        orient="auto-start-reverse"
      >
        <path d="M 0 0 L 10 5 L 0 10 z" fill="context-stroke" />
      </marker>
      <marker
        id="marker-arrow-head-3"
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        markerWidth="3"
        markerHeight="3"
        orient="auto-start-reverse"
      >
        <path d="M 0 0 L 10 5 L 0 10 z" fill="context-stroke" />
      </marker>
      <marker
        id="marker-arrow-head-2.75"
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        markerWidth="2.75"
        markerHeight="2.75"
        orient="auto-start-reverse"
      >
        <path d="M 0 0 L 10 5 L 0 10 z" fill="context-stroke" />
      </marker>
    </defs>
  </svg>
</template>

<script lang="ts" setup></script>
