import { Node } from '../models';
import { Action } from './';

type NodesDeleteActionObjects = { nodes: Node[] };
export class NodesDeleteAction extends Action<NodesDeleteActionObjects> {
  static actionName = 'NodesDeleteAction';

  async redo({ nodes }: NodesDeleteActionObjects) {
    nodes.forEach((node) => {
      node.delete(true, (m) => this.delete(m));
    });
  }
}
