import { Slide } from '../models';
import { Action } from '.';

export type SlideCreateActionObjects = { slide: Slide };
export class SlideCreateAction extends Action<SlideCreateActionObjects> {
  static actionName = 'SlideCreateAction';

  async redo({ slide }: SlideCreateActionObjects) {
    this.create(slide);
  }
}
